import { handlePermissionByFeatureId } from 'aa_common/front-end/helpers/permission-helper'
import { useOpenFeature } from 'aa_common/front-end/open-features'
import { FeatureNameType } from 'aa_common/front-end/open-features/store'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import TriangleRightIcon from 'components/atoms/icons/TriangleRightIcon'
import { FEATURES_ID } from 'constants/const'
import i18n from 'i18n'
import { List, Set } from 'immutable'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link as NavLink, useRouteMatch } from 'react-router-dom'
import {
  changeLocation,
  changeMenuNavigation,
  savedMenuOpenSubnavKeys,
  setMenuCollapse,
  setMenuOpenSubnavKeys,
} from 'store/app/actions'
import { selectFlagDisableNavigation, selectNavigationSelectedNavItem } from 'store/app/selectors'
import { cleanupAssetCollectionList } from 'store/asset/actions'
import { getUserInfoSelector, selectUserPermission } from 'store/session/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectHasAccountantAndTaxLedger } from 'store/settings/ledgerSetting/selectors'
import getObject from 'utils/RecursiveHelper'

import { navItems, SideNavItem } from './settings'
import {
  NavItem,
  NavSubMenu,
  NavTextLabel,
  SidebarMenu,
  SidebarMenuWrapper,
  styleSidebarMenuWrapperClass,
  subnavMenuPopupClass,
  Wrapper,
} from './styles'
import { TriggerTemplate } from './TriggerTemplate'

type MainMenuProps = {
  id?: string
  isCollapsed: boolean
  isFirstLoad: boolean
  selectedNavItem: SideNavItem
  openSubnavKeys?: string[]
  mode?: 'inline' | 'vertical'
  savedOpenSubnavKeys?: string[]
  setMenuCollapse: typeof setMenuCollapse
  setMenuOpenSubnavKeys: typeof setMenuOpenSubnavKeys
  savedMenuOpenSubnavKeys: typeof savedMenuOpenSubnavKeys
  changeMenuNavigation: typeof changeMenuNavigation
  changeLocation: typeof changeLocation
  cleanupAssetCollectionList: typeof cleanupAssetCollectionList
}

const MainMenu = ({
  id,
  isCollapsed,
  openSubnavKeys,
  isFirstLoad,
  selectedNavItem,
  savedOpenSubnavKeys,
  mode = 'vertical',
  setMenuCollapse,
  setMenuOpenSubnavKeys,
  savedMenuOpenSubnavKeys,
  changeLocation,
  changeMenuNavigation,
  cleanupAssetCollectionList,
}: MainMenuProps) => {
  const selectedNavItemStore = useSelector(selectNavigationSelectedNavItem, isEqual)
  const isDisabled = useSelector(selectFlagDisableNavigation, isEqual)
  const hasAccountantAndTaxLedger = useSelector(selectHasAccountantAndTaxLedger, isEqual)
  const userInfo = useSelector(getUserInfoSelector, isEqual)
  const [selectedSubmenu, setSelectedSubmenu] = useState<string | null>(null)
  const routeMatch = useRouteMatch()
  const dispatch = useDispatch()
  const [remoteFlags] = useOpenFeature()

  const userPermissions = useSelector(selectUserPermission, isEqual)

  useEffect(() => {
    document.title = `${selectedNavItemStore?.label || ''} | ${i18n.t('common.page_title')}`
  }, [selectedNavItemStore?.label])

  useEffect(() => {
    const selectedKey = routeMatch.path.replace('/', '').split('/').splice(0, 2).join('.')
    const selectedNavItem = getObject(navItems, 'key', selectedKey) as SideNavItem
    if (selectedNavItem) {
      const selectedSubnavKey = selectedNavItem.key.split('.')[0]
      if (isFirstLoad) {
        changeMenuNavigation(selectedNavItem, true)
        if (!isCollapsed && mode === 'inline') {
          setMenuOpenSubnavKeys([selectedSubnavKey])
          savedMenuOpenSubnavKeys([selectedSubnavKey])
        }
      } else {
        changeMenuNavigation(selectedNavItem)
        setSelectedSubmenu(selectedSubnavKey)
      }
    } else {
      const indexNavItem = getObject(navItems, 'key', selectedKey.split('.')[0]) as SideNavItem
      indexNavItem && changeMenuNavigation(indexNavItem)
      indexNavItem && setSelectedSubmenu(indexNavItem.key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeMatch, isFirstLoad, isCollapsed, mode, savedMenuOpenSubnavKeys, setMenuOpenSubnavKeys, changeMenuNavigation])

  useEffect(() => {
    const depreciationIndex = navItems?.findIndex(navItem => navItem.key === 'depreciation')
    const underOverDepreciationReportIndex = navItems[depreciationIndex]?.children?.findIndex(
      subNavItem => subNavItem.feature_id === FEATURES_ID.REPORT_UNDER_OVER_DEPRECIATION
    )
    const settingIndex = navItems?.findIndex(navItem => navItem.key === 'setting')
    const recalculateIndex = navItems[settingIndex]?.children?.findIndex(
      subNavItem => subNavItem.feature_id === FEATURES_ID.SETTING_RECALCULATE
    )

    if (hasAccountantAndTaxLedger) {
      if (underOverDepreciationReportIndex === -1) {
        navItems[depreciationIndex]?.children?.push({
          label: i18n.t('components.MainMenu.depreciation.under_over_depreciation_report'),
          path: '/depreciation/under-over-depreciation-report',
          key: 'depreciation.under-over-depreciation-report',
          feature_id: FEATURES_ID.REPORT_UNDER_OVER_DEPRECIATION,
        })
      }

      // TODO: Temporarily comment to release asset dividing feature
      // if (recalculateIndex === -1) {
      //   const settingLength: number = navItems[settingIndex]?.children?.length || 0
      //   navItems[settingIndex]?.children?.splice(settingLength - 1, 0, {
      //     label: i18n.t('components.MainMenu.setting.recalculate_depreciation'),
      //     path: '/setting/recalculate',
      //     key: 'setting.recalculate',
      //     feature_id: FEATURES_ID.SETTING_RECALCULATE,
      //   })
      // }
    } else {
      if (typeof underOverDepreciationReportIndex === 'number' && underOverDepreciationReportIndex > -1) {
        navItems[depreciationIndex]?.children?.splice(underOverDepreciationReportIndex, 1)
      }

      if (typeof recalculateIndex === 'number' && recalculateIndex !== -1) {
        navItems[settingIndex]?.children?.splice(recalculateIndex, 1)
      }
    }
    hasAccountantAndTaxLedger === null && dispatch(fetchLedgerSettingsList())
  }, [hasAccountantAndTaxLedger, dispatch, userInfo])

  const handleToggleCollapse = (collapseState: boolean) => {
    setMenuCollapse(collapseState)
  }

  const handleNavOpenChange = (event: any) => {
    setMenuOpenSubnavKeys(event)

    if (!isCollapsed && mode === 'inline') {
      savedMenuOpenSubnavKeys(event)
    }
  }

  const handleChangeLocation = (item: SideNavItem) => {
    const currentKey = item.key.split('.')[0]
    const newUpdatedState = Set(List(savedOpenSubnavKeys).push(currentKey)).toArray()
    mode === 'inline' && savedMenuOpenSubnavKeys(newUpdatedState)
    if ((isCollapsed && mode === 'inline') || mode === 'vertical') {
      setMenuOpenSubnavKeys([])
    }

    // We have old behavior that when user in asset list page and click the list item navigation item, it should redirect and reset all the filters.
    cleanupAssetCollectionList()
    if (item.key === 'assets') {
      changeLocation('/')
    }
    changeMenuNavigation(item)
  }

  const checkCanRead = (featureId?: number | string) => {
    if (!featureId) return false
    const feature = handlePermissionByFeatureId(featureId as number, userPermissions.data)
    return feature.isRead
  }

  const renderSubNav = (navItem: SideNavItem) => {
    return (
      <NavSubMenu
        popupOffset={[0, 0]}
        className={classNames({ 'selected-subnav': selectedSubmenu === navItem.key })}
        popupClassName={subnavMenuPopupClass}
        key={navItem.key}
        icon={navItem.icon}
        disabled={isDisabled}
        title={
          <>
            <NavTextLabel>{navItem.label}</NavTextLabel>
            {!isDisabled && <TriangleRightIcon />}
          </>
        }
      >
        {navItem.children &&
          navItem.children.map(subNavItem => {
            const isRemoteEnabled = subNavItem.feature_flag_key
              ? remoteFlags[subNavItem.feature_flag_key as FeatureNameType]
              : true
            const isAllowedAccess = checkCanRead(subNavItem.feature_id)

            return subNavItem.children && subNavItem.children.length > 0
              ? renderSubNav(subNavItem)
              : isRemoteEnabled && (
                  <NavItem
                    disabled={subNavItem.disabled || isDisabled || !isAllowedAccess}
                    key={subNavItem.key}
                    icon={subNavItem.icon}
                    onClick={() => handleChangeLocation(subNavItem)}
                  >
                    {isAllowedAccess ? (
                      <NavLink
                        to={{
                          pathname: subNavItem.path,
                          state: { fromPath: subNavItem.path },
                        }}
                      >
                        <NavTextLabel>{subNavItem.label}</NavTextLabel>
                      </NavLink>
                    ) : (
                      <Tooltip
                        key={subNavItem.key}
                        placement="right"
                        title={i18n.t('common.messages.no_permissions_error_occured')}
                      >
                        <NavTextLabel>{subNavItem.label}</NavTextLabel>
                      </Tooltip>
                    )}
                  </NavItem>
                )
          })}
      </NavSubMenu>
    )
  }

  return (
    <Wrapper id={id}>
      <SidebarMenu
        trigger={<TriggerTemplate isCollapsed={isCollapsed} />}
        onCollapse={handleToggleCollapse}
        collapsible
        collapsed={isCollapsed}
        width={130}
        collapsedWidth={55}
        className="site-layout-background"
      >
        <SidebarMenuWrapper
          mode={mode}
          triggerSubMenuAction={mode === 'vertical' ? 'hover' : undefined}
          getPopupContainer={() => document.getElementById(`${id}`) as HTMLElement}
          selectedKeys={[`${selectedNavItem && selectedNavItem.key}`]}
          openKeys={openSubnavKeys || []}
          onOpenChange={handleNavOpenChange}
          className={styleSidebarMenuWrapperClass}
          subMenuOpenDelay={0.1}
          subMenuCloseDelay={0.3}
        >
          {navItems.map(navItem => {
            const isAllowedAccess = checkCanRead(navItem.feature_id)

            return navItem.children && navItem.children.length > 0 ? (
              renderSubNav(navItem)
            ) : (
              <NavItem
                disabled={navItem.disabled || isDisabled || !isAllowedAccess}
                key={navItem.key}
                icon={navItem.icon}
                onClick={() => handleChangeLocation(navItem)}
              >
                {isAllowedAccess ? (
                  <NavLink
                    to={{
                      pathname: navItem.path,
                      state: { fromPath: navItem.path },
                    }}
                  >
                    <NavTextLabel>{navItem.label}</NavTextLabel>
                  </NavLink>
                ) : (
                  <Tooltip
                    key={navItem.key}
                    placement="right"
                    title={i18n.t('common.messages.no_permissions_error_occured')}
                  >
                    <NavTextLabel>{navItem.label}</NavTextLabel>
                  </Tooltip>
                )}
              </NavItem>
            )
          })}
        </SidebarMenuWrapper>
      </SidebarMenu>
    </Wrapper>
  )
}

const mapStateToProps = (state: Readonly<any>) => ({
  isCollapsed: state.getIn(['app', 'navigation', 'isCollapsed']),
  isFirstLoad: state.getIn(['app', 'navigation', 'isFirstLoad']),
  selectedNavItem: state.getIn(['app', 'navigation', 'selectedNavItem']),
  openSubnavKeys: state.getIn(['app', 'navigation', 'openSubnavKeys']),
  savedOpenSubnavKeys: state.getIn(['app', 'navigation', 'savedOpenSubnavKeys']),
})

const mapDispatchToProps = {
  changeLocation,
  changeMenuNavigation,
  setMenuCollapse,
  setMenuOpenSubnavKeys,
  savedMenuOpenSubnavKeys,
  cleanupAssetCollectionList,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)
