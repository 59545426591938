import { faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoCompleteSelect, Button, Spin, Tooltip } from 'aa_common/front-end/antd'
import { BottomAction, ConfirmModal, If, LinkLabel } from 'aa_common/front-end/components'
import { Button as AntdButton, Tooltip as AntdTooltip } from 'antd'
import Input from 'components/atoms/v2/Input'
import {
  IMPAIRMENT_LOSS_APPLY_METHOD,
  LEDGER_PURPOSE,
  RETIREMENT_DEPRECIATION_ENDED_METHOD,
} from 'constants/masterData'
import i18n from 'i18n'
import { List } from 'immutable'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { LedgerSettingsEditItem, LedgerSettingsItem } from 'models/ledgerSetting'
import { IMasterData } from 'models/masterData'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { v1 as uuidv1 } from 'uuid'

import { DEFAULT_TEMPLATE_LEDGER } from '../const'
import LabelField from '../LabelField'
import { DEPRECIATION_METHOD } from '../model'
import { LedgerSettingsTable, MessageAtEditMode } from '../styles'
import DepreciationMethod from './DepreciationMethod'
import ImpairmentField from './ImpairmentField'
import RetirementField from './RetirementField'

interface Props {
  values: any
  masterData: IMasterData
  loading?: boolean
  isEmptyAssetList: boolean
  onSubmit: (value: any) => void
}

const LedgerSettingsEditTableForm = ({ values, masterData, loading, isEmptyAssetList, onSubmit }: Props) => {
  const [deletedLedgerSetting, setDeleteLedgerSetting] = useState<{ item: LedgerSettingsItem; index: number } | null>()

  const formModule = useForm({
    defaultValues: {
      settings: [],
      submitPayload: [],
    },
    shouldUnregister: false,
  })

  const { register, control, reset, handleSubmit, getValues, watch } = formModule

  useEffect(() => {
    register()
  }, [register])

  const { fields, remove, append } = useFieldArray<LedgerSettingsEditItem, 'key_id'>({
    control,
    name: 'settings',
    keyName: 'key' as 'key_id',
  })

  const handleOnSubmit = useCallback(() => {
    const formValues = getValues()
    onSubmit && onSubmit(formValues)
  }, [onSubmit, getValues])

  useEffect(() => {
    // re-initialize initial values (for binding edit form)
    reset(values)
  }, [values, reset])

  const isAddButtonShow = fields.length < 3 && isEmptyAssetList
  const isDeleteDisabled = fields.length === 1 || !isEmptyAssetList

  const handleAddLedgerSettings = () => {
    if (formModule && fields && fields.length < 3) {
      const { getValues, setValue } = formModule

      const newItem = Object.assign(DEFAULT_TEMPLATE_LEDGER, { key_id: uuidv1() })

      append(newItem as any)
      const updatedPayload = List<LedgerSettingsEditItem>(getValues('submitPayload')).push(newItem as any)
      setValue('submitPayload', updatedPayload.toArray())
    }
  }

  const handleDeleteLedgerSettings = (item: any, index: number) => setDeleteLedgerSetting({ item, index })

  const handleCancelDelete = () => {
    setDeleteLedgerSetting(null)
  }

  const memoizedNameCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].name`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <Input
              name={fieldName}
              placeholder={i18n.t('common.placeholder.under_50')}
              defaultValue={item.name as any}
              control={formModule.control}
              isShowError
              error={get(item.errors, 'name.message')}
              maxLength={50}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control])

  const memoizedRoundingMethodCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].rounding_method`
        return (
          <td key={`rounding_method_${item.key_id}`} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.rounding_method}
              defaultValue={item.rounding_method}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'rounding_method.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])

  const memoizedLedgerPurposeCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].ledger_purpose`

        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.ledger_purpose}
              defaultValue={item.ledger_purpose}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'ledger_purpose.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])

  const memoizedMonthlyResidualMethodCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].monthly_residual_method`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.monthly_residual_method}
              defaultValue={item.monthly_residual_method}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'monthly_residual_method.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule, masterData])

  const memoizedMaxDepreciationReachYearCalculationMethodCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].maximum_depreciation_reached_year_calculation_method`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.maximum_depreciation_reached_year_calculation_method}
              defaultValue={item.maximum_depreciation_reached_year_calculation_method}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'maximum_depreciation_reached_year_calculation_method.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])

  const memoizedMovementDepreciationAmountMethodCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].movement_depreciation_amount_method`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.movement_depreciation_amount_method}
              defaultValue={item.movement_depreciation_amount_method}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'movement_depreciation_amount_method.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])

  const impairmentDepreciationEndedMethod = useMemo(() => {
    return fields?.map((item, index) => {
      return (
        <td key={item.key_id} className="ledger-item-data-cell">
          <ImpairmentField
            index={index}
            error={get(item.errors, 'impairment_loss_apply_method.message')}
            masterData={masterData}
            defaultValue={item.impairment_loss_apply_method || IMPAIRMENT_LOSS_APPLY_METHOD.END_OF_CURRENT_MONTH}
            control={formModule.control}
          />
        </td>
      )
    })
  }, [fields, formModule.control, masterData])

  const memoized5YearsEqualInstallmentRoundingMethodCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].five_years_equal_installment_rounding_method`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.five_years_equal_installment_rounding_method}
              defaultValue={item.five_years_equal_installment_rounding_method}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'five_years_equal_installment_rounding_method.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])

  useEffect(() => {
    const watchFields: string[] = []
    for (let i = 0; i < fields.length; i++) {
      watchFields.push(`settings[${i}].ledger_purpose`)
    }
    const ledgerSettingsWatcher = watch(watchFields) as [LEDGER_PURPOSE]

    const hasAllTaxAccountant =
      Object.values(ledgerSettingsWatcher)?.some(element => element === LEDGER_PURPOSE.TAX) &&
      Object.values(ledgerSettingsWatcher)?.some(element => element === LEDGER_PURPOSE.ACCOUNTING)

    fields.forEach((item, index) => {
      const defValue =
        !hasAllTaxAccountant &&
        item?.retirement_depreciation_ended_method === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_YEAR
          ? RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH
          : item?.retirement_depreciation_ended_method
      control.setValue(`settings[${index}].retirement_depreciation_ended_method`, defValue)
    })
  }, [watch, fields, control])

  const memoizedRetirementDepreciationEndMethodCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <RetirementField
              index={index}
              error={get(item.errors, 'retirement_depreciation_ended_method.message')}
              masterData={masterData}
              defaultValue={item?.retirement_depreciation_ended_method}
              control={control}
              ledgerNumber={fields?.length}
            />
          </td>
        )
      })
    )
  }, [fields, control, masterData])

  const memoizedDepreciationCostForFirstYearOfAcquisition = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].bulk_equal_installment_first_year_method`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.bulk_equal_installment_first_year_method}
              defaultValue={item.bulk_equal_installment_first_year_method}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'bulk_equal_installment_first_year_method.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])

  const memoizedLumbSumDepreciableAssetRoundingOnCells = useMemo(() => {
    return (
      fields &&
      fields.length > 0 &&
      fields.map((item, index) => {
        const fieldName = `settings[${index}].lump_sum_depreciable_asset_rounding_on`
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <AutoCompleteSelect
              name={fieldName}
              items={masterData.lump_sum_depreciable_asset_rounding_on}
              defaultValue={item.lump_sum_depreciable_asset_rounding_on}
              textField="name_jp"
              valueField="code"
              control={formModule.control}
              isShowError
              error={get(item.errors, 'lump_sum_depreciable_asset_rounding_on.message')}
              allowClear={false}
            />
          </td>
        )
      })
    )
  }, [fields, formModule.control, masterData])
  const memoizedDepreciationTreatmentAfterDisposal = useMemo(() => {
    const isEmptyFields = isEmpty(fields)
    return (
      !isEmptyFields &&
      fields.map((item, index) => {
        return (
          <td key={item.key_id} className="ledger-item-data-cell">
            <DepreciationMethod
              index={index}
              masterData={masterData}
              defaultValue={item.bulk_equal_installment_depreciation_method || DEPRECIATION_METHOD.STOP_DEPRECIATION}
              control={control}
              error={get(item.errors, 'bulk_equal_installment_depreciation_method.message')}
            />
          </td>
        )
      })
    )
  }, [fields, control, masterData])

  const handleConfirmDelete = () => {
    if (deletedLedgerSetting && formModule && fields && fields.length > 1) {
      const { getValues, setValue } = formModule
      remove(deletedLedgerSetting.index)
      const deletedIndex = List<LedgerSettingsEditItem>(getValues('submitPayload')).findIndex(
        item => item.key_id === deletedLedgerSetting.item.key_id
      )
      let updatedPayload
      if (deletedLedgerSetting.item.id) {
        updatedPayload = List<LedgerSettingsEditItem>(getValues('submitPayload'))
          .set(deletedIndex, { ...deletedLedgerSetting.item, deleted: true })
          .toArray()
      } else {
        updatedPayload = List<LedgerSettingsEditItem>(getValues('submitPayload')).delete(deletedIndex).toArray()
      }
      setValue('submitPayload', updatedPayload)

      /**
       * This block handle rendering the ledger setting value on form after
       * deleting a ledger item which contain the accounting journal target value
       */
      let updatedSettings: LedgerSettingsEditItem[] = []
      updatedSettings = List<LedgerSettingsEditItem>(getValues('settings')).toArray()

      setValue('settings', updatedSettings)
      handleCancelDelete()
    }
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit, handleOnSubmit)}>
      <Spin loading={loading}>
        <MessageAtEditMode>
          {i18n.t('components.LedgerSettings.message_at_edit_mode')}
          <Tooltip content={i18n.t('components.LedgerSettings.tooltip.edit')}>
            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, marginTop: 3, cursor: 'pointer' }} />
          </Tooltip>
        </MessageAtEditMode>
        <div style={{ marginBottom: 5 }}>{i18n.t('components.LedgerSettings.description')}</div>
        <LedgerSettingsTable className="edit">
          <tbody>
            <tr>
              <th className="label-field">
                <div style={{ minHeight: 41 }} />
              </th>
              {fields?.length > 0 &&
                fields.map((item, index) => {
                  return (
                    <td key={item.key_id} className="ledger-item-data-cell ledger-name">
                      <div className="ledger-name-wrapper">
                        <span>{`${i18n.t('components.LedgerSettings.ledger')} ${index + 1}`}</span>
                        <LinkLabel
                          showTooltipMessage={i18n.t('components.LedgerSettings.can_not_add_and_delete_msg')}
                          tooltipSettings={{
                            placement: 'top',
                            overlayStyle: { maxWidth: 300 },
                          }}
                          disabled={isDeleteDisabled}
                          className="delete-ledger-setting"
                          type="danger"
                          onClick={() => handleDeleteLedgerSettings(item, index)}
                        >
                          {i18n.t('common.actions.cancel_1')}
                        </LinkLabel>
                      </div>
                    </td>
                  )
                })}
              <If condition={isAddButtonShow}>
                <td className="ledger-item-data-cell add-button">
                  <AntdTooltip
                    overlayStyle={{ maxWidth: 300 }}
                    title={i18n.t('components.LedgerSettings.can_not_add_and_delete_msg')}
                    placement="top"
                  >
                    <AntdButton className="add-ledger-setting" onClick={handleAddLedgerSettings}>
                      <FontAwesomeIcon icon={faPlus} className="icon-add" />
                    </AntdButton>
                  </AntdTooltip>
                </td>
              </If>
            </tr>
            <tr>
              <th className="label-field">
                <LabelField title={i18n.t('components.LedgerSettings.ledger_name')} required />
              </th>
              {memoizedNameCells}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.ledger_purpose')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.ledger_purpose')}
                  required
                />
              </th>
              {memoizedLedgerPurposeCells}
            </tr>
            <tr>
              <th className="section">
                <div className="label-field-wrapper">
                  <h4>{i18n.t('components.LedgerSettings.depreciation_calculation')}</h4>
                </div>
              </th>
              {fields && fields.length > 0 && fields.map(item => <td key={item.key_id} className="section" />)}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField title={i18n.t('components.LedgerSettings.rounding_method')} required />
              </th>
              {memoizedRoundingMethodCells}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.monthly_residual_method')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.monthly_Residual_Method')}
                  required
                />
              </th>
              {memoizedMonthlyResidualMethodCells}
            </tr>
            <tr>
              <th className="section">
                <div className="label-field-wrapper">
                  <h4>{i18n.t('components.LedgerSettings.depreciation_calculation_old_straight')}</h4>
                </div>
              </th>
              {fields && fields.length > 0 && fields.map(item => <td key={item.key_id} className="section" />)}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={
                    <div style={{ whiteSpace: 'break-spaces' }}>
                      <h4>{i18n.t('components.LedgerSettings.old_method_monthly_settlement_method')}</h4>
                    </div>
                  }
                  tooltip={i18n.t('components.LedgerSettings.tooltip.old_method_monthly_settlement_method')}
                  required
                />
              </th>
              {memoizedMaxDepreciationReachYearCalculationMethodCells}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.old_method_rounding_carried_on')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.old_method_rounding_carried_on')}
                  required
                />
              </th>
              {memoized5YearsEqualInstallmentRoundingMethodCells}
            </tr>
            <tr>
              <th className="section">
                <div className="label-field-wrapper">
                  <h4>{i18n.t('components.LedgerSettings.depreciation_calculation_bulk')}</h4>
                </div>
              </th>
              {fields && fields.length > 0 && fields.map(item => <td key={item.key_id} className="section" />)}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.bulk_equal_installment_first_year_method')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.bulk_equal_installment_first_year_method')}
                  required
                />
              </th>
              {memoizedDepreciationCostForFirstYearOfAcquisition}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.lump_sum_depreciable_asset_rounding_on')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.lump_sum_depreciable_asset_rounding_on')}
                  required
                />
              </th>
              {memoizedLumbSumDepreciableAssetRoundingOnCells}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.bulk_equal_installment_depreciation_method')}
                  required
                />
              </th>
              {memoizedDepreciationTreatmentAfterDisposal}
            </tr>
            <tr>
              <th className="section">
                <div className="label-field-wrapper">
                  <h4>{i18n.t('components.LedgerSettings.depreciation_calculation_when_change_situation')}</h4>
                </div>
              </th>
              {fields && fields.length > 0 && fields.map(item => <td key={item.key_id} className="section" />)}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.retirement_depreciation_ended_method')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.retirement_depreciation_ended_method')}
                  required
                />
              </th>
              {memoizedRetirementDepreciationEndMethodCells}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.impairment_loss_apply_method')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.impairment_loss_apply_method')}
                  required
                />
              </th>
              {impairmentDepreciationEndedMethod}
            </tr>
            <tr>
              <th className="label-field">
                <LabelField
                  title={i18n.t('components.LedgerSettings.movement_depreciation_amount_method')}
                  tooltip={i18n.t('components.LedgerSettings.tooltip.movement_depreciation_amount_method')}
                  required
                />
              </th>
              {memoizedMovementDepreciationAmountMethodCells}
            </tr>
          </tbody>
        </LedgerSettingsTable>
        <ConfirmModal
          title={i18n.t('components.LedgerSettings.delete_ledger_settings_confirmation_title')}
          message={`${i18n.t('components.LedgerSettings.delete_ledger_settings_confirmation_message')}`}
          visible={!!deletedLedgerSetting}
          onOK={handleConfirmDelete}
          onCancel={handleCancelDelete}
          closable
        />
        <BottomAction id="ledger-settings-bottom" containerId="app-main-body">
          <Button className="submit-button" disabled={loading || fields.length === 0} size="big" type="submit">
            {i18n.t('components.BasicForm.register')}
          </Button>
        </BottomAction>
      </Spin>
    </form>
  )
}

export default LedgerSettingsEditTableForm
