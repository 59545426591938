import { isEmptyValue } from 'aa_common/front-end/helpers'
import { getIn } from 'formik'
import { useEffect, useState } from 'react'

export function useReferValue(
  fieldName: string,
  formValues: any,
  setFieldValue: (field: string, value: any) => void,
  referValue: any,
  shouldUpdate: boolean
) {
  const [firstTime, setFirstTime] = useState(true)
  const value = getIn(formValues, fieldName)

  useEffect(() => {
    if (!shouldUpdate) return

    if (isEmptyValue(value)) {
      setFieldValue(fieldName, referValue)
    }
  }, [referValue, value, shouldUpdate])

  useEffect(() => {
    if (!shouldUpdate || referValue === undefined) return

    if (firstTime) {
      setFirstTime(false)
    } else {
      setFieldValue(fieldName, referValue)
    }
  }, [referValue, shouldUpdate])
}
