import { yen } from 'aa_common/front-end/helpers'
import { LedgerSetting } from 'aa_common/front-end/models'
import { DEPRECIATION_METHOD } from 'components/organisms/depreciation/LedgerSettingsTable/model'
import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'constants/masterData'
import get from 'lodash/get'
import React from 'react'

import { calculateRetirementLoss } from '../AssetRetirementForm/helper'
import { EmptySection, UnderlineSplitedText, UnderlineText } from '../events/styles'

export interface RetirementFixedAssetLedgerRequests {
  ledger_setting_id: number
  retirement_depreciation_ended_method: number
}

export interface ValueFields {
  sold_at: any
  event_cause_id: number
  sold_amount: number
  sold_destination_name: string
  disposal_cost: number
  disposal_destination_name: string
  event_photos: any
  event_attachments: any
  event_urls: any
  retirement_fixed_asset_ledger_requests: RetirementFixedAssetLedgerRequests[]
}

export const validateForm = (
  values: ValueFields,
  isRetirementDepreciationEndedMethod = false,
  isSmallAmountDepreciableAsset?: boolean
) => {
  const { sold_at, event_cause_id, sold_amount, retirement_fixed_asset_ledger_requests } = values

  const depreciationEndedMethodsField = (retirement_fixed_asset_ledger_requests || []).filter(
    item => item.retirement_depreciation_ended_method
  ).length

  if (isSmallAmountDepreciableAsset) {
    return sold_at && event_cause_id && sold_amount
  }

  return isRetirementDepreciationEndedMethod
    ? sold_at && event_cause_id && sold_amount && depreciationEndedMethodsField
    : sold_at && event_cause_id && sold_amount
}

export const calculateSaleProfit = (
  bookValue: number | null,
  isBulkEqualMethod: boolean,
  isSmallAmountDepreciableAsset: boolean,
  isTaxMethodExcluded: boolean,
  values: any,
  isContinueToDepreciate: boolean,
  previousBulkEqualSetting: boolean,
  isEditMode?: boolean
) => {
  let result
  bookValue = bookValue || 0
  const disposalCostIncluded = get(values, 'disposal_cost')
  const disposalCostExcluded = get(values, 'disposal_cost_excluded_excise')
  const soldAmountIncluded = get(values, 'sold_amount')
  const soldAmountExcluded = get(values, 'sold_amount_excluded_excise')

  const currentContinueToDepreciate = isEditMode
    ? previousBulkEqualSetting && isBulkEqualMethod
    : isContinueToDepreciate && isBulkEqualMethod
  if (currentContinueToDepreciate) {
    return 0
  }

  if (!isTaxMethodExcluded) {
    const disposalAmount = isSmallAmountDepreciableAsset ? disposalCostIncluded : disposalCostIncluded + bookValue
    result = soldAmountIncluded - disposalAmount
  } else {
    const disposalAmount = isSmallAmountDepreciableAsset ? disposalCostExcluded : disposalCostExcluded + bookValue
    result = soldAmountExcluded - disposalAmount
  }

  return Math.abs(result)
}

export const renderDepreciationConditionField = (
  ledgerSettings: LedgerSetting[],
  key: string,
  assetItem: any,
  values: any,
  bookInformationItems: any,
  isTaxMethodExcluded: boolean,
  isEditMode?: boolean
) => {
  const hasFixedAssetLedgers = !!assetItem?.fixed_asset_ledgers?.length
  return ledgerSettings?.map((item: LedgerSetting, index: number) => {
    const isBulkEqualMethodCode =
      hasFixedAssetLedgers &&
      assetItem?.fixed_asset_ledgers[index]?.depreciation_method_code ===
        DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
    const isContinueToDepreciate =
      hasFixedAssetLedgers &&
      assetItem?.fixed_asset_ledgers[index]?.ledger_setting?.bulk_equal_installment_depreciation_method ===
        DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
    const previousBulkEqualSetting =
      hasFixedAssetLedgers &&
      assetItem?.fixed_asset_ledgers[index]?.bulk_equal_installment_depreciation_method ===
        DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
    const isSmallAmountDepreciableAsset =
      hasFixedAssetLedgers &&
      assetItem?.fixed_asset_ledgers[index]?.depreciation_method_code ===
        DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
    const saleProfit =
      item?.ledger_purpose !== LEDGER_PURPOSE.TAX
        ? calculateSaleProfit(
            get(bookInformationItems, `[${index}].book_value`),
            isBulkEqualMethodCode,
            isSmallAmountDepreciableAsset,
            isTaxMethodExcluded,
            values,
            isContinueToDepreciate,
            previousBulkEqualSetting,
            isEditMode
          )
        : null
    const retirementLoss =
      item?.ledger_purpose !== LEDGER_PURPOSE.TAX
        ? calculateRetirementLoss(
            get(bookInformationItems, `[${index}].book_value`),
            isBulkEqualMethodCode,
            isSmallAmountDepreciableAsset,
            isTaxMethodExcluded,
            values,
            isContinueToDepreciate,
            previousBulkEqualSetting,
            isEditMode
          )
        : null
    const keyRender = `[${index}].${key}`
    switch (key) {
      case 'date':
        return isSmallAmountDepreciableAsset ? (
          <UnderlineText key={item.id} style={{ paddingTop: 15 }} />
        ) : (
          <UnderlineText key={item.id}>{get(bookInformationItems, keyRender)}</UnderlineText>
        )
      case 'book_value':
        return isSmallAmountDepreciableAsset ? (
          <UnderlineText key={item.id} style={{ paddingTop: 15 }} />
        ) : (
          <UnderlineSplitedText key={item.id} style={{ marginLeft: 0 }}>
            <div>{item.name}</div>
            <div>{yen(get(bookInformationItems, keyRender))}</div>
          </UnderlineSplitedText>
        )
      case 'disposal_cost':
        return saleProfit !== null ? (
          <UnderlineSplitedText key={item.id}>
            <div>{item.name}</div>
            <div>{yen(saleProfit)}</div>
          </UnderlineSplitedText>
        ) : (
          <EmptySection />
        )
      case 'retirement_loss':
        return retirementLoss !== null ? (
          <UnderlineSplitedText key={item.id}>
            <div>{item.name}</div>
            <div>{yen(retirementLoss)}</div>
          </UnderlineSplitedText>
        ) : (
          <EmptySection />
        )
      default:
        break
    }
    return null
  })
}
