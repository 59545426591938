import { Button, Spin } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { NoDataImage } from 'components/atoms'
import {
  Description,
  EmptyImage,
  LedgerSettingsTable,
} from 'components/organisms/depreciation/LedgerSettingsTable/styles'
import { FEATURES_ID } from 'constants/const'
import i18n from 'i18n'
import useUserPermission from 'lib/hooks/useUserPermission'
import { find, get, isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { isAccountingJournalTarget, isTaxJournalTarget } from 'utils/LedgerHelper'

import LabelField from '../LabelField'
import { ILedgerSettingsViewTable } from '../model'

const renderFieldValue = (value: any, valueField: string, labelField: string, masterData: any[]) => {
  const targetItem = find(masterData, item => get(item, valueField) === value)
  const renderText = defaultTo(get(targetItem, labelField), '')

  return <span>{renderText}</span>
}

const LedgerSettingsViewTable = ({ data, loading, onEdit }: ILedgerSettingsViewTable) => {
  const masterData = useSelector(selectMasterData, isEqual)

  const {
    permissions: { isCRUD },
  } = useUserPermission(FEATURES_ID.SETTING_LEDGER)

  const renderDescription = () => {
    const maxWidth = () => {
      switch (data.length) {
        case 1:
          return 580
        case 3:
          return 1020
        default:
          return 800
      }
    }

    return (
      <Description maxWidth={maxWidth()}>
        {i18n.t('components.LedgerSettings.description')}
        <Button
          showTooltipMessage={ifElse(!isCRUD, i18n.t('common.actions.no_permission'), undefined)}
          disabled={!isCRUD}
          onClick={onEdit}
          color="grey"
        >
          {i18n.t('common.actions.edit')}
        </Button>
      </Description>
    )
  }

  return (
    <Spin loading={loading}>
      {renderDescription()}
      {data.length > 0 ? (
        <div style={{ maxWidth: 'inherit', overflow: 'auto' }}>
          <LedgerSettingsTable className="view">
            <tbody>
              <tr>
                <th className="label-field" />
                {data.map((item, index) => {
                  return (
                    <td key={`ledger-name-${item.id}`} className="ledger-item-data-cell ledger-name">
                      {`${i18n.t('components.LedgerSettings.ledger')} ${index + 1}`}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField title={i18n.t('components.LedgerSettings.ledger_name')} />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field1-${item.id}`} className="ledger-item-data-cell">
                      {item.name}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.ledger_purpose')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.ledger_purpose')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field2-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(item.ledger_purpose, 'code', 'name_jp', masterData.ledger_purpose)}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="section">
                  <div className="label-field-wrapper">
                    <h4>{i18n.t('components.LedgerSettings.depreciation_calculation')}</h4>
                  </div>
                </th>
                {data.map(item => {
                  return <td key={item.id} className="section" />
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField title={i18n.t('components.LedgerSettings.rounding_method')} />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field3-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(item.rounding_method, 'code', 'name_jp', masterData.rounding_method)}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.monthly_residual_method')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.monthly_Residual_Method')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field4-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.monthly_residual_method,
                        'code',
                        'name_jp',
                        masterData.monthly_residual_method
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="section">
                  <div className="label-field-wrapper">
                    <h4>{i18n.t('components.LedgerSettings.depreciation_calculation_old_straight')}</h4>
                  </div>
                </th>
                {data.map(item => {
                  return <td key={item.id} className="section" />
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={
                      <div style={{ whiteSpace: 'break-spaces' }}>
                        <h4>{i18n.t('components.LedgerSettings.old_method_monthly_settlement_method')}</h4>
                      </div>
                    }
                    tooltip={i18n.t('components.LedgerSettings.tooltip.old_method_monthly_settlement_method')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.maximum_depreciation_reached_year_calculation_method,
                        'code',
                        'name_jp',
                        masterData.maximum_depreciation_reached_year_calculation_method
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.old_method_rounding_carried_on')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.old_method_rounding_carried_on')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.five_years_equal_installment_rounding_method,
                        'code',
                        'name_jp',
                        masterData.five_years_equal_installment_rounding_method
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="section">
                  <LabelField title={i18n.t('components.LedgerSettings.depreciation_calculation_bulk')} />
                </th>
                {data.map(item => {
                  return <td key={item.id} className="section" />
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.bulk_equal_installment_first_year_method')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.bulk_equal_installment_first_year_method')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.bulk_equal_installment_first_year_method,
                        'code',
                        'name_jp',
                        masterData.bulk_equal_installment_first_year_method
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.lump_sum_depreciable_asset_rounding_on')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.lump_sum_depreciable_asset_rounding_on')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.lump_sum_depreciable_asset_rounding_on,
                        'code',
                        'name_jp',
                        masterData.lump_sum_depreciable_asset_rounding_on
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField title={i18n.t('components.LedgerSettings.bulk_equal_installment_depreciation_method')} />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field6-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.bulk_equal_installment_depreciation_method,
                        'code',
                        'name_jp',
                        masterData.bulk_equal_installment_depreciation_method
                      )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="section">
                  <div className="label-field-wrapper">
                    <h4>{i18n.t('components.LedgerSettings.depreciation_calculation_when_change_situation')}</h4>
                  </div>
                </th>
                {data.map(item => {
                  return <td key={item.id} className="section" />
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.retirement_depreciation_ended_method')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.retirement_depreciation_ended_method')}
                  />
                </th>
                {data.map(item => {
                  const isTaxPurpose = isTaxJournalTarget(item)
                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      {!isTaxPurpose &&
                        renderFieldValue(
                          item.retirement_depreciation_ended_method,
                          'code',
                          'name_jp',
                          masterData.retirement_depreciation_ended_method
                        )}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.impairment_loss_apply_method')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.impairment_loss_apply_method')}
                  />
                </th>
                {data.map(item => {
                  const isAccountingPurpose = isAccountingJournalTarget(item)

                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      <If condition={isAccountingPurpose}>
                        {renderFieldValue(
                          item.impairment_loss_apply_method,
                          'code',
                          'name_jp',
                          masterData.impairment_loss_apply_method
                        )}
                      </If>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <th className="label-field">
                  <LabelField
                    title={i18n.t('components.LedgerSettings.movement_depreciation_amount_method')}
                    tooltip={i18n.t('components.LedgerSettings.tooltip.movement_depreciation_amount_method')}
                  />
                </th>
                {data.map(item => {
                  return (
                    <td key={`field5-${item.id}`} className="ledger-item-data-cell">
                      {renderFieldValue(
                        item.movement_depreciation_amount_method,
                        'code',
                        'name_jp',
                        masterData.movement_depreciation_amount_method
                      )}
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </LedgerSettingsTable>
        </div>
      ) : (
        <EmptyImage>
          <NoDataImage text={i18n.t('components.AreaList.msg_no_data')} />
        </EmptyImage>
      )}
    </Spin>
  )
}

export default LedgerSettingsViewTable
