/* eslint-disable no-restricted-syntax */
import { loadNs } from 'aa_common/front-end/i18n-config'
import { ASSET_CODE_AUTO_GENERATE_SETTING_METHOD, DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import { REGEX_FORMATS } from 'constants/regexs'
import { Asset } from 'models/asset'
import { LedgerSetting } from 'models/ledgerSetting'
import { Term } from 'models/term'
import moment, { Moment } from 'moment'

import { ERROR_FROM_API, LEDGERS_FIELD_NAME } from './const'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

export const deleteableAsset = {
  id: 197109,
  biid: '01GP8A6M3FP0PEXFMM5K9N5HV0',
  created_at: '2023-01-08T09:08:20.22766Z',
  user_info: {
    id: 256836,
    identification_code: '',
    email: '',
    display_name: 'Mills',
    family_name: '',
    given_name: '',
    office_id: 36478,
    office_identification_code: '',
  },
  valid_from: '2023-01-08',
  name: '資産名4',
  name_kana: '',
  code: 'Cat100004',
  branch_code: '001',
  acquired_at: '2023-01-08',
  usage_started_at: null,
  transition_at: null,
  retired_at: null,
  sold_at: null,
  recognized_at: null,
  impaired_at: null,
  sold_amount: 0,
  sold_destination_name: null,
  disposal_cost: null,
  disposal_destination_name: null,
  disposal_cost_excluded_excise: null,
  manual_disposal_excise_value: null,
  calculated_disposal_excise_value: null,
  disposal_excise_id: null,
  sold_amount_excluded_excise: null,
  manual_sold_excise_value: null,
  calculated_sold_excise_value: null,
  sold_excise_id: null,
  acquisition_cost: 1100,
  acquisition_cost_excluded_excise: 1000,
  manual_excise_value: null,
  calculated_excise_value: 100,
  excise_id: 111680,
  area_biid: '01GNSSA3JYZAS71JEK545J4Z0V',
  department_biid: null,
  quantity: '0',
  acquire_destination_name: '',
  pre_submit_number: '',
  memo: '',
  fixed_asset_ledgers: [
    {
      id: 406734,
      created_at: '2023-01-08T09:08:20Z',
      fixed_asset_id: 197109,
      ledger_setting_id: 462,
      depreciation_method_code: 22,
      service_life: 2,
      accounting_beginning_book_initial_value: 1000,
      transition_beginning_book_value: null,
      memorandum_value: 0,
      residual_amount: 0,
      usage_period_months: 0,
      impaired_amount: null,
      accumulated_impaired_amount: null,
      accumulated_over_depreciation_amt: 0,
      revised_acquisition_amount: 0,
      transition_book_value: null,
      depreciated_months: 0,
      transition_at: null,
      book_value: null,
      retirement_depreciation_ended_method: null,
      bulk_equal_installment_depreciation_method: null,
      depreciation_method: {
        code: 22,
        name_jp: '定率法（200%）',
        name_en: 'new_declining_balance_method_200',
      },
      ledger_setting: {
        id: 462,
        created_at: '2022-12-08T04:50:17Z',
        created_by: 0,
        updated_at: '2022-12-08T04:50:17Z',
        updated_by: 0,
        name: '台帳1',
        ledger_purpose: 1,
        rounding_method: 2,
        monthly_residual_method: 1,
        five_years_equal_installment_rounding_method: 1,
        maximum_depreciation_reached_year_calculation_method: 1,
        lump_sum_depreciable_asset_rounding_on: 1,
        retirement_depreciation_ended_method: 1,
        movement_depreciation_amount_method: 1,
        bulk_equal_installment_first_year_method: 2,
        bulk_equal_installment_depreciation_method: 1,
        accounting_journal_target: true,
        impairment_loss_apply_method: 1,
      },
      accumulated_depreciation_amount: 0,
      gain: 0,
      loss: 0,
    },
    {
      id: 406735,
      created_at: '2023-01-08T09:08:20Z',
      fixed_asset_id: 197109,
      ledger_setting_id: 463,
      depreciation_method_code: 22,
      service_life: 2,
      accounting_beginning_book_initial_value: 1000,
      transition_beginning_book_value: null,
      memorandum_value: 0,
      residual_amount: 0,
      usage_period_months: 0,
      impaired_amount: null,
      accumulated_impaired_amount: null,
      accumulated_over_depreciation_amt: 0,
      revised_acquisition_amount: 0,
      transition_book_value: null,
      depreciated_months: 0,
      transition_at: null,
      book_value: null,
      retirement_depreciation_ended_method: null,
      bulk_equal_installment_depreciation_method: null,
      depreciation_method: {
        code: 22,
        name_jp: '定率法（200%）',
        name_en: 'new_declining_balance_method_200',
      },
      ledger_setting: {
        id: 463,
        created_at: '2022-12-08T04:50:17Z',
        created_by: 0,
        updated_at: '2022-12-08T04:50:17Z',
        updated_by: 0,
        name: '台帳2',
        ledger_purpose: 2,
        rounding_method: 2,
        monthly_residual_method: 1,
        five_years_equal_installment_rounding_method: 1,
        maximum_depreciation_reached_year_calculation_method: 1,
        lump_sum_depreciable_asset_rounding_on: 1,
        retirement_depreciation_ended_method: 1,
        movement_depreciation_amount_method: 1,
        bulk_equal_installment_first_year_method: 2,
        bulk_equal_installment_depreciation_method: 1,
        accounting_journal_target: false,
        impairment_loss_apply_method: 1,
      },
      accumulated_depreciation_amount: 0,
      gain: 0,
      loss: 0,
    },
  ],
  asset_structure_for_corporate_tax: '',
  asset_detail_for_corporate_tax: '',
  exemption_numerator: 0,
  exemption_denominator: 0,
  tags: [],
  asset_category: {
    id: 11276,
    code: 'Cat1',
    name: 'Cat 1',
    asset_type: 2,
  },
  area: {
    id: 12252,
    biid: '01GNSSA3JYZAS71JEK545J4Z0V',
    code: 'Area1',
    name: 'Area1',
    valid_from: '2023-01-03',
    valid_to: null,
  },
  unit: null,
  department: null,
  event_cause: null,
  change_situation: {
    code: 10,
    name_jp: '取得',
    name_en: 'acquisition',
  },
  asset_status: {
    code: 10,
    name_jp: '取得（供用開始前）',
    name_en: 'acquired_before_use',
  },
  acquire_category: {
    code: 1,
    name_jp: '新品',
    name_en: 'new',
  },
  asset_type_for_depreciable_property_tax: null,
  asset_type_for_corporate_tax: null,
  summary_for_asset_depreciation_tax: '',
  is_excluded: true,
}

export const defaultAssetValues: Asset = {
  biid: '',
  name: '',
  name_kana: '',
  code: '',
  branch_code: '',
  asset_type: undefined,
  asset_category_id: undefined,
  area: null,
  area_biid: null,
  department_biid: null,
  acquired_at: null,
  min_acquired_at: undefined,
  usage_started_at: null,
  sold_at: null,
  asset_type_for_depreciable_property_tax: null,
  asset_type_for_depreciable_property_tax_code: undefined,
  asset_type_for_corporate_tax_code: undefined,
  asset_detail_for_corporate_tax: '',
  asset_structure_for_corporate_tax: '',
  acquisition_cost: 0,
  acquisition_cost_excluded_excise: 0,
  acquisition_cost_included_excise: 0,
  disposal_cost: 0,
  consumption_tax_category: undefined,
  consumption_tax_amount: 0,
  quantity: 0,
  unit_id: undefined,
  manual_excise_value: undefined,
  acquire_category_code: undefined,
  acquire_destination_name: '',
  pre_submit_number: '',
  has_exemption: false,
  exemption_numerator: 0,
  exemption_denominator: 0,
  summary_for_asset_depreciation_tax: '',
  photos: [],
  attachments: [],
  urls: [],
  fixed_asset_ledgers: [],
  tags: [],
  memo: '',
  comment: '',
  edited_at: moment(),
  retired_at: null,
  has_transition: false,
  impaired_at: null,
  accumulated_impaired_amount: 0,
  impaired_amount: 0,
  remaining_book_value: 0,
  divided_at: null,
}

export const getLedgerName = (ledgerSettingId: number | undefined, ledgerSettings: LedgerSetting[]) => {
  if (!ledgerSettingId || !ledgerSettings) return null

  const ledgerSetting = ledgerSettings.find(item => item.id === ledgerSettingId)
  return ledgerSetting?.name
}

export const isFirstDayOfTerm = (terms: Term[], date: string) => {
  if (!terms || terms.length === 0 || !date) return false

  return terms.findIndex(term => term.start_date === date) !== -1
}

export const checkValidKatakana = (name: string): boolean => {
  const nameArray = name.split('')
  for (const name of nameArray) {
    if (!REGEX_FORMATS.KATAKANA.test(name)) {
      return false
    }
  }
  return true
}

export const checkAcquiredAtBelongToAssetCodeAutoGenerate = (setting: any, acquiredAt?: string) => {
  let startDateOfSetting = null
  let endDateOfSetting = null
  let currentSettingStartDate = null

  if (!acquiredAt) return false

  if (setting.from_term) {
    startDateOfSetting = setting.from_term.start_date
  }

  if (setting.to_term) {
    endDateOfSetting = setting.to_term.end_date
  }

  currentSettingStartDate = acquiredAt

  if (
    currentSettingStartDate >= startDateOfSetting &&
    (!endDateOfSetting || currentSettingStartDate <= endDateOfSetting)
  )
    return true

  return false
}

export const isDifferentExciseSetting = (currentExciseSetting: any, newExciseSetting: any) => {
  if (
    currentExciseSetting.inputTaxWhenRegisterFixedAsset !== newExciseSetting?.input_tax_when_register_fixed_asset ||
    currentExciseSetting.taxInputMethod !== newExciseSetting?.tax_input_format ||
    currentExciseSetting.taxMethod !== newExciseSetting?.tax_method
  ) {
    return true
  }
  return false
}

export const fetchNewExciseSetting = async (
  newExciseDateValue: string,
  dispatch: any,
  fetchExciseSettingsByDate: any
) => {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(dispatch(fetchExciseSettingsByDate(newExciseDateValue))), 100)
  })

  const result = await promise
  return result
}

export const validateRequiredField = (values: any, currentSetting?: any) => {
  const error: any = []
  if (values) {
    !values.name?.length && error.push(t('name'))
    if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH) {
      !values.code?.length && error.push(t('asset_code'))
    } else if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.NO_AUTO_NUMBER) {
      !values.code?.length && error.push(t('asset_code'))
      !values.branch_code?.length && error.push(t('asset_branch_code'))
    }
    !values.acquired_at && error.push(t('acquired_at'))
    !values.acquire_category_code && error.push(t('acquisition_category'))
    !values.asset_category_id && error.push(t('asset_category'))
    !values.area_biid && error.push(t('area'))
    if (values.fixed_asset_ledgers?.length > 1) {
      // eslint-disable-next-line array-callback-return
      values.fixed_asset_ledgers?.map((item: any) => {
        const isNonDepreciation = item?.depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIATION
        const isSmallAmountDepreciableAsset =
          item?.depreciation_method_code === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
        if (values.has_transition && !isNonDepreciation && !isSmallAmountDepreciableAsset) {
          !item?.depreciation_method_code &&
            error.push(t('depreciation_type_of', { name: item?.name || item?.ledger_setting?.name }))
          !item?.transition_at && error.push(t('transition_at_of', { name: item?.name || item?.ledger_setting?.name }))
        } else {
          !item?.depreciation_method_code &&
            error.push(t('depreciation_type_of', { name: item?.name || item?.ledger_setting?.name }))
        }
      })
    } else {
      const isNonDepreciation =
        values.fixed_asset_ledgers[0].depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIATION
      const isSmallAmountDepreciableAsset =
        values.fixed_asset_ledgers[0].depreciation_method_code ===
        DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
      if (
        values.has_transition &&
        values.fixed_asset_ledgers?.length === 1 &&
        !isNonDepreciation &&
        !isSmallAmountDepreciableAsset
      ) {
        !values.fixed_asset_ledgers[0].depreciation_method_code &&
          error.push(
            t('depreciation_type_of', {
              name: values.fixed_asset_ledgers[0].name || values.fixed_asset_ledgers[0].ledger_setting.name,
            })
          )
        !values.fixed_asset_ledgers[0].transition_at &&
          error.push(
            t('transition_at_of', {
              name: values.fixed_asset_ledgers[0].name || values.fixed_asset_ledgers[0].ledger_setting.name,
            })
          )
      } else if (!values.has_transition && values.fixed_asset_ledgers?.length === 1) {
        !values.fixed_asset_ledgers[0].depreciation_method_code &&
          error.push(
            t('depreciation_type_of', {
              name: values.fixed_asset_ledgers[0].name || values.fixed_asset_ledgers[0].ledger_setting.name,
            })
          )
      }
    }
  }
  return error.join('<br>')
}

export const setTransitionAtValue = (initialValues: any, setFieldValue: any, value: string | Moment | null) => {
  ;(initialValues?.fixed_asset_ledgers || []).forEach((_: any, index: any) => {
    setFieldValue(`fixed_asset_ledgers[${index}].transition_at`, value)
  })
}

export const checkShowMemorandumValue = (depreciationCode: number) => {
  return [
    DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE, // d.method 10
    DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE, // d.method 11
    DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE, // d.method 20
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250, // d.method 21
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200, // d.method 22
  ].includes(depreciationCode)
}

export const genLedgerFieldName = (fieldName: string, index: number) => {
  return `${LEDGERS_FIELD_NAME}[${index}].${fieldName}`
}

export const addApiErrorKey = (errors: any) => {
  if (!errors || typeof errors !== 'object' || Object.keys(errors).length === 0) return errors
  return {
    ...errors,
    [ERROR_FROM_API]: true,
  }
}
