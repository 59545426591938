/* eslint-disable react/no-unused-prop-types, no-case-declarations, @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions */
import { BasicModal, ConfirmModal, LinkLabel } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { BasicForm } from 'components/molecules'
import { get, isEqual } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDepartmentById } from 'store/settings/department/actions'
import {
  DEPARTMENT_DETAIL_MODE,
  DepartmentItem,
  DepartmentItemOnCloud,
  IDepartmentDataItem,
  LatestRecord,
  PrepressCategories,
} from 'store/settings/department/model'
import { selectDepartmentItem } from 'store/settings/department/selectors'

import DepartmentDetailForm from './DepartmentDetailForm'
import { ModalContent, Row, Table } from './styles'

const t = loadNs(['common', 'components/organisms/settings/department-form'])

type Props = {
  mode?: DEPARTMENT_DETAIL_MODE.EDIT | DEPARTMENT_DETAIL_MODE.VIEW
  loading: boolean
  detailData: DepartmentItem
  departmentId: number
  currentMonthlyClosingDate: string
  prepressCategories: PrepressCategories[]
  isDisplayAllDepartments: boolean
  isEdit: boolean
  isCopy: boolean
  isAddNewDepartment: boolean
  isEditLatestRecord: boolean
  formDataList: IDepartmentDataItem[]
  latestRecord: LatestRecord
  formDataItem: IDepartmentDataItem
  listDepartmentOnCloud: DepartmentItemOnCloud[]
  onEdit?: (departmentId: number) => void
  onCancel?: (departmentId: number | null) => void
  onHandleSubmit: (formData: DepartmentItem) => void
  setIsEdit: (isEdit: boolean) => void
  setIsCopy: (isCopy: boolean) => void
  setIsEditLatestRecord: (isEditLatestRecord: boolean) => void
  setLatestRecord: (latestRecord: LatestRecord) => void
  hasEditPermission?: boolean
  hasDeletePermission?: boolean
}

type DepartmentRow = {
  value: any
  label: string
}

const DetailTable: React.FC<Props> = ({
  mode = DEPARTMENT_DETAIL_MODE.VIEW,
  detailData,
  loading,
  departmentId,
  prepressCategories,
  isDisplayAllDepartments,
  currentMonthlyClosingDate,
  isEdit,
  isCopy,
  isAddNewDepartment,
  formDataList,
  isEditLatestRecord,
  latestRecord,
  formDataItem,
  listDepartmentOnCloud,
  onEdit,
  onCancel,
  onHandleSubmit,
  setIsEdit,
  setIsCopy,
  setIsEditLatestRecord,
  setLatestRecord,
  hasDeletePermission,
  hasEditPermission: hasEditPermisison,
}) => {
  const dispatch = useDispatch()
  const { departmentData } = useSelector(selectDepartmentItem, isEqual)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)
  const [initialValueModal, setInitialValueModal] = useState(detailData)

  const isTheNewestFormItem = !get(detailData, 'id_before')
  const isTheFirstFormItem = !get(detailData, 'id_after')

  // DELETE
  const handleCancelDelete = () => {
    setIsConfirmingDelete(false)
  }

  const handleDelete = () => {
    dispatch(deleteDepartmentById(detailData))
    setIsConfirmingDelete(false)
  }

  const handleClickEditItem = () => {
    setIsEditLatestRecord(false)
    onEdit && departmentId && onEdit(departmentId)
    setIsEdit(true)
  }

  const handleClickOnDeleteItem = () => {
    isLatestDepartmentItem && setIsConfirmingDelete(true)
  }

  const handleClickOnCopyStatus = () => {
    const idAfter = get(detailData, 'id_after')
    const isBefore = get(detailData, 'id_before')

    departmentData?.forEach((item: DepartmentItem) => {
      item.id === idAfter && setInitialValueModal({ ...item, id: null })
      isTheFirstFormItem && item.id === isBefore && setInitialValueModal(item)
    })

    setIsCopy(true)
  }

  const renderDepartmentTable = () => {
    const areaTableData = [
      {
        label: t('valid_from'),
        value: detailData?.valid_from as string,
      },
      {
        label: t('name'),
        value: detailData?.name,
      },
      {
        label: t('department_on_cloud'),
        value: detailData?.ref_name,
      },
      {
        label: t('prepress_category'),
        value: detailData?.prepress_category,
      },
      {
        label: t('search_key'),
        value: detailData?.search_key,
      },
    ]

    const departmentRow = areaTableData.map(({ label, value }: DepartmentRow) => {
      switch (label) {
        // VALID FROM
        case t('valid_from'):
          if (detailData?.valid_to) {
            const dateValue = moment(value)
            const validTo = moment(detailData?.valid_to)
            const isValidToEqualCurrentMonthlyClosing = currentMonthlyClosingDate === detailData?.valid_to
            const isDepartmentIncludedInMonthlyClosing =
              (moment(currentMonthlyClosingDate).isAfter(dateValue) &&
                moment(currentMonthlyClosingDate).isBefore(validTo)) ||
              isValidToEqualCurrentMonthlyClosing

            return (
              <Row key={label}>
                <div key={label} className="label">
                  {label}
                </div>
                <div>
                  {`${value} ~ ${detailData?.valid_to} ${ifElse(
                    isDepartmentIncludedInMonthlyClosing,
                    t('label.current_monthly_time_status'),
                    ''
                  )}`}
                </div>
              </Row>
            )
          }
          const isDepartmentIncludeInMonthlyClosingNotValidTo = moment(currentMonthlyClosingDate).isAfter(
            value as string
          )

          return (
            <Row key={label}>
              <div key={label} className="label">
                {label}
              </div>
              <div>
                {`${value} ~ ${ifElse(
                  isDepartmentIncludeInMonthlyClosingNotValidTo,
                  t('label.current_monthly_time_status'),
                  ''
                )}`}
              </div>
            </Row>
          )

        // PREPRESS CATEGORY FIELD
        case t('prepress_category'):
          return (
            <Row key={label}>
              <div className="label">{label}</div>
              <div>{value?.name_jp}</div>
            </Row>
          )

        default:
          return (
            <Row key={label}>
              <div key={label} className="label">
                {label}
              </div>
              <div>{value}</div>
            </Row>
          )
      }
    })

    return departmentRow
  }

  const isValidToValue = !!detailData?.valid_to
  const addNewDepartmentTitleModal = (
    <>
      {isAddNewDepartment ? (
        <span>{t('actions.insert')}</span>
      ) : (
        <span>{ifElse(!isValidToValue && isEditLatestRecord, t('actions.edit'), t('actions.modify'))}</span>
      )}
      {isAddNewDepartment && (
        <span className={`copy-the-status ${ifElse(isCopy, 'copied', '')}`} onClick={handleClickOnCopyStatus}>
          {t('actions.copy_the_status_before')}
        </span>
      )}
    </>
  )

  const isValidTo = !!get(detailData, 'valid_to')
  const isLatestDepartmentItem = !isTheFirstFormItem && isTheNewestFormItem && get(detailData, 'id')

  return (
    <>
      {formDataItem.data.id && (
        <Table>
          {renderDepartmentTable()}
          {isDisplayAllDepartments && (
            <Row className="action-row">
              <LinkLabel
                onClick={handleClickOnDeleteItem}
                disabled={!hasDeletePermission || !isLatestDepartmentItem}
                type="danger"
                showTooltipMessage={ifElse(!hasDeletePermission, t('actions.no_permission'), undefined)}
                style={{ marginRight: 5 }}
              >
                {t('actions.remove')}
              </LinkLabel>
              <LinkLabel
                onClick={handleClickEditItem}
                disabled={!hasEditPermisison}
                showTooltipMessage={ifElse(!hasEditPermisison, t('actions.no_permission'), undefined)}
              >
                {t('actions.modify')}
              </LinkLabel>
            </Row>
          )}
        </Table>
      )}
      <ConfirmModal
        message={t('messages.confirm_delete', { name: detailData?.name })}
        visible={isConfirmingDelete}
        onOK={handleDelete}
        onCancel={handleCancelDelete}
      />
      <BasicModal
        title={addNewDepartmentTitleModal}
        isShow={mode === DEPARTMENT_DETAIL_MODE.EDIT}
        allowOutsideClick
        onCancel={() => onCancel && onCancel(detailData?.id)}
        width={753}
        className="add-new-modal"
        bodyStyle={{
          padding: '15px 0',
        }}
      >
        <ModalContent>
          <BasicForm initialValues={initialValueModal} onSubmit={onHandleSubmit}>
            <DepartmentDetailForm
              prepressCategories={prepressCategories}
              isValidTo={isValidTo}
              isEdit={isEdit}
              isAddNewDepartment={isAddNewDepartment}
              isTheNewestFormItem={isTheNewestFormItem}
              initialValueModal={initialValueModal}
              loading={loading}
              formDataList={formDataList}
              isEditLatestRecord={isEditLatestRecord}
              latestRecord={latestRecord}
              listDepartmentOnCloud={listDepartmentOnCloud}
              isCopy={isCopy}
              setLatestRecord={setLatestRecord}
            />
          </BasicForm>
        </ModalContent>
      </BasicModal>
    </>
  )
}

export default DetailTable
