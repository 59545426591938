import { UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import parser from 'html-react-parser'
import { useMemo } from 'react'

import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { useReferValue } from '../hooks/useReferValue'
import { useResetInput } from '../hooks/useResetInput'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type BookValueAtChangeProps = {
  fieldName: string
  hasTransition: boolean
  depreciationCode: DEPRECIATION_METHOD_CODE
  enabled?: boolean
  setFieldValue: (field: string, value: any) => void
  formValues: any
  initialValue?: number | null
}

export const shouldRender = (hasTransition: boolean, depreciationCode: DEPRECIATION_METHOD_CODE) => {
  return (
    hasTransition &&
    [DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE, DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE].includes(depreciationCode)
  )
}

export const BookValueAtChange = ({
  fieldName,
  hasTransition,
  depreciationCode,
  enabled,
  setFieldValue,
  formValues,
  initialValue,
}: React.PropsWithChildren<BookValueAtChangeProps>) => {
  const isShown = useMemo(() => shouldRender(hasTransition, depreciationCode), [hasTransition, depreciationCode])

  useReferValue(fieldName, formValues, setFieldValue, initialValue, isShown)
  useClearValueOnHide(fieldName, setFieldValue, isShown)
  const { hasUpdate, handleReset } = useResetInput(
    fieldName,
    formValues,
    setFieldValue,
    initialValue,
    isNumberHaveValue
  )

  if (!isShown) {
    return null
  }

  return (
    <Item
      label={
        <ItemLabelTooltip
          tooltipMaxWidth={620}
          label={parser(t('book_value_at_change'))}
          tooltip={parser(t('book_value_at_change_tooltip'))}
        />
      }
    >
      <UnderlineMoneyInput
        style={{ textAlign: 'right' }}
        name={fieldName}
        editable={enabled}
        isResetManualValue={hasUpdate && enabled}
        onHandleClickOnReset={handleReset}
        defaultValue={initialValue || 0}
      />
    </Item>
  )
}
