/* eslint-disable @typescript-eslint/no-unused-vars */
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Button } from 'antd'
import * as areaAPI from 'api/app/area'
import * as assetCategoryAPI from 'api/app/assetCategory'
import * as departmentAPI from 'api/app/department'
import SearchInput from 'components/atoms/v2/SearchInput'
import { ASSET_STATUSES } from 'constants/masterData'
import { isEqual, pick } from 'lodash'
import { IFilterFacetSettingsData } from 'models/asset'
import { IMasterData } from 'models/masterData'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetsCollectionView } from 'store/asset/selectors'
import { useDebounce } from 'use-debounce'

import AssetStatusContent from './components/AssetStatusContent'
import MoneyAmountContent from './components/MoneyAmountContent'
import { CoreMultiSelectPickerItem } from './components/MultiSelectPicker/model'
import MultiSelectPickerContent from './components/MultiSelectPickerContent'
import OtherConditionContent from './components/OtherConditionContent'
import RangeDateContent from './components/RangeDateContent'
import ServiceLifeContent from './components/ServiceLifeContent'
import FormFilter from './FormFilter'
import AdvancedFilterModalForm from './forms/AdvancedFilterModalForm'
import { FORM_STATE_OTHER_CONDITION_FILTERS } from './forms/AdvancedFilterModalForm/const'
import AssetStatusForm from './forms/AssetStatusForm'
import MoneyAmountForm from './forms/MoneyAmountForm'
import MultiSelectForm from './forms/MultiSelectForm'
import InfinityMultiSelectForm from './forms/MultiSelectForm/InfinityMultiSelectForm'
import RangeDatePickerForm from './forms/RangeDateForm'
import ServiceLifeForm from './forms/ServiceLifeForm'
import UsageStartForm from './forms/UsageStartForm'
import { BoldLabel, FilterField } from './styles'

const t = loadNs(['components/organisms/assets/asset-filter-facet', 'common'])

interface FilterFormProps {
  value: any
  filterDate: string
  masterData: IMasterData
  settingsData: IFilterFacetSettingsData
  onSubmit: (event: any, isReset?: boolean) => void
}

const initForm = {
  depreciationMethod: {
    depreciation_method: [],
  },
  moneyAmount: {
    acquisition_cost: null,
  },
  assetCategory: {
    asset_category: [],
  },
  area: {
    area: [],
  },
  department: {
    department: [],
  },
  assetStatus: {
    asset_status: [],
  },
  usageStartedAt: {
    usage_started_at: null,
  },
  acquiredAt: {
    acquired_at: undefined,
  },
  tags: {
    tag: [],
  },
  otherConditions: {
    name_kana: null,
    summary: null,
    memo: null,
    quantity: null,
    acquire_category: [],
    asset_type_for_corporate_tax: [],
    asset_type_for_depreciable_property_tax: [],
    acquire_destination_name: null,
    created_at: null,
    code: null,
    branch_code: null,
    name: null,
  },
  serviceLife: {
    service_life: null,
  },
}

const defaultValues = {
  depreciation_method: [],
  asset_status: [],
  asset_category: [],
  area: [],
  department: [],
  acquisition_cost: null,
  acquired_at: undefined,
  usage_started_at: undefined,

  name_kana: null,
  summary: null,
  memo: null,
  quantity: null,
  asset_type: null,
  acquire_category: [],
  asset_type_for_corporate_tax: [],
  asset_type_for_depreciable_property_tax: [],
  acquire_destination_name: null,
  submit_destination: null,
  tag: [],
  created_at: null,
  created_by: null,
  service_life: null,
  code: null,
  branch_code: null,
  name: null,
}

const FilterForm = ({ value, filterDate, masterData, settingsData, onSubmit }: FilterFormProps) => {
  const assetCollectionViewSelector = useSelector(selectAssetsCollectionView, isEqual)

  const [formState, setFormValues] = useState(defaultValues)
  const [searchValue, setSearchValue] = useState('')
  const validAt = assetCollectionViewSelector.filters?.valid_at

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    if (value) {
      setSearchValue(value.keyword)
      setFormValues(value)
    }
  }, [value]) // eslint-disable-line

  const handleOnSearch = ({ name, value }: any) => {
    const newSearchState = { [name]: value.trim() }

    setFormValues((prev: any) => {
      const newFormState = { ...prev, ...newSearchState }
      onSubmit(newFormState)
      return newFormState
    })
  }

  const handleResetFilter = () => {
    setFormValues(defaultValues)
    onSubmit(defaultValues, true)
  }

  const onApplyFilter = (filter: any, formName: string, formValues: any) => {
    setFormValues((prev: any) => {
      let newFormState = null
      switch (formName) {
        case 'other_conditions': {
          newFormState = {
            ...prev,
            ...pick(
              { ...formValues, asset_code: { code: formValues?.code, branch_code: formValues?.branch_code } },
              FORM_STATE_OTHER_CONDITION_FILTERS
            ),
          }
          break
        }
        default: {
          newFormState = { ...prev, ...filter }
        }
      }
      onSubmit(newFormState)
      return newFormState
    })
  }

  const assetCategoryValue = useMemo(() => formState.asset_category, [formState.asset_category])
  const formAssetCategoryValue = useMemo(
    () => ({
      asset_category: !assetCategoryValue ? initForm.assetCategory.asset_category : assetCategoryValue,
    }),
    [assetCategoryValue]
  )

  const departmentValue = useMemo(() => formState.department, [formState.department])
  const formDepartmentValue = useMemo(
    () => ({
      department: !departmentValue ? initForm.department.department : departmentValue,
    }),
    [departmentValue]
  )

  const areaValue = useMemo(() => formState.area, [formState.area])
  const formAreaValue = useMemo(
    () => ({
      area: !areaValue ? initForm.area.area : areaValue,
    }),
    [areaValue]
  )

  const depreciationMethods = useMemo<CoreMultiSelectPickerItem[]>(() => {
    const depreciationMethodsData = masterData.depreciation_method
    if (!depreciationMethodsData) {
      return []
    }

    return depreciationMethodsData.map(item => ({
      key: item.code,
      value: item.code,
      label: item.name_jp,
    }))
  }, [masterData.depreciation_method])
  const depreciationMethodValue = useMemo(() => formState.depreciation_method, [formState.depreciation_method])
  const formDepreciationMethodValue = useMemo(
    () => ({
      depreciation_method: !depreciationMethodValue
        ? initForm.depreciationMethod.depreciation_method
        : depreciationMethodValue,
    }),
    [depreciationMethodValue]
  )

  const assetStatusDatasource = useMemo<CoreMultiSelectPickerItem[]>(() => {
    const todayDate = moment()
    if (!masterData.asset_status) {
      return []
    }

    return masterData.asset_status.map(item => {
      return {
        key: item.code,
        value: item.code,
        label: item.name_jp,
        disabled:
          [ASSET_STATUSES.TO_BE_ACQUIRED, ASSET_STATUSES.RETIRED_OR_SOLD].includes(item.code) &&
          !moment(filterDate).isSame(todayDate, 'date'),
      }
    })
  }, [filterDate, masterData.asset_status])
  const [debounceAssetStatusDatasource] = useDebounce(assetStatusDatasource, 500)
  const assetStatusValue = useMemo(() => formState.asset_status, [formState.asset_status])
  const formAssetStatusValue = useMemo(
    () => ({
      asset_status: !assetStatusValue ? initForm.assetStatus.asset_status : assetStatusValue,
    }),
    [assetStatusValue]
  )

  const usageStartAtValue = useMemo(() => formState.usage_started_at, [formState.usage_started_at])
  const formUsageStartAtValue = useMemo(
    () => ({
      usage_started_at: !usageStartAtValue ? initForm.usageStartedAt.usage_started_at : usageStartAtValue,
    }),
    [usageStartAtValue]
  )

  const acquireAtValue = useMemo(() => formState.acquired_at, [formState.acquired_at])
  const formAcquireAtValue = useMemo(
    () => ({
      acquired_at: !acquireAtValue ? initForm.acquiredAt.acquired_at : acquireAtValue,
    }),
    [acquireAtValue]
  )

  const acquisitionCostValue = useMemo(() => formState.acquisition_cost, [formState.acquisition_cost])
  const formAcquisitionCostValue = useMemo(
    () => ({
      acquisition_cost: !acquisitionCostValue ? initForm.moneyAmount.acquisition_cost : acquisitionCostValue,
    }),
    [acquisitionCostValue]
  )

  const serviceLife = useMemo(() => formState.service_life, [formState.service_life])
  const formServiceLife = useMemo(
    () => ({
      service_life: !serviceLife ? initForm.serviceLife.service_life : serviceLife,
    }),
    [serviceLife]
  )

  const tagsValue = useMemo(() => formState.tag, [formState.tag])
  const formTagValue = useMemo(
    () => ({
      tag: !tagsValue ? initForm.tags.tag : tagsValue,
    }),
    [tagsValue]
  )
  const tagDatasource = useMemo<CoreMultiSelectPickerItem[]>(() => {
    const defaultTagData = [
      {
        key: 'untagged',
        value: 'untagged',
        label: t('untagged'),
      },
    ]
    const apiTagData = settingsData.tag
    if (!apiTagData) {
      return []
    }

    const mappedData = apiTagData.map(item => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }))

    return [...defaultTagData, ...mappedData]
  }, [settingsData.tag])

  const otherConditionValue = useMemo(() => pick({ ...formState }, FORM_STATE_OTHER_CONDITION_FILTERS), [formState])
  const formOtherConditionValue = useMemo(() => {
    return {
      name: null,
      name_kana: null,
      summary: null,
      memo: null,
      quantity: null,
      acquire_category: [],
      asset_type_for_corporate_tax: [],
      asset_type_for_depreciable_property_tax: [],
      pre_submit_number: null,
      acquire_destination_name: null,
      created_at: null,
      code: null,
      branch_code: null,
      ...otherConditionValue,
    }
  }, [otherConditionValue])

  return (
    <>
      <FilterField>
        <BoldLabel htmlFor="keyword">{t('full_text_search')}</BoldLabel>
        <SearchInput
          name="keyword"
          value={searchValue}
          placeholder={t('full_text_search_placeholder')}
          onSearch={handleOnSearch}
          onChange={handleSearchChange}
        />
      </FilterField>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
        <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>{t('refinement_conditions')}</h5>
        <Button style={{ padding: 0 }} type="link" onClick={handleResetFilter}>
          {t('actions.clear')}
        </Button>
      </div>

      {/* Asset Category */}
      <FormFilter
        name="asset_category"
        type="popup"
        label={t('filterSection.asset_category')}
        fields={['asset_category']}
        initValues={formAssetCategoryValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => {
          return (
            <InfinityMultiSelectForm
              valueField="id"
              textField="name"
              formModule={form}
              setPopupShow={setPopupShow}
              onFormSubmit={onSubmit}
              searchFunction={({ textSearch, pageSize, page }) => {
                return assetCategoryAPI.search({ filters: { name: textSearch }, page, pageSize, sort: ['name'] })
              }}
              name="asset_category"
              height={300}
            />
          )
        }}
        renderContent={({ form, onSubmit }) => {
          return (
            <MultiSelectPickerContent
              selectedItems={assetCategoryValue}
              onFormSubmit={onSubmit}
              formModule={form}
              name="asset_category"
            />
          )
        }}
      />

      {/* Area */}
      <FormFilter
        name="area"
        type="popup"
        label={t('filterSection.area')}
        fields={['area']}
        initValues={formAreaValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => {
          return (
            <InfinityMultiSelectForm
              formModule={form}
              setPopupShow={setPopupShow}
              onFormSubmit={onSubmit}
              valueField="biid"
              textField="name"
              additionalQueries={validAt}
              searchFunction={({ textSearch, pageSize, page, additionalQueries: validAt }) =>
                areaAPI.search({
                  filters: { name: textSearch, valid_at: validAt },
                  pageSize,
                  pageNumber: page,
                  sort: ['-valid_from', 'name'],
                })
              }
              name="area"
              height={300}
            />
          )
        }}
        renderContent={({ form, onSubmit }) => {
          return (
            <MultiSelectPickerContent selectedItems={areaValue} onFormSubmit={onSubmit} formModule={form} name="area" />
          )
        }}
      />

      {/* Department */}
      <FormFilter
        name="department"
        type="popup"
        label={t('filterSection.department')}
        fields={['department']}
        initValues={formDepartmentValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => {
          return (
            <InfinityMultiSelectForm
              formModule={form}
              valueField="biid"
              textField="name"
              setPopupShow={setPopupShow}
              onFormSubmit={onSubmit}
              additionalQueries={validAt}
              searchFunction={({ textSearch, pageSize, page, additionalQueries: validAt }) =>
                departmentAPI.search({
                  filters: { name: textSearch, valid_at: validAt },
                  pageSize,
                  pageNumber: page,
                  sort: ['-valid_from', 'name'],
                })
              }
              name="department"
              height={300}
            />
          )
        }}
        renderContent={({ form, onSubmit }) => {
          return (
            <MultiSelectPickerContent
              selectedItems={departmentValue}
              onFormSubmit={onSubmit}
              formModule={form}
              name="department"
            />
          )
        }}
      />

      {/* Depreciation Method */}
      <FormFilter
        name="depreciation_method"
        type="popup"
        label={t('filterSection.depreciation_method')}
        fields={['depreciation_method']}
        initValues={formDepreciationMethodValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => {
          return (
            <MultiSelectForm
              formModule={form}
              setPopupShow={setPopupShow}
              onFormSubmit={onSubmit}
              items={depreciationMethods}
              name="depreciation_method"
            />
          )
        }}
        renderContent={({ form, onSubmit }) => {
          return (
            <MultiSelectPickerContent
              selectedItems={depreciationMethodValue}
              onFormSubmit={onSubmit}
              formModule={form}
              name="depreciation_method"
            />
          )
        }}
      />

      {/* Service Life */}
      <FormFilter
        name="service_life"
        type="popup"
        label={t('filterSection.service_life')}
        fields={['service_life']}
        initValues={formServiceLife}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => (
          <ServiceLifeForm formModule={form} setPopupShow={setPopupShow} onFormSubmit={onSubmit} />
        )}
        renderContent={({ form, onSubmit }) => {
          return <ServiceLifeContent formState={formState} formModule={form} onFormSubmit={onSubmit} />
        }}
      />

      {/* Acquired At */}
      <FormFilter
        name="acquired_at"
        type="popup"
        label={t('filterSection.acquired_at')}
        fields={['acquired_at']}
        initValues={formAcquireAtValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => (
          <RangeDatePickerForm
            name="acquired_at"
            label={t('form.acquired_at')}
            formModule={form}
            setPopupShow={setPopupShow}
            onFormSubmit={onSubmit}
          />
        )}
        renderContent={({ form, onSubmit }) => {
          return (
            <RangeDateContent fieldKey="acquired_at" formState={formState} formModule={form} onFormSubmit={onSubmit} />
          )
        }}
      />

      {/* Usage Started At */}
      <FormFilter
        name="usage_started_at"
        type="popup"
        label={t('filterSection.usage_started')}
        fields={['usage_started_at']}
        initValues={formUsageStartAtValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => (
          <UsageStartForm setPopupShow={setPopupShow} formModule={form} onFormSubmit={onSubmit} />
        )}
        renderContent={({ form, onSubmit }) => {
          return (
            <RangeDateContent
              fieldKey="usage_started_at"
              formState={formState}
              formModule={form}
              onFormSubmit={onSubmit}
            />
          )
        }}
      />

      {/* Asset Status */}
      <FormFilter
        name="asset_status"
        type="popup"
        label={t('filterSection.asset_status')}
        fields={['asset_status']}
        initValues={formAssetStatusValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => {
          return (
            <AssetStatusForm
              formModule={form}
              setPopupShow={setPopupShow}
              onFormSubmit={onSubmit}
              items={debounceAssetStatusDatasource}
              name="asset_status"
            />
          )
        }}
        renderContent={({ form, onSubmit }) => {
          return (
            <AssetStatusContent
              selectedItems={assetStatusValue}
              onFormSubmit={onSubmit}
              formModule={form}
              name="asset_status"
            />
          )
        }}
      />

      {/* Money Amount */}
      <FormFilter
        name="money_amount"
        type="popup"
        label={t('filterSection.acquisition_cost')}
        fields={['acquisition_cost']}
        initValues={formAcquisitionCostValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => (
          <MoneyAmountForm formModule={form} setPopupShow={setPopupShow} onFormSubmit={onSubmit} />
        )}
        renderContent={({ form, onSubmit }) => {
          return <MoneyAmountContent formState={formState} formModule={form} onFormSubmit={onSubmit} />
        }}
      />

      {/* Tag */}
      <FormFilter
        name="tags"
        type="popup"
        label={t('filterSection.tag')}
        fields={['tag']}
        initValues={formTagValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, setPopupShow, onSubmit }) => (
          <MultiSelectForm
            formModule={form}
            setPopupShow={setPopupShow}
            onFormSubmit={onSubmit}
            items={tagDatasource}
            name="tag"
          />
        )}
        renderContent={({ form, onSubmit }) => (
          <MultiSelectPickerContent selectedItems={tagsValue} onFormSubmit={onSubmit} formModule={form} name="tag" />
        )}
      />

      {/* Other conditions */}
      <FormFilter
        name="other_conditions"
        type="modal"
        label={t('filterSection.other_conditions')}
        fields={FORM_STATE_OTHER_CONDITION_FILTERS}
        initValues={formOtherConditionValue}
        onApplyFilter={onApplyFilter}
        renderForm={({ form, onSubmit, isModalShow, showModal }) => (
          <AdvancedFilterModalForm
            formModule={form}
            masterData={masterData}
            settingsData={settingsData}
            onFormSubmit={onSubmit}
            isShow={isModalShow}
            showModal={showModal}
          />
        )}
        renderContent={({ form, onSubmit }) => (
          <OtherConditionContent
            formState={formState}
            masterData={masterData}
            formModule={form}
            onFormSubmit={onSubmit}
          />
        )}
      />
    </>
  )
}

export default FilterForm
