import { ASSET_CHANGE_SITUATION_TYPES, EMPTY_SYMBOL } from 'aa_common/front-end/constants'
import { ReferenceLink } from 'components/atoms'
import get from 'lodash/get'
import React from 'react'

export const UrlView = ({ assetData }: any) => {
  let urls = get(assetData, 'urls') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    urls = get(assetData, 'event_urls') || []
  }

  if (urls.length === 0) return EMPTY_SYMBOL

  return urls.map((url: string) => <ReferenceLink key={url} url={url} style={{ marginRight: 10 }} />)
}
