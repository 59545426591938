import { Button } from 'aa_common/front-end/antd'
import { BasicModal } from 'aa_common/front-end/components'
import { yen } from 'aa_common/front-end/helpers/format-helper'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { get, isEqual } from 'lodash'
import { Asset } from 'models/asset'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { getExciseListSelector } from 'store/settings/excise/selectors'
import { selectTagList } from 'store/settings/tag/selectors'
import { selectUnitList } from 'store/settings/unit/selectors'
import {
  getAcquireCategoryName,
  getAssetTypeForCorporateTax,
  getAssetTypeForDepreciablePropertyTax,
} from 'utils/MasterDataHelper'

import { getNameValue, VALUE_TYPES } from './helper'
import { TemporaryDataWrapper } from './styles'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type Props = {
  tempAssetData: Asset
  isShow: boolean
  onCancel: () => void
  onOk: () => void
}

const AssetTemporaryDataModal: React.FC<Props> = ({ tempAssetData, isShow, onCancel, onOk }) => {
  const masterData = useSelector(selectMasterData, isEqual)
  const tags = useSelector(selectTagList, isEqual)
  const units = useSelector(selectUnitList)
  const { data: excises } = useSelector(getExciseListSelector)

  return (
    <BasicModal
      destroyOnClose={false}
      allowOutsideClick={false}
      title={t('temp_modal_title')}
      isShow={isShow}
      onCancel={onCancel}
      footer={[
        <Button key="back" color="grey" onClick={onCancel}>
          {t('actions.cancel')}
        </Button>,
        <Button key="ok" onClick={onOk} color="blue">
          {t('submit_get_temp_data')}
        </Button>,
      ]}
    >
      {tempAssetData && (
        <TemporaryDataWrapper>
          <div>
            <label>{t('name')}：</label>
            <label>{tempAssetData.name}</label>
          </div>
          <div>
            <label>{t('name_kana')}：</label>
            <label>{tempAssetData.name_kana}</label>
          </div>
          <div>
            <label>{t('asset_code')}：</label>
            <label>
              {tempAssetData.code} - {tempAssetData.branch_code}
            </label>
          </div>
          <div>
            <label>{t('acquired_at')}：</label>
            <label>{tempAssetData.acquired_at}</label>
          </div>
          <div>
            <label>{t('used_at')}：</label>
            <label>{tempAssetData.usage_started_at}</label>
          </div>
          <div>
            <label>{t('acquisition_cost')}：</label>
            <label>{yen(tempAssetData.acquisition_cost)}</label>
          </div>
          <div>
            <label>{t('acquisition_category')}：</label>
            <label>{getAcquireCategoryName(masterData, tempAssetData.acquire_category_code as number)}</label>
          </div>
          <div>
            <label>{t('excise')}：</label>
            <label>{excises && getNameValue(excises, VALUE_TYPES.EXCISE, tempAssetData.excise_id as number)}</label>
          </div>
          <div>
            <label>{t('asset_category')}：</label>
            <label>{get(tempAssetData, 'asset_category_id.label', '')}</label>
          </div>
          <div>
            <label>{t('asset_type_for_depreciable_property_tax')}：</label>
            <label>
              {getAssetTypeForDepreciablePropertyTax(
                masterData,
                tempAssetData.asset_type_for_depreciable_property_tax_code as number
              )}
            </label>
          </div>
          <div>
            <label>{t('asset_type_for_corporate_tax')}：</label>
            <label>
              {getAssetTypeForCorporateTax(masterData, tempAssetData.asset_type_for_corporate_tax_code as number)}
            </label>
          </div>
          <div>
            <label>{t('structure')}：</label>
            <label>{tempAssetData.asset_structure_for_corporate_tax}</label>
          </div>
          <div>
            <label>{t('detail')}：</label>
            <label>{tempAssetData.asset_detail_for_corporate_tax}</label>
          </div>
          <div>
            <label>{t('area')}：</label>
            <label>{get(tempAssetData.area, 'name')}</label>
          </div>
          <div>
            <label>{t('submit_destination')}：</label>
            <label>{get(tempAssetData, 'area.submit_destination.name')}</label>
          </div>
          <div>
            <label>{t('department_allocation_rate')}：</label>
            <label>{get(tempAssetData.department, 'name')}</label>
          </div>
          <div>
            <label>{t('quantity_unit')}：</label>
            <label>{tempAssetData.quantity}</label>
            <label>{units && getNameValue(units, VALUE_TYPES.UNIT, tempAssetData.unit_id as number)}</label>
          </div>
          <div>
            <label>{t('acquire_destination')}：</label>
            <label>{tempAssetData.acquire_destination_name}</label>
          </div>
          <div>
            <label>{t('taxation_standard_special')}：</label>
            <label>{tempAssetData.has_exemption ? t('has_exemption') : t('no_exemption')}</label>
          </div>
          <div>
            <label>{t('description_property_tax_return')}：</label>
            <label>{tempAssetData.summary_for_asset_depreciation_tax}</label>
          </div>
          <div>
            <label>{t('photo')}：</label>
            <label>
              {tempAssetData.event_photos && tempAssetData.event_photos.length > 0
                ? t('form_values.yes')
                : t('form_values.no')}
            </label>
          </div>
          <div>
            <label>{t('reference_link')}：</label>
            <label>
              {tempAssetData.urls && tempAssetData.urls.length > 0 ? t('form_values.yes') : t('form_values.no')}
            </label>
          </div>
          <div>
            <label>{t('attachment')}：</label>
            <label>
              {tempAssetData.attachments && tempAssetData.attachments.length > 0
                ? t('form_values.yes')
                : t('form_values.no')}
            </label>
          </div>

          {tempAssetData.fixed_asset_ledgers?.map((assetLedger, index) => (
            <div key={assetLedger?.ledger_setting_id}>
              <div>
                <label>
                  {t('depreciation_type')}
                  {t('ledger', { number: index + 1 })}：
                </label>
                <label>
                  {getNameValue(
                    masterData?.depreciation_method,
                    VALUE_TYPES.DEPRECIATION_METHOD,
                    assetLedger.depreciation_method_code
                  )}
                </label>
              </div>
              <div>
                <label>
                  {t('service_life')}
                  {t('ledger', { number: index + 1 })}：
                </label>
                <label>{assetLedger.service_life}</label>
              </div>
              <div>
                <label>
                  {t('beginning_book_value')}
                  {t('ledger', { number: index + 1 })}：
                </label>
                <label>
                  {assetLedger.transition_beginning_book_value || assetLedger.accounting_beginning_book_initial_value}
                </label>
              </div>
              <div>
                <label>
                  {t('depreciation_rate')}
                  {t('ledger', { number: index + 1 })}：
                </label>
                <label>{assetLedger.depreciation_rate}</label>
              </div>
              <div>
                <label>
                  {t('revised_depreciation_rate')}
                  {t('ledger', { number: index + 1 })}：
                </label>
                <label>{assetLedger.revised_depreciation_rate}</label>
              </div>
              <div>
                <label>
                  {t('guarantee_rate')}
                  {t('ledger', { number: index + 1 })}：
                </label>
                <label>{assetLedger.guarantee_rate}</label>
              </div>
            </div>
          ))}
          <div>
            <label>{t('tag')}：</label>
            <label>{tags && getNameValue(tags, VALUE_TYPES.TAG, null, tempAssetData.tags as string[])}</label>
          </div>
          <div>
            <label>{t('note')}：</label>
            <label>{tempAssetData.memo}</label>
          </div>
          <div>
            <label>{t('comment')}：</label>
            <label>{tempAssetData.comment}</label>
          </div>
        </TemporaryDataWrapper>
      )}
    </BasicModal>
  )
}

export default AssetTemporaryDataModal
