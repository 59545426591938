import { UnderlineInput } from 'aa_common/front-end/antd'
import { UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { isEmptyValue } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { FeatureFlagNames } from 'aa_common/front-end/open-features'
import { useCheckFlag } from 'aa_common/front-end/open-features/hooks'
import * as depreciationRateApi from 'api/app/depreciationRates'
import classNames from 'classnames'
import { Checkbox, NumberInput } from 'components/atoms'
import { AutoCompleteSelect, DatePicker, MoneyInput } from 'components/molecules'
import { DEFAULT_MEMORANDUM_VALUE } from 'constants/const'
import { ASSET_TYPE, DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'constants/masterData'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { AssetLedger } from 'models/asset'
import { FORM_MODES } from 'models/form'
import { IMasterData } from 'models/masterData'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { setShowMemorandumValueErrorMessage } from 'store/asset/actions'
import { selectShowMemorandumValueErrorMessage } from 'store/asset/selectors'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'
import { selectUnitList } from 'store/settings/unit/selectors'
import { checkIsAfterDate, checkIsAfterOrEqualDate, checkIsBeforeDate } from 'utils/DateTimeHelper'
import { isAccountingJournalTarget, isTaxJournalTarget } from 'utils/LedgerHelper'

import BeginningBookValue from './BeginningBookValue'
import { BookValueAtChange, shouldRender as shouldRenderBookValueAtChange } from './components/BookValueAtChange'
import { ItemLabelTooltip } from './components/ItemLabelTooltip'
import { ServiceLifeAfterChange } from './components/ServiceLifeAfterChange'
import { DEPRECIATION_FIELDS, LEDGERS_FIELD_NAME } from './const'
import DepreciatedMonthsInput from './DepreciatedMonthsInput'
import { checkShowMemorandumValue, genLedgerFieldName } from './helper'
import { useLedgerCopy } from './hooks/useLedgerCopy'
import { Error, LedgerHeader, TitleName } from './styles'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

const { Item } = UnborderCollapse

type Props = {
  masterData?: IMasterData
  ledgerIndex: number
  disabled?: boolean
  isTransitionDayIsFirstDayOfTerm?: boolean
  ledgerName?: string
  isEditMode?: boolean
  showRequiredError?: boolean
  isTaxMethodExclude?: boolean
  isDisabledTransitionAt?: boolean
  onAfterChangedTransitionAt: any
  formMode: FORM_MODES
  isImportMode: boolean
  bookValueAtChangeReference?: number
}

const prelineStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
}

const DepreciationLedge: React.FC<Props> = ({
  ledgerIndex,
  masterData,
  disabled = false,
  isTransitionDayIsFirstDayOfTerm = false,
  ledgerName = '',
  isEditMode,
  showRequiredError = false,
  isTaxMethodExclude,
  isDisabledTransitionAt,
  onAfterChangedTransitionAt,
  formMode,
  isImportMode,
  bookValueAtChangeReference,
}) => {
  const dispatch = useDispatch()
  const { values, errors, setFieldValue } = useFormikContext<{ id: string }>()
  const [depreciationRateResponse, getDepreciationRate] = useResource(depreciationRateApi.get)
  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const unitList = useSelector(selectUnitList, isEqual)

  const showMemorandumValueErrorMessage = useSelector(selectShowMemorandumValueErrorMessage, isEqual)
  const [beginningBookValue, setBeginningBookValue] = useState<number | undefined>(undefined)

  const ledgerIdField = genLedgerFieldName(DEPRECIATION_FIELDS.LEDGER_SETTING_ID, ledgerIndex)

  const serviceLifeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, ledgerIndex)
  const serviceLifeAfterChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE, ledgerIndex)
  const bookValueAtChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE, ledgerIndex)
  const usagePeriodMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS, ledgerIndex)
  const residualAmountField = genLedgerFieldName(DEPRECIATION_FIELDS.RESIDUAL_AMOUNT, ledgerIndex)
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const depreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_RATE, ledgerIndex)
  const guaranteeRateField = genLedgerFieldName(DEPRECIATION_FIELDS.GUARANTEE_RATE, ledgerIndex)
  const revisedDepreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE, ledgerIndex)
  const accountingBeginningBookInitialValueField = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE,
    ledgerIndex
  )
  const accountingBeginningBookManualValueField = genLedgerFieldName(
    DEPRECIATION_FIELDS.TRANSITION_BEGINNING_BOOK_VALUE,
    ledgerIndex
  )
  const transitionBookValueField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE, ledgerIndex)
  const revisedAcquisitionAmountField = genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_ACQUISITION_AMOUNT, ledgerIndex)
  const depreciatedMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATED_MONTHS, ledgerIndex)
  const transitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, ledgerIndex)
  const accumulatedDepreciationAmount = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCUMULATED_DEPRECIATION_AMOUNT,
    ledgerIndex
  )
  const accumulatedImpairmentAmount = genLedgerFieldName(DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT, ledgerIndex)
  const bookValue = genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE, ledgerIndex)
  const memorandumValueField = genLedgerFieldName(DEPRECIATION_FIELDS.MEMORANDUM_VALUE, ledgerIndex)
  const retirementDepreciationEndedMethod = genLedgerFieldName(
    DEPRECIATION_FIELDS.RETIREMENT_DEPRECIATION_ENDED_METHOD,
    ledgerIndex
  )
  const gainField = genLedgerFieldName(DEPRECIATION_FIELDS.GAIN, ledgerIndex)
  const lossField = genLedgerFieldName(DEPRECIATION_FIELDS.LOSS, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)
  const accumulatedExcessDepreciationValueField = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCUMULATED_OVER_DEPRECIATION_AMT,
    ledgerIndex
  )
  const isEnteredField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_MEMORANDUM_VALUE_ENTERED, ledgerIndex)

  const ledgerId = getIn(values, ledgerIdField)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const fixedAssetLedgers = getIn(values, LEDGERS_FIELD_NAME) as AssetLedger[]
  const transitionAt = getIn(values, transitionAtField)
  const usageStartedAt = getIn(values, 'usage_started_at')
  const hasTransition = getIn(values, 'has_transition')
  const acquisitionCostInput = get(values, 'acquisition_cost_input')
  const acquisitionExcludedInput = get(values, 'acquisition_cost_excluded_excise')
  const accumulatedImpairmentAmountValue = get(values, accumulatedImpairmentAmount)
  const accumulatedDepreciationAmountValue = get(values, accumulatedDepreciationAmount)
  const transitionBookValueFieldValue = get(values, transitionBookValueField)
  const memorandumValue = get(values, memorandumValueField)
  const isCopied = getIn(values, isCopiedField)
  const unitId = get(values, 'unit_id')
  const assetType = get(values, 'asset_type') || get(values, 'asset_category.asset_type')
  const linkMemorandumValueQuantity = unitList?.find(unit => unit.id === unitId)?.link_memorandum_value_quantity
  const serviceLifeValue = getIn(values, serviceLifeField)
  const serviceLifeAfterChangeValue = getIn(values, serviceLifeAfterChangeField)
  const bookValueAtChangeValue = getIn(values, bookValueAtChangeField)

  const fieldItems = [
    revisedAcquisitionAmountField,
    depreciationRateField,
    depreciatedMonthsField,
    accountingBeginningBookManualValueField,
    accumulatedDepreciationAmount,
    accumulatedImpairmentAmount,
    bookValue,
    guaranteeRateField,
    memorandumValueField,
    residualAmountField,
    retirementDepreciationEndedMethod,
    revisedDepreciationRateField,
    transitionBookValueField,
    usagePeriodMonthsField,
    lossField,
    gainField,
  ]

  const isSmallAmountDepreciationMethod = depreciationCode === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
  const isEnableFullFields = ![
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
  ].includes(depreciationCode)

  useEffect(() => {
    if (depreciationRateResponse.data) {
      const depreciationRateItem = depreciationRateResponse.data[0]
      setFieldValue(depreciationRateField, getIn(depreciationRateItem, DEPRECIATION_FIELDS.DEPRECIATION_RATE))
      setFieldValue(guaranteeRateField, getIn(depreciationRateItem, DEPRECIATION_FIELDS.GUARANTEE_RATE))
      setFieldValue(
        revisedDepreciationRateField,
        getIn(depreciationRateItem, DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE)
      )
    }
  }, [depreciationRateResponse.data]) // eslint-disable-line

  // Set new accountingBeginningBookValue when accountingBeginningBookInitialValue or accountingBeginningBookManualValue changed
  useEffect(() => {
    const accountingBeginningBookInitialValue = getIn(values, accountingBeginningBookInitialValueField)

    if (!hasTransition) {
      setBeginningBookValue(accountingBeginningBookInitialValue)
      setFieldValue(accountingBeginningBookManualValueField, null)
      setFieldValue(accumulatedExcessDepreciationValueField, null)
    }
  }, [values?.id, hasTransition]) // eslint-disable-line

  useEffect(() => {
    clearRateValues()
    const serviceLifeForCalc = serviceLifeAfterChangeValue || serviceLifeValue
    if (depreciationCode && serviceLifeForCalc) {
      getDepreciationRate({ depreciation_method: depreciationCode, service_life: serviceLifeForCalc })
    }
  }, [depreciationCode, serviceLifeValue, serviceLifeAfterChangeValue])

  useEffect(() => {
    fixedAssetLedgers.forEach((_, index) => {
      if (index > 0) {
        const otherTransitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, index)
        const isD10Method = depreciationCode === DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE

        setFieldValue(otherTransitionAtField, transitionAt)

        const transitionAtMoment = moment(transitionAt)
        const usageStartedAtMoment = moment(usageStartedAt)
        const depreciationMonthsValue = isD10Method
          ? 0
          : moment([transitionAtMoment.year(), transitionAtMoment.month()]).diff(
              moment([usageStartedAtMoment.year(), usageStartedAtMoment.month()]),
              'months'
            )
        setFieldValue(depreciatedMonthsField, depreciationMonthsValue < 0 ? 0 : depreciationMonthsValue)
      }
    })
    setFieldValue('transition_at', transitionAt)
  }, [transitionAt, depreciationCode, usageStartedAt]) // eslint-disable-line

  useEffect(() => {
    const transitionBookValue = get(values, transitionBookValueField)
    const beginningBookManualValue = get(values, accountingBeginningBookManualValueField)
    const beginningBookValue = get(values, accountingBeginningBookInitialValueField)

    if (!hasTransition) {
      setFieldValue(transitionAtField, null)
      setFieldValue(transitionBookValueField, null)
    } else if (!isSmallAmountDepreciationMethod) {
      /**
       * We will set transition_book_value and transition_beginning_book_value value
       * equal acquisition_cost (acquisition_cost_excluded_excise in case: excluded or acquisition_cost in case: included)
       * when:
       *   + transition without usage start at
       *   + usageStartAt >= transitionAt
       */
      const isUsageStartAfterOrEqualTransitionDate = checkIsAfterOrEqualDate(usageStartedAt, transitionAt)

      const isAccountingLedger = ledgerSettingList?.[ledgerIndex]?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
      if (!isAccountingLedger && (!usageStartedAt || isUsageStartAfterOrEqualTransitionDate)) {
        setFieldValue(transitionBookValueField, beginningBookValue)
        setFieldValue(accountingBeginningBookManualValueField, beginningBookValue)
      } else {
        !transitionBookValue && setFieldValue(transitionBookValueField, 0)
        !beginningBookManualValue && setFieldValue(accountingBeginningBookManualValueField, 0)
      }
    }
  }, [hasTransition, isSmallAmountDepreciationMethod, usageStartedAt, transitionAt]) // eslint-disable-line

  useEffect(() => {
    if (usageStartedAt && isTransitionDayIsFirstDayOfTerm) {
      const beginningBookValue = get(values, accountingBeginningBookManualValueField)
      !isSmallAmountDepreciationMethod && setFieldValue(transitionBookValueField, beginningBookValue)
    }
  }, [
    accountingBeginningBookManualValueField,
    isTransitionDayIsFirstDayOfTerm,
    setFieldValue,
    transitionBookValueField,
    usageStartedAt,
    values,
    isSmallAmountDepreciationMethod,
  ]) // eslint-disable-line

  useEffect(() => {
    if (isSmallAmountDepreciationMethod) {
      if (isTaxMethodExclude) {
        setBeginningBookValue(acquisitionExcludedInput)
      } else {
        setBeginningBookValue(acquisitionCostInput)
      }
    }
  }, [isTaxMethodExclude, acquisitionExcludedInput, acquisitionCostInput, isSmallAmountDepreciationMethod]) // eslint-disable-line

  useEffect(() => {
    if (isSmallAmountDepreciationMethod) {
      const currentMonthlyClosingStartDate = moment(currentMonthlyClosing?.from_term_month.start_date)
      const beginningBookValue = get(values, accountingBeginningBookInitialValueField)
      const isUsageStartedAtInPast = checkIsBeforeDate(usageStartedAt, currentMonthlyClosingStartDate)
      const isUsageStartAtBeforeTransitionAt = checkIsBeforeDate(usageStartedAt, transitionAt)
      const isUsageStartedAtAfterTransitionAt = checkIsAfterDate(usageStartedAt, transitionAt)

      fieldItems.forEach((fieldName: string) => {
        const isBeginningBookManualAndTransitionBookValue =
          fieldName === accountingBeginningBookManualValueField || fieldName === transitionBookValueField
        let fieldValue: null | number = null

        if (isBeginningBookManualAndTransitionBookValue) {
          if (isUsageStartAtBeforeTransitionAt || isUsageStartedAtInPast) {
            fieldValue = 0
          } else if ((!usageStartedAt && transitionAt) || isUsageStartedAtAfterTransitionAt) {
            fieldValue = beginningBookValue
          }
        }
        setFieldValue(fieldName, fieldValue)
      })
    }
  }, [isSmallAmountDepreciationMethod, hasTransition, transitionAt, usageStartedAt, beginningBookValue]) // eslint-disable-line

  useEffect(() => {
    if (!isEnableFullFields && transitionAt) {
      fieldItems.forEach((fieldName: string) => {
        const isBeginningBookManualAndTransitionBookValue =
          fieldName === accountingBeginningBookManualValueField || fieldName === transitionBookValueField
        let fieldValue = null

        if (isBeginningBookManualAndTransitionBookValue) {
          if (isTaxMethodExclude) {
            fieldValue = acquisitionExcludedInput
          } else {
            fieldValue = acquisitionCostInput
          }
        }

        if (isImportMode && depreciationCode === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE) {
          if (fieldName === accumulatedImpairmentAmount) {
            fieldValue = accumulatedImpairmentAmountValue
          }

          if (fieldName === accumulatedDepreciationAmount) {
            fieldValue = accumulatedDepreciationAmountValue
          }

          if (fieldName === transitionBookValueField) {
            fieldValue = transitionBookValueFieldValue
          }
        }

        setFieldValue(fieldName, fieldValue)
      })
    }
  }, [
    // eslint-disable-line
    isEnableFullFields,
    hasTransition,
    transitionAt,
    isTaxMethodExclude,
    acquisitionExcludedInput,
    acquisitionCostInput,
    accumulatedImpairmentAmount,
    accumulatedImpairmentAmountValue,
    accumulatedDepreciationAmount,
    accumulatedDepreciationAmountValue,
    transitionBookValueField,
    transitionBookValueFieldValue,
    accountingBeginningBookManualValueField,
  ])

  const copyFields = useMemo(() => {
    return [
      DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE,
      DEPRECIATION_FIELDS.SERVICE_LIFE,
      DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS,
      DEPRECIATION_FIELDS.RESIDUAL_AMOUNT,
      DEPRECIATION_FIELDS.DEPRECIATION_RATE,
      DEPRECIATION_FIELDS.GUARANTEE_RATE,
      DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE,
      DEPRECIATION_FIELDS.ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE,
      DEPRECIATION_FIELDS.TRANSITION_BEGINNING_BOOK_VALUE,
      DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE,
      DEPRECIATION_FIELDS.REVISED_ACQUISITION_AMOUNT,
      DEPRECIATION_FIELDS.DEPRECIATED_MONTHS,
      DEPRECIATION_FIELDS.TRANSITION_AT,
      DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE,
      DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE,
      DEPRECIATION_FIELDS.ACCUMULATED_DEPRECIATION_AMOUNT,
      DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT,
      DEPRECIATION_FIELDS.MEMORANDUM_VALUE,
    ]
  }, [])

  useLedgerCopy(values, setFieldValue, ledgerIndex, copyFields, isCopied)

  useEffect(() => {
    dispatch(
      setShowMemorandumValueErrorMessage({
        ledgerId,
        isShowErrorMessage:
          (hasTransition && transitionAt && memorandumValue > transitionBookValueFieldValue) ||
          memorandumValue > acquisitionCostInput,
      })
    )
  }, [memorandumValue, acquisitionCostInput, transitionBookValueFieldValue, hasTransition, transitionAt]) // eslint-disable-line

  const clearRateValues = () => {
    setFieldValue(depreciationRateField, null)
    setFieldValue(guaranteeRateField, null)
    setFieldValue(revisedDepreciationRateField, null)
  }

  const handleBeginningBookValueChanged = (newValue: number) => {
    setFieldValue(accountingBeginningBookManualValueField, newValue)
  }

  const handleResetBeginningBookValue = () => {
    setFieldValue(accountingBeginningBookManualValueField, null)
    setBeginningBookValue(getIn(values, accountingBeginningBookInitialValueField))
  }

  const handleOnChangeDepreciationType = (depreciationCode?: number | string) => {
    const isNonDepreciableMethod = depreciationCode === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE

    switch (depreciationCode) {
      case DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD:
      case DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE:
      case DEPRECIATION_METHOD_CODE.NON_DEPRECIATION:
      case DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE:
        setFieldValue(serviceLifeField, null)
        setFieldValue(usagePeriodMonthsField, isNonDepreciableMethod ? null : 1)
        break
      case DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT:
        setFieldValue(serviceLifeField, 1)
        break
      case DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD:
        setFieldValue(serviceLifeField, 3)
        break
      case DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET:
        setFieldValue(serviceLifeField, 0)
        break
      default:
        setFieldValue(serviceLifeField, 2)
    }

    resetDepreciatedMonths()
  }

  const resetDepreciatedMonths = () => {
    setFieldValue(depreciatedMonthsField, 0)
  }

  const renderServiceLife = () => {
    const isBulkEqualInstallmentMethod = depreciationCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
    const isDisabledInput = disabled || isBulkEqualInstallmentMethod || isCopied
    const canEditServiceLife = isSmallAmountDepreciationMethod ? false : isDisabledInput
    const minServiceLifeByDmethod = depreciationCode === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT ? 1 : 2
    const minServiceLife = isSmallAmountDepreciationMethod ? 0 : minServiceLifeByDmethod

    if (
      [DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item
        label={
          <ItemLabelTooltip
            showTooltip={isSmallAmountDepreciationMethod}
            label={t('service_life')}
            tooltip={parser(t('small_amount_servicelife_tooltip_content'))}
          />
        }
        required={!isSmallAmountDepreciationMethod}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name={serviceLifeField}
            defaultValue={isSmallAmountDepreciationMethod ? 0 : 2}
            min={minServiceLife}
            max={100}
            maxLength={3}
            disabled={canEditServiceLife}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
          />
          <label>{t('units.year')}</label>
        </div>
        <Error>{getIn(errors, serviceLifeField)}</Error>
      </Item>
    )
  }

  const renderTransitionAt = () => {
    const accountingPeriodDate = get(values, 'min_acquired_at')
    const acquiredAt = get(values, 'acquired_at')
    const isAccountingPeriodDateAfterAcquiredAt = moment(accountingPeriodDate).isAfter(acquiredAt)
    const isValidDate = isAccountingPeriodDateAfterAcquiredAt ? 'min_acquired_at' : 'acquired_at'

    return (
      <Item
        label={<ItemLabelTooltip label={t('transition_at')} tooltip={parser(t('transition_at_tooltip'))} />}
        required
        jsLabel={t('transition_at_of', { name: ledgerName })}
      >
        <DatePicker
          name={transitionAtField}
          disabled={isEditMode || isDisabledTransitionAt}
          minDateField={isValidDate}
          className={classNames({ error: showRequiredError && !get(values, transitionAtField) })}
          afterChanged={onAfterChangedTransitionAt}
        />
        {showRequiredError && !get(values, transitionAtField) && <Error>{t('transition_at_required_error')}</Error>}
      </Item>
    )
  }

  const renderDepreciationRate = () => {
    if (
      [
        DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
        DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
        DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
        DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
        DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
      ].includes(depreciationCode)
    )
      return null

    return (
      <Item label={t('depreciation_rate')}>
        <UnderlineInput name={depreciationRateField} placeholder={t('depreciation_rate_placeholder')} />
      </Item>
    )
  }

  const renderNumberOfAvgUsagePeriod = () => {
    if (
      ![DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item label={t('usage_period_months')} required>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name={usagePeriodMonthsField}
            min={1}
            defaultValue={1}
            maxLength={3}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
            disabled={disabled || isCopied}
          />
          <label>{t('units.month_amount')}</label>
        </div>
        <Error>{getIn(errors, usagePeriodMonthsField)}</Error>
      </Item>
    )
  }

  const renderResidualValueEndOfUsage = () => {
    if (
      ![DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item
        label={t('residual_amount')}
        required
        style={prelineStyle}
        jsLabel={t('residual_amount_of', { name: ledgerName })}
      >
        <MoneyInput name={residualAmountField} style={{ textAlign: 'right' }} disabled={disabled || isCopied} />
      </Item>
    )
  }

  const renderBookValues = () => {
    const beginningManualBookValue = get(values, accountingBeginningBookManualValueField)

    if (isSmallAmountDepreciationMethod) return

    if (hasTransition) {
      const isUsageStartedAt = !!usageStartedAt
      const isUsageStartAtBeforeTransitionAt = checkIsAfterDate(transitionAt, usageStartedAt)
      let beginBookValueMax = !isTaxMethodExclude ? acquisitionCostInput : acquisitionExcludedInput
      if (
        isEnableDmethodD10D11 &&
        shouldRenderBookValueAtChange(hasTransition, depreciationCode) &&
        !Number.isNaN(parseInt(String(bookValueAtChangeValue), 10))
      ) {
        beginBookValueMax = bookValueAtChangeValue
      }

      return (
        <>
          {
            // Begining book manual field just is shown when the Fixed Asset has been started usage
            isUsageStartedAt && isUsageStartAtBeforeTransitionAt && (
              <Item
                label={
                  <ItemLabelTooltip
                    label={t('transition_beginning_book_value')}
                    tooltip={parser(t('transition_beginning_book_value_tooltip'))}
                  />
                }
                style={prelineStyle}
                required
                jsLabel={t('transition_beginning_book_value_of', { name: ledgerName })}
              >
                <MoneyInput
                  name={accountingBeginningBookManualValueField}
                  disabled={disabled || isCopied}
                  maxValue={beginBookValueMax}
                />
              </Item>
            )
          }
          {!isTransitionDayIsFirstDayOfTerm && isUsageStartedAt && isUsageStartAtBeforeTransitionAt && (
            <Item
              label={
                <ItemLabelTooltip
                  label={t('transition_book_value')}
                  tooltip={parser(t('transition_book_value_tooltip'))}
                />
              }
              style={prelineStyle}
              required
              jsLabel={t('transition_book_value_of', { name: ledgerName })}
            >
              <MoneyInput
                name={transitionBookValueField}
                disabled={disabled || isCopied}
                maxValue={beginningManualBookValue}
                afterChanged={value =>
                  (beginningManualBookValue || beginningManualBookValue === 0) &&
                  value &&
                  value > beginningManualBookValue &&
                  setFieldValue(transitionBookValueField, beginningManualBookValue)
                }
              />
            </Item>
          )}
          {[
            DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200,
            DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250,
          ].includes(depreciationCode) && (
            <Item
              label={
                <ItemLabelTooltip
                  label={t('revised_acquisition_amount')}
                  tooltip={parser(t('revised_acquisition_amount_tooltip'))}
                  tooltipMaxWidth={600}
                />
              }
              required
              jsLabel={t('revised_acquisition_amount_of', { name: ledgerName })}
            >
              <MoneyInput name={revisedAcquisitionAmountField} disabled={disabled || isCopied} />
            </Item>
          )}
          <DepreciatedMonthsInput
            name={depreciatedMonthsField}
            dMethodCode={depreciationCode}
            usagePeriodMonthsField={usagePeriodMonthsField}
            serviceLifeField={serviceLifeField}
            usageStartedAt={usageStartedAt}
            transitionAt={transitionAt}
            ledgerName={ledgerName}
            disabled={disabled}
            isCopied={isCopied}
          />
        </>
      )
    }
    if (
      ![
        DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
        DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
        DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
        DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
      ].includes(depreciationCode)
    ) {
      return (
        <Item label={t('beginning_book_value')}>
          <BeginningBookValue
            value={beginningBookValue || 0}
            placeholder={t('beginning_book_value_placeholder')}
            hasManualValue={getIn(values, accountingBeginningBookManualValueField)}
            maxValue={1000000000000}
            editable={false}
            onChange={handleBeginningBookValueChanged}
            onClickResetValue={handleResetBeginningBookValue}
          />
        </Item>
      )
    }
    return null
  }

  const renderAccumulatedExcessDepreciation = () => {
    const ledgerPurpose = ledgerSettingList?.[ledgerIndex]?.ledger_purpose
    const ledgerPurposeList = ledgerSettingList?.map(ledgetSetting => ledgetSetting?.ledger_purpose)
    if (
      isSmallAmountDepreciationMethod ||
      !hasTransition ||
      !ledgerPurposeList?.includes(LEDGER_PURPOSE.ACCOUNTING) ||
      ledgerPurpose !== LEDGER_PURPOSE.TAX
    )
      return
    const isError = get(values, accumulatedExcessDepreciationValueField) > acquisitionCostInput

    return (
      <Item label={t('accumulated_excess_depreciation')}>
        <MoneyInput
          name={accumulatedExcessDepreciationValueField}
          disabled={disabled}
          className={classNames({
            error: isError,
          })}
        />
        {isError && <Error>{t('accumulated_excess_depreciation_value_error')}</Error>}
      </Item>
    )
  }

  const renderMemorandumValue = () => {
    if (DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD === depreciationCode) {
      return (
        <Item label={t('memorandum_value')}>
          <BeginningBookValue
            value={0}
            placeholder=""
            hasManualValue={false}
            maxValue={1000000000000}
            editable={false}
          />
        </Item>
      )
    }
    return null
  }
  const canDisableTaxMemorandumField = useCallback(() => {
    // Prepare data by merge fixed_asset_ledgers with ledger_settings data
    const ledgerMergeSetting = fixedAssetLedgers?.map(faLedger => ({
      ...faLedger,
      ...ledgerSettingList?.find(ledgerSetting => ledgerSetting?.id === faLedger?.ledger_setting_id),
    }))
    const accountantDmethod =
      ledgerMergeSetting?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING)
        ?.depreciation_method_code || 0
    const taxDmethod =
      ledgerMergeSetting?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.TAX)?.depreciation_method_code || 0
    // Check 2 Dmethod belong to 5 Dmethods that allow input memorandum value
    return checkShowMemorandumValue(accountantDmethod) && checkShowMemorandumValue(taxDmethod)
  }, [fixedAssetLedgers, ledgerSettingList])

  const renderMemorandumValueWithSomeDMethods = () => {
    const isShowMemorandumValue = checkShowMemorandumValue(depreciationCode)
    const isAccountantLedger = isAccountingJournalTarget(ledgerSettingList?.[ledgerIndex])
    const hasImpairmentEvent = !!get(values, 'impaired_at') || !!accumulatedImpairmentAmountValue
    const isUnitUnlink = !get(values, 'unit.link_memorandum_value_quantity')

    const isShowMemorandumValueErrorMessage =
      showMemorandumValueErrorMessage && showMemorandumValueErrorMessage[ledgerId]
    const isTaxLedger = isTaxJournalTarget(ledgerSettingList?.[ledgerIndex])
    const getErrorMessage = () => {
      if (formMode === FORM_MODES.EDIT) {
        return t('memorandum_value_error_message_edit')
      }
      if ([FORM_MODES.NEW, FORM_MODES.COPY, FORM_MODES.IMPORT, FORM_MODES.CORRECTION].includes(formMode)) {
        return hasTransition ? t('memorandum_value_error_message_transaction') : t('memorandum_value_error_message_new')
      }
    }

    return isShowMemorandumValue ? (
      <Item label={t('memorandum_value')}>
        {linkMemorandumValueQuantity ? (
          <BeginningBookValue
            value={get(values, memorandumValueField)}
            placeholder=""
            hasManualValue={false}
            maxValue={1000000000000}
            editable={false}
            labelClassName={isShowMemorandumValueErrorMessage ? 'error' : undefined}
          />
        ) : (
          <MoneyInput
            name={memorandumValueField}
            style={{ textAlign: 'right' }}
            className={classNames({ error: isShowMemorandumValueErrorMessage })}
            disabled={
              (isTaxLedger && canDisableTaxMemorandumField()) ||
              isCopied ||
              (hasImpairmentEvent &&
                isAccountantLedger &&
                ((formMode === FORM_MODES.EDIT && !isUnitUnlink) || isUnitUnlink) &&
                formMode === FORM_MODES.EDIT)
            }
            defaultValue={
              assetType === ASSET_TYPE.TANGIBLE
                ? DEFAULT_MEMORANDUM_VALUE.TANGIBLE
                : DEFAULT_MEMORANDUM_VALUE.INTANGIBLE
            }
            onChange={() => setFieldValue(isEnteredField, true)}
          />
        )}
        {isShowMemorandumValueErrorMessage && <Error>{getErrorMessage()}</Error>}
      </Item>
    ) : null
  }

  const filteredDepreciationMethods = useMemo(
    () => masterData?.depreciation_method || [],
    [masterData?.depreciation_method]
  )

  const renderAccumulatedDepreciationAmount = () => {
    if (!hasTransition) return
    if ([FORM_MODES.NEW, FORM_MODES.COPY].includes(formMode)) return
    return (
      <Item label={t('accumulated_depreciation_amount')}>
        <UnderlineMoneyInput style={{ textAlign: 'right' }} name={accumulatedDepreciationAmount} />
      </Item>
    )
  }

  const renderAccumulatedImpairmentAmount = () => {
    const ledgerPurpose = ledgerSettingList?.[ledgerIndex]?.ledger_purpose
    if (!hasTransition) return
    if ([FORM_MODES.NEW, FORM_MODES.COPY].includes(formMode)) return
    if (![LEDGER_PURPOSE.ACCOUNTING, LEDGER_PURPOSE.TAX_AND_ACCOUNTING].includes(ledgerPurpose)) return
    return (
      <Item label={t('accumulated_impairment_amount')}>
        <UnderlineMoneyInput style={{ textAlign: 'right' }} name={accumulatedImpairmentAmount} />
      </Item>
    )
  }

  useEffect(() => {
    const beginningBookValue = get(values, accountingBeginningBookManualValueField)

    if (isEnableFullFields) {
      if (
        (!isTaxMethodExclude && beginningBookValue > acquisitionCostInput) ||
        (isTaxMethodExclude && beginningBookValue > acquisitionExcludedInput)
      ) {
        setFieldValue(accountingBeginningBookManualValueField, 0)
        // comment out this line to fix a bug
        // setFieldValue(transitionBookValueField, 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingBeginningBookManualValueField, acquisitionCostInput, acquisitionExcludedInput, isTaxMethodExclude])
  const { isEnable: isEnableDmethodD10D11 } = useCheckFlag(FeatureFlagNames.ChangeDmethodD10D11)
  return (
    <div>
      <LedgerHeader>
        <TitleName>{ledgerName || ledgerSettingList?.[ledgerIndex]?.name}</TitleName>
        {ledgerIndex !== 0 && (
          <Checkbox name={isCopiedField} style={{ color: '#7C8291', fontSize: 13 }} disabled={isEditMode}>
            {t('copy_content_ledger_head', {
              ledger_name: ledgerSettingList?.[0]?.name || '',
            })}
          </Checkbox>
        )}
      </LedgerHeader>
      <Item label={t('depreciation_type')} required jsLabel={t('ledger_of', { name: ledgerName })}>
        <AutoCompleteSelect
          name={depreciationMethodCodeField}
          items={filteredDepreciationMethods}
          afterChanged={handleOnChangeDepreciationType}
          valueField="code"
          textField="name_jp"
          disabled={disabled || isCopied}
          allowClear={false}
          className={classNames({ error: showRequiredError && !get(values, depreciationMethodCodeField) })}
        />
        {showRequiredError && !get(values, depreciationMethodCodeField) && (
          <Error>{t('depreciation_type_required_error')}</Error>
        )}
      </Item>
      {isEnableFullFields ? (
        <>
          {renderServiceLife()}
          {renderNumberOfAvgUsagePeriod()}
          {isEnableDmethodD10D11 && (
            <ServiceLifeAfterChange
              fieldName={serviceLifeAfterChangeField}
              hasTransition={hasTransition}
              depreciationCode={depreciationCode}
              setFieldValue={setFieldValue}
              enabled={!disabled && !isCopied && !isEmptyValue(serviceLifeValue)}
              formValues={values}
              initialValue={serviceLifeValue}
            />
          )}
          {hasTransition && renderTransitionAt()}
          {isEnableDmethodD10D11 && (
            <BookValueAtChange
              fieldName={bookValueAtChangeField}
              hasTransition={hasTransition}
              depreciationCode={depreciationCode}
              setFieldValue={setFieldValue}
              enabled={!disabled && !isCopied && !isEmptyValue(bookValueAtChangeReference)}
              formValues={values}
              initialValue={bookValueAtChangeReference}
            />
          )}
          {renderBookValues()}
          {renderResidualValueEndOfUsage()}
          {renderAccumulatedExcessDepreciation()}
          {renderMemorandumValue()}
          {renderDepreciationRate()}
          {renderAccumulatedDepreciationAmount()}
          {renderAccumulatedImpairmentAmount()}
          {!!getIn(values, guaranteeRateField) && (
            <Item label={t('guarantee_rate')}>
              <UnderlineInput name={guaranteeRateField} placeholder={t('guarantee_rate_placeholder')} />
            </Item>
          )}
          {!!getIn(values, revisedDepreciationRateField) && (
            <Item label={t('revised_depreciation_rate')}>
              <UnderlineInput
                name={revisedDepreciationRateField}
                placeholder={t('revised_depreciation_rate_placeholder')}
              />
            </Item>
          )}
          {renderMemorandumValueWithSomeDMethods()}
        </>
      ) : (
        <>
          {hasTransition && renderTransitionAt()}
          {depreciationCode === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE && renderAccumulatedImpairmentAmount()}
        </>
      )}
    </div>
  )
}

export default DepreciationLedge
