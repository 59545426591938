import * as depreciationRatesApi from 'api/app/depreciationRates'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import { AssetLedger } from 'models/asset'
import { useEffect, useState } from 'react'

export function useGetDepreciation(ledger: AssetLedger) {
  const [depreciationRates, getDepreciationRates] = useResource(depreciationRatesApi.get)
  const [renderDepreciationRate, setDepreciationRate] = useState<any>(null)

  useEffect(() => {
    getDepreciationRates({ depreciation_method: ledger.depreciation_method.code, service_life: ledger.service_life })
  }, [ledger]) // eslint-disable-line

  useEffect(() => {
    if (depreciationRates.data?.length > 0) {
      const depreciationRateData = get(depreciationRates, 'data[0]')
      setDepreciationRate(depreciationRateData)
    }
  }, [depreciationRates])
  return { renderDepreciationRate }
}
