import { ifElse } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Input, RegexInput } from 'components/atoms'
import { AutoCompleteSelect, BasicForm, DatePicker } from 'components/molecules'
import { useFormikContext } from 'formik'
import React from 'react'
import { DepartmentItemOnCloud } from 'store/settings/department/model'

import { validateForm, ValueFields } from './helpers'

const t = loadNs(['common', 'components/organisms/settings/department-form'])

const { Group, Header, Item, SubmitButton } = BasicForm

type Props = {
  prepressCategories: any[]
  departmentOnCloud: DepartmentItemOnCloud[]
}

const Form = ({ prepressCategories, departmentOnCloud }: Props) => {
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const isValidFields = validateForm(values)

  const handleBlurDepartmentName = (newValue: string) => {
    !newValue && setFieldValue('ref_id', null)

    if (departmentOnCloud && newValue) {
      const departmentNames = departmentOnCloud?.filter(
        ({ name }: any) => name && name.toLocaleLowerCase() === newValue.toLocaleLowerCase()
      )
      setFieldValue('ref_id', ifElse(departmentNames.length > 0, departmentNames?.[0]?.id, null))
    }
  }

  return (
    <>
      <Group>
        <Header>{t('form_title')}</Header>
        <Item required label={t('code')}>
          <RegexInput name="code" placeholder={t('placeholder.under_10')} maxLength={10} />
        </Item>
        <Item required label={t('name')}>
          <Input
            name="name"
            placeholder={t('placeholder.under_200')}
            maxLength={200}
            afterBlur={handleBlurDepartmentName}
          />
        </Item>
        <Item label={t('department_on_cloud')}>
          <AutoCompleteSelect name="ref_id" items={departmentOnCloud} valueField="id" textField="name" />
        </Item>
        <Item required label={t('prepress_category')}>
          <AutoCompleteSelect
            name="prepress_category_code"
            placeholder={t('placeholders.prepress_category_placeholder')}
            items={prepressCategories}
            valueField="code"
            textField="name_jp"
          />
        </Item>
        <Item label={t('search_key')}>
          <Input name="search_key" maxLength={20} placeholder={t('placeholder.under_20')} />
        </Item>
        <Item required label={t('valid_from')}>
          <DatePicker
            name="valid_from"
            placeholder={t('placeholders.valid_from_placeholder')}
            style={{ width: 172 }}
            maxDateField="valid_to"
            allowClear={false}
          />
        </Item>
        <Item label={t('valid_to')}>
          <DatePicker
            name="valid_to"
            placeholder={t('placeholders.valid_to_placeholder')}
            style={{ width: 172 }}
            unsetable
            minDateField="valid_from"
          />
        </Item>
      </Group>
      <SubmitButton isDisableBtn={!isValidFields} />
    </>
  )
}

export default Form
