import { Button } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { Radio } from 'antd'
import { AutoCompleteSelect, ImportExportButton } from 'components/molecules'
import { FEATURES_ID } from 'constants/const'
import { useFormikContext } from 'formik'
import i18n from 'i18n'
import useUserPermission from 'lib/hooks/useUserPermission'
import { LedgerSettingsItem } from 'models/ledgerSetting'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeLocation } from 'store/app/actions'
import { isAccountingJournalTarget } from 'utils/LedgerHelper'

import { Aggregation, AGGREGATIONS, UNITS } from './const'
import { RadioGroupWrapper, WrapperForm, WrapperLedger } from './styles'

interface Props {
  terms: any[]
  ledgers: LedgerSettingsItem[]
  hasData: boolean
  onValuesChanged: (values: any) => void
  onClickExport: () => void
  handleExportUnit: (value: any) => void
}

interface ValueFields {
  ledger_setting_id: number
  term_id: number
  unit: number
  mode: number
}

const parseTerms = (terms: any[]) => {
  return (
    !!terms &&
    terms.map(term => {
      return {
        value: term.id,
        text: `${i18n.t('common.format.year_term', { year: term.year, term: term.period })}`,
      }
    })
  )
}

const Form: React.FC<Props> = ({ terms, ledgers, hasData, onValuesChanged, onClickExport, handleExportUnit }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const { permissions: ledgerSettingsPermission } = useUserPermission(FEATURES_ID.SETTING_LEDGER)

  useEffect(() => {
    onValuesChanged(values)
  }, [values]) // eslint-disable-line

  const handleChangeUnit = (e: any) => {
    const { value } = e.target
    handleExportUnit(value)
    setFieldValue('unit', value)
  }

  const handleChangeAggregationMode = (e: any) => {
    const { value } = e.target
    setFieldValue('mode', value)
  }

  const handleOnChangeLedger = (e: any) => {
    const { value } = e.target
    setFieldValue('ledger_setting_id', value)

    const ledger = ledgers.find(({ id }) => id === value)
    const isJournalTarget = ledger && isAccountingJournalTarget(ledger)
    if (!isJournalTarget) {
      setFieldValue('mode', Aggregation.DEPRECIATION_CALCULATION)
    }
  }

  const handleGoToLedgerSettings = () => {
    const backUrl = `${location.pathname}${location.search}`
    dispatch(changeLocation(`/setting/ledgers?backUrl=${backUrl}`))
  }

  const { ledger_setting_id, unit, mode } = values

  const aggregationsModes = useMemo(() => {
    const ledger = ledgers.find(({ id }) => id === ledger_setting_id)
    const isJournalTarget = ledger && isAccountingJournalTarget(ledger)

    return AGGREGATIONS.map(mode => ({
      ...mode,
      disabled: mode.value === Aggregation.JOURNAL_RESULT && !isJournalTarget,
    }))
  }, [ledger_setting_id, ledgers])

  const showNoPermissionMessage = useCallback((permission: boolean) => {
    return !permission ? i18n.t('common.actions.no_permission') : undefined
  }, [])

  return (
    <>
      <WrapperForm>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label>{i18n.t('components.DepreciationResultList.fiscal_year')}</label>
            <AutoCompleteSelect
              name="term_id"
              items={parseTerms(terms)}
              valueField="value"
              textField="text"
              style={{ width: 150, marginLeft: 10 }}
              allowClear={false}
            />
          </div>
          <Button
            showTooltipMessage={showNoPermissionMessage(ledgerSettingsPermission.isRead)}
            disabled={!ledgerSettingsPermission.isRead}
            color="grey"
            onClick={handleGoToLedgerSettings}
          >
            {i18n.t('components.LedgerSettings.title')}
          </Button>
        </div>
      </WrapperForm>
      <WrapperForm>
        <WrapperLedger>
          <Radio.Group onChange={handleOnChangeLedger} optionType="button">
            {ledgers?.map(({ id, name }) => (
              <Radio.Button key={id} value={id} className={ledger_setting_id === id ? 'active' : ''}>
                {name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </WrapperLedger>
        <If condition={hasData}>
          <ImportExportButton type="export" onClick={onClickExport} />
        </If>
      </WrapperForm>
      <WrapperForm>
        <RadioGroupWrapper>
          <label style={{ marginRight: '10px' }}>{i18n.t('components.DepreciationResultList.aggregation')}</label>
          <Radio.Group
            onChange={handleChangeAggregationMode}
            optionType="button"
            value={mode}
            options={aggregationsModes}
          />
        </RadioGroupWrapper>
        <RadioGroupWrapper>
          <label style={{ marginRight: '10px' }}>{i18n.t('components.DepreciationResultList.unit')}</label>
          <Radio.Group onChange={handleChangeUnit} optionType="button" value={unit} options={UNITS} />
        </RadioGroupWrapper>
      </WrapperForm>
    </>
  )
}

export default Form
