import { ASSET_CHANGE_SITUATION_TYPES, EMPTY_SYMBOL } from 'aa_common/front-end/constants'
import { formatDate, formatDateTime } from 'aa_common/front-end/helpers'
import { ColumnsType } from 'antd/es/table'
import i18n from 'i18n'
import { get } from 'lodash'
import React from 'react'

import { Photos } from '../../../../assets/AssetDetail/tabs/EventHistory/components/photos'
import { Attachments } from './components/attachments'
import { DetailField } from './components/detail-field'
import { UrlView } from './components/url-view'
import { DetailList, DetailListField, ExtraLine } from './styles'

const DateTemplate = (date: string) => <span>{formatDate(date)}</span>
type DetailTemplateProps = {
  data: any
}

const DetailTemplate: React.FC<DetailTemplateProps> = ({ data }) => {
  const isMovementEvent = get(data, ['change_situation', 'code']) === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT
  const isSwitchEvent = get(data, ['change_situation', 'code']) === ASSET_CHANGE_SITUATION_TYPES.SWITCH

  return (
    <>
      <ExtraLine />
      <DetailList>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">
              {get(data, ['change_situation', 'name_jp']) || ''}
              {i18n.t('components.AssetDetail.reason')}
            </div>
            <div className="value">{get(data, ['event_cause', 'content']) || EMPTY_SYMBOL}</div>
          </div>
        </DetailListField>
        {isMovementEvent && (
          <>
            <DetailListField>
              <DetailField
                titleKey="area"
                previousValue={get(data, ['previous', 'area', 'name'])}
                currentValue={get(data, ['area', 'name'])}
              />
            </DetailListField>
            <DetailListField>
              <DetailField
                titleKey="submit_destination"
                previousValue={get(data, ['previous', 'area', 'submit_destination', 'name'])}
                currentValue={get(data, ['area', 'submit_destination', 'name'])}
              />
            </DetailListField>
            <DetailListField>
              <DetailField
                titleKey="department"
                previousValue={get(data, ['previous', 'department', 'name'])}
                currentValue={get(data, ['department', 'name'])}
              />
            </DetailListField>
          </>
        )}
        {isSwitchEvent && (
          <DetailListField>
            <DetailField
              titleKey="asset_category"
              previousValue={get(data, ['previous', 'asset_category', 'name'])}
              currentValue={get(data, ['asset_category', 'name'])}
            />
          </DetailListField>
        )}
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.photo')}</div>
            <div className="value" style={{ display: 'flex' }}>
              <Photos assetData={data} />
            </div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.attachment_file')}</div>
            <div className="value">
              <Attachments assetData={data} />
            </div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.reference_link')}</div>
            <div className="value">
              <UrlView assetData={data} />
            </div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.registered_by')}</div>
            <div className="value">{get(data, ['user_info', 'display_name'])}</div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{i18n.t('components.AssetDetail.created_at_without_date')}</div>
            <div className="value">{formatDateTime(data.created_at)}</div>
          </div>
        </DetailListField>
      </DetailList>
    </>
  )
}

export const buildEventHistoryColumns = (): ColumnsType<any> => {
  return [
    {
      title: i18n.t('components.AssetDetail.date'),
      dataIndex: 'valid_from',
      key: 'valid_from',
      showSorterTooltip: false,
      sorter: false,
      width: 100,
      render: DateTemplate,
    },
    {
      title: i18n.t('components.AssetDetail.change_situation_type'),
      dataIndex: ['change_situation', 'name_jp'],
      key: 'change_situation',
      sorter: false,
      showSorterTooltip: false,
      width: 160,
    },
    {
      title: i18n.t('components.AssetDetail.content'),
      key: 'details',
      sorter: false,
      showSorterTooltip: false,
      width: 600,
      className: 'content',
      render: function renderDetail(_: string, record: any) {
        return <DetailTemplate data={record} />
      },
    },
  ]
}
