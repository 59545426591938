import {
  checkSettingBelongToMonthlyClosing,
  checkTermBelongToTermSpan,
} from 'aa_common/front-end/helpers/monthly-closing-helper'
import { AutoGenerateSetting } from 'models/assetCode'
import { Term } from 'models/term'
import { useMemo } from 'react'

export const useSettingList = (props: {
  assetCodeAutoGenerateSettingListData: AutoGenerateSetting[]
  isShowFullYears: boolean
  terms: Term[]
  termYearSelected: number | null
  currentMonthlyClosing: any
}) => {
  const { assetCodeAutoGenerateSettingListData, isShowFullYears, terms, termYearSelected, currentMonthlyClosing } =
    props
  const settingList = useMemo(() => {
    if (assetCodeAutoGenerateSettingListData) {
      if (isShowFullYears) {
        return assetCodeAutoGenerateSettingListData.sort((a, b) => b.from_term_id - a.from_term_id)
      }

      if (terms && termYearSelected) {
        const term = terms.find(term => term.id === termYearSelected)
        return assetCodeAutoGenerateSettingListData.filter(setting => checkTermBelongToTermSpan(setting, term))
      }

      if (currentMonthlyClosing) {
        return assetCodeAutoGenerateSettingListData.filter(setting =>
          checkSettingBelongToMonthlyClosing(setting, currentMonthlyClosing)
        )
      }
    }

    return []
  }, [isShowFullYears, assetCodeAutoGenerateSettingListData, currentMonthlyClosing, termYearSelected, terms])

  return { settingList }
}
