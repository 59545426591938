import { JOURNAL_TYPE } from 'aa_common/front-end/constants'
import { loadNs } from 'aa_common/front-end/i18n-config'
import React from 'react'

import { JournalStatusWrapper } from '../styles'

const t = loadNs([
  'components/organisms/accounting/monthly-closing-asset-event-list',
  'components/organisms/accounting/asset-events-report',
])
export const JournalStatus = ({ type, termMonthId }: Props) => {
  const status = getTextByType(type)

  return (
    <JournalStatusWrapper className={status.color}>
      {termMonthId && type === JOURNAL_TYPE.EXPORTED ? t('closing_at', { num: termMonthId }) : status.text}
    </JournalStatusWrapper>
  )
}

const getTextByType = (type: JOURNAL_TYPE): { text: string; color: COLORS } => {
  switch (type) {
    case JOURNAL_TYPE.NOT_EXPORTED:
      return {
        text: t('not_exported'),
        color: COLORS.BLUE,
      }
    case JOURNAL_TYPE.EXPORTED:
      return {
        text: t('exported'),
        color: COLORS.GREY,
      }
    default:
      return {
        text: '',
        color: COLORS.GREY,
      }
  }
}

enum COLORS {
  BLUE = 'blue',
  GREY = 'grey',
}

type Props = {
  type: JOURNAL_TYPE
  termMonthId: number
}
