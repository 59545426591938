import { getIn } from 'formik'
import { useEffect, useMemo } from 'react'

import { genLedgerFieldName } from '../helper'

const enableCopyValue = (fieldName: string, formValues: any, valueFirstItem: any) => {
  return getIn(formValues, fieldName) !== valueFirstItem
}

const getValueOfFirstItem = (name: string, formValues: any) => {
  return getIn(formValues, genLedgerFieldName(name, 0))
}

export function useLedgerCopy(
  formValues: any,
  setFieldValue: (name: string, value: any) => void,
  index: number,
  fields: string[],
  isCopied: boolean
) {
  const firstItemValues = useMemo(
    () => fields.map(name => String(getValueOfFirstItem(name, formValues))).join('-'),
    [fields, formValues]
  )
  useEffect(() => {
    if (isCopied) {
      fields.forEach(name => {
        const valueFirstItem = getValueOfFirstItem(name, formValues)
        const fieldName = genLedgerFieldName(name, index)
        enableCopyValue(fieldName, formValues, valueFirstItem) && setFieldValue(fieldName, valueFirstItem)
      })
    }
  }, [firstItemValues, setFieldValue, index, fields, isCopied])
}
