import {
  Asset,
  ASSET_CHANGE_SITUATION_TYPES,
  Checkbox,
  FeatureFlagNames,
  formatDate,
  formatDateTime,
  getNs,
  identifyJournalStatusType,
  objectToQueryParams,
  openFeatureSlice,
} from 'aa_common/front-end'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { assetEventListReportSlice, resetStore } from 'pages/accounting/AssetEventListReportPage/store'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanupMonthlyClosingAssetEvents,
  fetchMonthlyClosingPreviousAssetEvents,
} from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingPreviousAssetEvents } from 'store/accounting/monthlyClosing/selectors'
import { changeLocation } from 'store/app/actions'

import { handleSelectedItem, renderAssetPhoto } from '../helpers'
import { AssetDisplay, AssetDisplayDataRow, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'
import { Media } from './media'
import { Row } from './row'

const { t, useNsReady } = getNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const SharedEventView = ({ assets, isEventMove = false }: Props) => {
  useNsReady()
  const dispatch = useDispatch()
  const previousAssetEvents = useSelector(selectMonthlyClosingPreviousAssetEvents, isEqual)
  const { isDetailMode, selectedItem } = assetEventListReportSlice.useSlice(state => ({
    isDetailMode: state?.data?.isDetailMode,
    selectedItem: state?.data?.selectedItem,
  }))
  const remoteFlags = openFeatureSlice.useSlice()
  const isAllowForMovementEvent = remoteFlags[FeatureFlagNames.CollectionViewBulkChangeMovement]

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
      resetStore()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (assets && assets.length > 0) {
      // get all events of each asset;
      const assetIds: number[] = []

      assets.forEach(({ change_situation, id }) => {
        if (change_situation?.code === ASSET_CHANGE_SITUATION_TYPES[isEventMove ? 'MOVEMENT' : 'SWITCH']) {
          assetIds.push(id as number)
        }
      })

      if (assetIds.length > 0) {
        dispatch(fetchMonthlyClosingPreviousAssetEvents(assetIds))
      }
    }
  }, [assets, dispatch])

  const previousAssetEventsMap = useMemo(() => {
    if (!previousAssetEvents || previousAssetEvents.length === 0) return null

    const result: any = {}
    previousAssetEvents.forEach(item => {
      result[item.id as number] = get(item, 'previous')
    })

    return result
  }, [previousAssetEvents])

  const handleAssetClick = (asset: Asset) => {
    const paramObj = {
      valid_at: asset.valid_from,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid || ''}${params}`))
  }

  if (!assets) return null
  return (
    <>
      {assets.map((asset, index) => (
        <AssetDisplay key={asset.id} onClick={() => handleAssetClick(asset)} highlight={asset?.highlight}>
          <SharedHeaderView
            asset={asset}
            isDetailMode={isDetailMode}
            isEnableForBulkChangeFeature={isAllowForMovementEvent}
            selectedItem={selectedItem}
            dataTestId="checkbox-item"
          />

          {isDetailMode && (
            <div data-testid="detailMode">
              <AssetDisplayDataRow className={index === 0 ? '' : 'border-top'}>
                <div className="short-label">{asset.valid_from}</div>
                <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
                {isEventMove && (
                  <>
                    <div className="label text-color-666">{t('move_at')}</div>
                    <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
                  </>
                )}
              </AssetDisplayDataRow>
              <AssetDisplayDataRow>
                <div className="label">{t(isEventMove ? 'area' : 'asset_category')}</div>
                <div className="value">
                  {get(previousAssetEventsMap, `[${asset.id || ''}].${isEventMove ? 'area' : 'asset_category'}.name`)}
                  <span className="arrow">→</span>
                  {get(asset, `${isEventMove ? 'area' : 'asset_category'}.name`)}
                </div>
              </AssetDisplayDataRow>
              {isEventMove && (
                <>
                  <AssetDisplayDataRow>
                    <div className="label">{t('submit_destination')}</div>
                    <div className="value">
                      {get(previousAssetEventsMap, `[${asset.id || ''}].area.submit_destination.name`)}
                      <span className="arrow">→</span>
                      {get(asset, 'area.submit_destination.name')}
                    </div>
                  </AssetDisplayDataRow>
                  <AssetDisplayDataRow>
                    <div className="label">{t('department')}</div>
                    <div className="value">
                      {get(previousAssetEventsMap, `[${asset.id || ''}].department.name`)}
                      <span className="arrow">→</span>
                      {get(asset.department, 'name')}
                    </div>
                  </AssetDisplayDataRow>
                </>
              )}
              <Media asset={asset} />
              {!isEventMove && <Row label={t('switch_by')} value={get(asset.user_info, 'display_name')} />}
              <AssetDisplayDataRow className="last">
                {isEventMove ? (
                  <>
                    <div className="label">{t('move_by')}</div>
                    <div className="value">{get(asset.user_info, 'display_name')}</div>
                  </>
                ) : (
                  <>
                    <div className="label">{t('switch_at')}</div>
                    <div className="value">{formatDate(asset.created_at as string)}</div>
                  </>
                )}
              </AssetDisplayDataRow>
            </div>
          )}
        </AssetDisplay>
      ))}
    </>
  )
}

export const SharedHeaderView = ({
  asset,
  isDetailMode,
  isEnableForBulkChangeFeature,
  selectedItem,
  dataTestId,
}: {
  asset: AssetWithHighlight
  isDetailMode?: boolean
  isEnableForBulkChangeFeature?: boolean
  selectedItem: any
  dataTestId: string
}) => {
  return (
    <AssetDisplayHeader className={`asset-header ${!isDetailMode ? 'border-bottom-0' : ''}`}>
      {isEnableForBulkChangeFeature && (
        <div className="checkbox-item" data-testid={dataTestId}>
          <Checkbox
            name={`${asset?.id}`}
            checked={selectedItem && selectedItem[asset.id!]}
            onChange={handleSelectedItem}
          />
        </div>
      )}
      <div className="status">
        <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={asset?.term_month?.month || null} />
      </div>
      <div className="photo">{renderAssetPhoto(asset)}</div>
      <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
      <div className="asset-name">{asset.name}</div>
    </AssetDisplayHeader>
  )
}

export type AssetWithHighlight = Asset & {
  highlight?: boolean
}
type Props = {
  assets: AssetWithHighlight[]
  isEventMove?: boolean
}
