import get from 'lodash/get'
import set from 'lodash/set'
import moment from 'moment'

import {
  API_STATUS_CODE,
  ASSET_EVENT_FIELD,
  DEPRECIATION_METHOD,
  DEPRECIATION_METHOD_CODE,
  JOURNAL_TYPE,
  LEDGER_PURPOSE,
  RECALCULATE_STATUS,
} from '../constants'
import { getNs } from '../i18n-config'
import { Asset, AssetLedger, AssetLedgerReport, LedgerSetting, MonthlyClosing } from '../models'
import { isAccountingJournalTarget, yen } from './index'

const { t } = getNs('common')

export const initialAssetLedgersFromSettings = (ledgerSettings: LedgerSetting[]): AssetLedger[] => {
  return ledgerSettings.map(ledgerSetting => ({
    name: ledgerSetting.name,
    depreciation_method_code: null,
    service_life: 2,
    accounting_beginning_book_initial_value: 0,
    transition_beginning_book_value: null,
    memorandum_value: null,
    ledger_setting_id: ledgerSetting.id as number,
    retirement_depreciation_ended_method: null,
  }))
}

export const parseDataForForm = (asset: Asset) => {
  const result: Asset = { ...asset }

  result.tags = (asset?.tags || []).map((tag: any) => {
    return typeof tag === 'string' ? tag : tag?.id
  })
  result.tag_names = (asset?.tags || []).map((tag: any) => {
    return typeof tag === 'string' ? tag : tag?.name
  })
  result.asset_category_id = asset?.asset_category
    ? {
        value: get(asset, 'asset_category.id'),
        label: get(asset, 'asset_category.name'),
      }
    : null

  result.department_biid = asset?.department
    ? {
        value: get(asset, 'department.biid'),
        label: get(asset, 'department.name'),
      }
    : null

  result.area_biid = asset?.area
    ? {
        value: get(asset, 'area.biid'),
        label: get(asset, 'area.name'),
      }
    : null

  result.asset_type_for_corporate_tax_code = get(asset, ['asset_type_for_corporate_tax', 'code'])
  result.asset_type_for_depreciable_property_tax_code = get(asset, ['asset_type_for_depreciable_property_tax', 'code'])
  result.unit_id = get(asset, ['unit', 'id'])
  result.acquire_category_code = get(asset, ['acquire_category', 'code'])
  result.edited_at = moment()

  if (result.exemption_numerator > 0 || result.exemption_denominator > 0) {
    result.has_exemption = true
  }

  if (result.transition_at) {
    result.has_transition = true
  }

  return result
}

export const parseDataForSubmit = (asset: Asset) => {
  const result = { ...asset }
  result.department_biid = asset.department_biid?.value || null
  result.area_biid = asset.area_biid?.value || null
  result.asset_category_id = asset.asset_category_id?.value || null
  // Remove book value at change reference before submit
  delete (result as any).book_value_at_change_reference

  if (get(result, 'tags')) {
    result.tags = result.tags.map((item: any) => ({ id: item }))
  }

  return getAssetPayload(result)
}

export const mergeAssetLedgersWithLedgerSettings = (assetLedgers: AssetLedger[], ledgerSettings: LedgerSetting[]) => {
  if (!assetLedgers || !ledgerSettings) return []

  const newAssetLedgers: AssetLedger[] = []

  ledgerSettings.forEach(ledgerSetting => {
    const assetLedger = assetLedgers.find(item => item.ledger_setting_id === ledgerSetting.id)

    if (assetLedger) {
      newAssetLedgers.push({ ...assetLedger })
    } else {
      newAssetLedgers.push({
        name: ledgerSetting.name,
        depreciation_method_code: null,
        service_life: 2,
        accounting_beginning_book_initial_value: 0,
        transition_beginning_book_value: null,
        memorandum_value: null,
        ledger_setting_id: ledgerSetting.id as number,
        retirement_depreciation_ended_method: null,
      })
    }
  })

  return newAssetLedgers
}

export const isRetroactiveAsset = (asset: Asset, processingMonth: MonthlyClosing) => {
  const acquiredAt = moment(asset?.acquired_at)
  const isTransitionAt = !!asset?.transition_at

  const processingMonthStartDate = moment(processingMonth?.from_term_month?.start_date)

  return !isTransitionAt && acquiredAt.isBefore(processingMonthStartDate, 'date')
}

export const isRetroactiveEvent = (event: any, processingMonth: MonthlyClosing) => {
  const usageStartedAt = moment(event?.usage_started_at)
  const processingMonthStartDate = moment(processingMonth?.from_term_month?.start_date)

  return usageStartedAt.isBefore(processingMonthStartDate, 'date')
}

export const hasRetroactiveAssets = (assets: Asset[], processingMonth: MonthlyClosing) =>
  assets.some(asset => isRetroactiveAsset(asset, processingMonth))

interface Props {
  res: any
  assetItem?: Asset
  assetEvent?: any
  currentMonthlyClosing: any
  confirmModal: any
}

export const checkRetroActiveFixedAsset = ({
  res,
  assetItem,
  assetEvent,
  currentMonthlyClosing,
  confirmModal,
}: Props) => {
  const isSuccessStatus = res?.status === API_STATUS_CODE.CREATED

  if (isSuccessStatus) {
    if (
      assetItem
        ? isRetroactiveAsset(assetItem, currentMonthlyClosing)
        : isRetroactiveEvent(assetEvent, currentMonthlyClosing)
    ) {
      // Open the retroactive asset warning modal
      return confirmModal.open({})
    }
    return true
  }
}

export const getAssetPayload = (assetItem: any) => {
  if (assetItem?.fixed_asset_ledgers && Array.isArray(assetItem?.fixed_asset_ledgers)) {
    assetItem?.fixed_asset_ledgers?.map((asset: any) => {
      if (asset?.depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIATION) {
        delete asset.usage_period_months
      }
      return asset
    })
    // Parse memorandum value to number type because it is string type in case edit no change quantity
    assetItem?.fixed_asset_ledgers?.forEach((asset: any) => {
      asset.memorandum_value = parseInt(asset?.memorandum_value, 10)
    })
  }
  return assetItem
}

export const getRecalculateError = (recalculateHistory: any) => {
  if (recalculateHistory && recalculateHistory?.[0]?.status === RECALCULATE_STATUS.PROCESSING) {
    const recalculateErrorMessage = t('messages.action_failure_by_recalculate')
    const recalculateError = { errors: [{ detail: recalculateErrorMessage }] }

    return recalculateError
  }
  return null
}

export function syncupDepreciationMethodTaxLedger(
  data: any,
  ledgerSettings: Array<LedgerSetting>,
  assetItem: Asset | null,
  isEditMode: boolean,
  formValues: any
) {
  if (data) {
    const accountantLedger = ledgerSettings.find(ledger => ledger.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING)
    const accountantIndexLedger = ledgerSettings.findIndex(
      ledger => ledger.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
    )
    const taxLedger = ledgerSettings.find(ledger => ledger.ledger_purpose === LEDGER_PURPOSE.TAX)
    const taxIndexLedger = ledgerSettings.findIndex(ledger => ledger.ledger_purpose === LEDGER_PURPOSE.TAX)
    const accountantRetirementFixAssetledger = data?.find(
      (item: any) => item?.ledger_setting_id === accountantLedger?.id
    )
    // Check accountant ledger that allow copy retirement field
    const assetAccountantLedger = assetItem?.fixed_asset_ledgers?.find(
      item => item.ledger_setting_id === accountantLedger?.id
    )
    const accOldRetirementDepreciation =
      assetAccountantLedger && assetAccountantLedger.retirement_depreciation_ended_method
    const accIsSmallAmountDepreciableAsset =
      assetAccountantLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
    const accIsBulkEqualInstallment =
      assetAccountantLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
    const accIsUndefinedValue =
      get(
        formValues,
        `retirement_fixed_asset_ledger_requests[${accountantIndexLedger}].retirement_depreciation_ended_method`
      ) === undefined
    const accIsDepreciationMethodContinue =
      accountantLedger?.bulk_equal_installment_depreciation_method === DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
    const accIsValidDepreciationMethod = accIsDepreciationMethodContinue && accIsBulkEqualInstallment
    const canCopyAccountantEndedMethod = !(
      (isEditMode && accIsBulkEqualInstallment && accOldRetirementDepreciation) ||
      accIsValidDepreciationMethod ||
      accIsSmallAmountDepreciableAsset ||
      (accIsUndefinedValue && !accIsSmallAmountDepreciableAsset && !accIsValidDepreciationMethod)
    )
    // Check tax ledger that allow copy retirement field
    const assetTaxLedger = assetItem?.fixed_asset_ledgers?.find(item => item.ledger_setting_id === taxLedger?.id)
    const oldRetirementDepreciation = assetTaxLedger && assetTaxLedger.retirement_depreciation_ended_method
    const isSmallAmountDepreciableAsset =
      assetTaxLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
    const isBulkEqualInstallment =
      assetTaxLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
    const isUndefinedValue =
      get(
        formValues,
        `retirement_fixed_asset_ledger_requests[${taxIndexLedger}].retirement_depreciation_ended_method`
      ) === undefined
    const isDepreciationMethodContinue =
      taxLedger?.bulk_equal_installment_depreciation_method === DEPRECIATION_METHOD.CONTINUE_DEPRECIATION
    const isValidDepreciationMethod = isDepreciationMethodContinue && isBulkEqualInstallment
    const canCopyTaxEndedMethod = !(
      (isEditMode && isBulkEqualInstallment && oldRetirementDepreciation) ||
      isValidDepreciationMethod ||
      isSmallAmountDepreciableAsset ||
      (isUndefinedValue && !isSmallAmountDepreciableAsset && !isValidDepreciationMethod)
    )

    const dataProcessed = data?.map((item: any) => {
      // Check data item is tax ledger and  both of accountant ledger and tax ledger allow copy retirement field
      if (item?.ledger_setting_id === taxLedger?.id && canCopyAccountantEndedMethod && canCopyTaxEndedMethod) {
        set(
          item,
          'retirement_depreciation_ended_method',
          accountantRetirementFixAssetledger?.retirement_depreciation_ended_method
        )
      }
      return item
    })
    return dataProcessed
  }
  return data
}

export const identifyJournalStatusType = (asset: Asset) => {
  if (asset?.is_exported || asset?.is_linked) {
    return JOURNAL_TYPE.EXPORTED
  }

  return JOURNAL_TYPE.NOT_EXPORTED
}

export const renderAssetFields = (fixAssetLedgers: AssetLedgerReport[], type: ASSET_EVENT_FIELD) => {
  let element

  fixAssetLedgers?.forEach(item => {
    const accountingJournalTarget = isAccountingJournalTarget(item?.ledger_setting)

    if (accountingJournalTarget) {
      if (type === ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION) {
        element = yen(item.accumulated_depreciation_amount)
      } else {
        element = item.loss > 0 ? `${yen(item.loss)}` : `${yen(item.gain)}`
      }
    }
  })

  return element
}

export const hasEventOccurred = (eventList: Asset[], eventCode: number) => {
  return eventList.some((event: Asset) => event?.change_situation?.code === eventCode)
}
