import {
  checkRetroActiveFixedAsset,
  getAssetPayload,
  initialAssetLedgersFromSettings,
  mergeAssetLedgersWithLedgerSettings,
} from 'aa_common/front-end/helpers'
import * as assetApi from 'api/app/asset'
import { TempDataTypes } from 'api/app/tempData'
import { BreadcrumbNavigation } from 'components/molecules'
import { AssetForm, AssetTemporaryDataModal } from 'components/organisms'
import { defaultAssetValues, validateRequiredField } from 'components/organisms/assets/AssetForm/helper'
import { getGeneralValidationErrors } from 'components/organisms/assets/AssetForm/validations/asset-schema'
import { MainTemplate } from 'components/templates'
import useRetroactiveModal from 'components/templates/modal/RetroactiveAssetModal'
import i18n from 'i18n'
import useResource from 'lib/hooks/useResource'
import { isEqual } from 'lodash'
import { Asset } from 'models/asset'
import { LedgerSetting } from 'models/ledgerSetting'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { cleanupAssetItem, createAsset } from 'store/asset/actions'
import { selectAssetItemErrors, selectAssetItemLoading } from 'store/asset/selectors'
import { fetchTerms } from 'store/settings/accountingPeriod/actions'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'
import { clearTempData, createTempData, fetchTempData } from 'store/tempData/actions'
import { selectTempDataData, selectTempDataSaveAt } from 'store/tempData/selectors'
import { parseErrorResponse } from 'utils/ResponseHelper'

const AssetNewPage = () => {
  const dispatch = useDispatch()
  const confirmModal = useRetroactiveModal()

  const [validateAssetRes, validateAsset] = useResource(assetApi.create)
  const isValidateAssetLoading = validateAssetRes.isLoading

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [showRequiredError, setShowRequiredError] = useState<boolean>(false)
  const [asset, setAsset] = useState()
  const breadcrumb = <BreadcrumbNavigation enableBackButton sectionName={i18n.t('pages.assets.add')} />
  const [initialAssetData, setInitialAssetData] = useState<Asset | null>(null)
  const [isAppliedTemporary, setIsAppliedTemporary] = useState<boolean>(false)

  const ledgerSettings: LedgerSetting[] = useSelector(selectLedgerSettingList, isEqual)
  const terms = useSelector(selectTermsData, isEqual)
  const tempAssetData = useSelector(selectTempDataData, isEqual)
  const tempAssetSavedAt = useSelector(selectTempDataSaveAt, isEqual)
  const errors = useSelector(selectAssetItemErrors, isEqual)
  const loading = useSelector(selectAssetItemLoading, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const [validateErrors, setValidateErrors] = useState(null)

  const handleSubmit = (assetItemParams: any) => {
    const assetItem = getAssetPayload(assetItemParams)
    setAsset(assetItem)
    if (validateRequiredField(assetItem) || !!getGeneralValidationErrors().join(',')) {
      setShowRequiredError(true)
      return
    }

    validateAsset({ ...assetItem, dry_run: true })
      .then(res => {
        if (res.error) {
          parseErrorResponse(
            {
              response: {
                status: res.status,
                data: { errors: res.error },
              },
            },
            setValidateErrors
          )
        }

        return checkRetroActiveFixedAsset({
          res,
          assetItem,
          currentMonthlyClosing,
          confirmModal,
        })
      })
      .then(isOK => {
        isOK === true && dispatch(createAsset(assetItem))
      })
  }

  const handleCancelConfirm = () => {
    dispatch(clearTempData())
    setConfirmVisible(false)
    setIsAppliedTemporary(false)
  }

  const handleOkConfirm = () => {
    tempAssetData &&
      setInitialAssetData({
        ...tempAssetData,
        fixed_asset_ledgers: mergeAssetLedgersWithLedgerSettings(tempAssetData.fixed_asset_ledgers, ledgerSettings),
      })
    setConfirmVisible(false)
    setIsAppliedTemporary(true)
  }

  const handleSaveTemp = (dataType: string, data: any) => {
    dispatch(createTempData(dataType, data))
  }

  useEffect(() => {
    dispatch(fetchTempData(TempDataTypes.CREATE_ASSET))
    !terms && dispatch(fetchTerms())
    dispatch(fetchLedgerSettingsList())
  }, []) // eslint-disable-line

  useEffect(() => {
    return function cleanup() {
      dispatch(clearTempData())
      dispatch(cleanupAssetItem())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (tempAssetData) {
      setConfirmVisible(true)
    }
  }, [tempAssetData])

  useEffect(() => {
    if (ledgerSettings && terms) {
      const newInitialAssetData = { ...defaultAssetValues }
      newInitialAssetData.fixed_asset_ledgers = initialAssetLedgersFromSettings(ledgerSettings)
      newInitialAssetData.min_acquired_at = terms[0]?.start_date
      setInitialAssetData(newInitialAssetData)
    }
  }, [ledgerSettings, terms])

  return (
    <MainTemplate breadcrumb={breadcrumb}>
      <AssetForm
        initialAssetData={initialAssetData}
        loading={loading || isValidateAssetLoading}
        errors={validateErrors || errors}
        onSubmit={handleSubmit}
        tempAssetSavedAt={tempAssetSavedAt}
        confirming={confirmVisible}
        hasTerms={terms && terms.length > 0}
        onSaveTemp={handleSaveTemp}
        showRequiredError={showRequiredError}
        asset={asset}
        isTemporaryData={isAppliedTemporary}
      />
      {tempAssetData && (
        <AssetTemporaryDataModal
          isShow={confirmVisible}
          tempAssetData={tempAssetData}
          onCancel={handleCancelConfirm}
          onOk={handleOkConfirm}
        />
      )}
    </MainTemplate>
  )
}

export default AssetNewPage
