import { Asset } from 'aa_common/front-end/models'

import { SharedEventView } from './shared-event-view'

export const SwitchEventView = ({ assets }: Props) => {
  return <SharedEventView assets={assets} />
}

type Props = {
  assets: Asset[]
}
