import {
  clearNullDataDeep,
  downloadFileByResponse,
  formatDay,
  formatMonth,
  formatYear,
  objectToQueryParams,
} from 'aa_common/front-end/helpers'
import * as areaApi from 'api/app/area'
import * as assetApi from 'api/app/asset'
import * as assetCommentApi from 'api/app/assetComment'
import * as departmentApi from 'api/app/department'
import { AxiosResponse } from 'axios'
import history from 'browserHistory'
import { API_STATUS_CODE } from 'constants/apiCode'
import { DATE_FORMAT_RFC } from 'constants/app'
import { ASSET_CHANGE_SITUATION_TYPES } from 'constants/masterData'
import i18n from 'i18n'
import { List } from 'immutable'
import { find, get } from 'lodash'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetSingleViewQueryParams } from 'models/asset'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { CHANGE_LOCATION, setAppMessage, showErrorsAppCall } from 'store/app/actions'
import { findCorrectDataFromHistories } from 'utils/BitemporalHelper'
import { calculatePaginationTotalPages, parseError, parseValidationError } from 'utils/ResponseHelper'

import {
  CreateAssetItemSuccess,
  DeleteAssetItemRequest,
  DeleteAssetRequest,
  FetchAssetEventHistoryRequest,
  FetchAssetEventHistorySuccess,
  FetchAssetItemByValidAtRequest,
  FetchAssetItemRequest,
  FetchAssetListRequest,
  HandleSubmitAssetEventItemFailure,
  HandleSubmitAssetItemFailure,
  SubmitImpairmentEventRequest,
  SubmitModifiedAssetTypeEventRequest,
  UpdateAssetSuccess,
} from './action.model'
import {
  fetchAssetByValidAt as fetchAssetByValidAtAction,
  handleFetchAssetItemByValidAtFailure,
  handleFetchAssetItemByValidAtSuccess,
  handleFetchAssetListFailure,
  handleFetchAssetListSuccess,
} from './actions'
import {
  CREATE_ASSET_EVENT_MOVEMENT_REQUEST,
  CREATE_ASSET_EVENT_MOVEMENT_SUCCESS,
  CREATE_ASSET_EVENT_RETIREMENT_REQUEST,
  CREATE_ASSET_EVENT_RETIREMENT_SUCCESS,
  CREATE_ASSET_EVENT_SELL_REQUEST,
  CREATE_ASSET_EVENT_SELL_SUCCESS,
  CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
  CREATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS,
  CREATE_ASSET_EVENT_USAGE_REQUEST,
  CREATE_ASSET_EVENT_USAGE_SUCCESS,
  CREATE_ASSET_ITEM_REQUEST,
  CREATE_ASSET_ITEM_SUCCESS,
  DELETE_ASSET_ITEM_FAILURE,
  DELETE_ASSET_ITEM_REQUEST,
  DELETE_ASSET_ITEM_SUCCESS,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_UNUSED_ASSET_REQUEST,
  DELETE_UNUSED_ASSET_SUCCESS,
  EXPORT_ASSET_FAILURE,
  EXPORT_ASSET_REQUEST,
  EXPORT_ASSET_SUCCESS,
  FETCH_ASSET_EVENT_HISTORY_FAILURE,
  FETCH_ASSET_EVENT_HISTORY_REQUEST,
  FETCH_ASSET_EVENT_HISTORY_SUCCESS,
  FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST,
  FETCH_ASSET_ITEM_REQUEST,
  FETCH_ASSET_ITEM_SUCCESS,
  FETCH_ASSET_LIST_REQUEST,
  HANDLE_SUBMIT_ASSET_EVENT_FAILURE,
  HANDLE_SUBMIT_ASSET_ITEM_FAILURE,
  SUBMIT_ASSET_EVENT_FAILURE,
  SUBMIT_ASSET_ITEM_FAILURE,
  SUBMIT_IMPAIRMENT_REQUEST,
  SUBMIT_IMPAIRMENT_SUCCESS,
  SUBMIT_MODIFIED_ASSET_TYPE_FAILURE,
  SUBMIT_MODIFIED_ASSET_TYPE_REQUEST,
  SUBMIT_MODIFIED_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_EVENT_MOVEMENT_REQUEST,
  UPDATE_ASSET_EVENT_MOVEMENT_SUCCESS,
  UPDATE_ASSET_EVENT_RETIREMENT_REQUEST,
  UPDATE_ASSET_EVENT_RETIREMENT_SUCCESS,
  UPDATE_ASSET_EVENT_SELL_REQUEST,
  UPDATE_ASSET_EVENT_SELL_SUCCESS,
  UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST,
  UPDATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS,
  UPDATE_ASSET_EVENT_USAGE_REQUEST,
  UPDATE_ASSET_EVENT_USAGE_SUCCESS,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_COMMENT_COUNT_REQUEST,
  UPDATE_COMMENT_COUNT_SUCCESS,
} from './constant'
import { parseAssetData } from './helper'
import { selectAssetHighlightItem, selectAssetsCollectionFormValues, selectAssetsCollectionView } from './selectors'

function* handleSubmitEventFailure({ error }: HandleSubmitAssetEventItemFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  if (errorResponse?.status === 422) {
    const validationErrors = get(error, 'response.data.errors')
    const payload = parseValidationError(validationErrors)
    yield put({ type: SUBMIT_ASSET_EVENT_FAILURE, payload })
  } else {
    yield put(showErrorsAppCall(parseError(error)))
    yield put({ type: SUBMIT_ASSET_EVENT_FAILURE })
  }
}

function* handleSubmitAssetFailure({ error }: HandleSubmitAssetItemFailure) {
  const errorResponse: AxiosResponse<any> = get(error, 'response')
  switch (errorResponse?.status) {
    case 409:
    case 422: {
      const validationErrors = get(error, 'response.data.errors')
      const payload = parseValidationError(validationErrors)
      yield put(showErrorsAppCall(parseError(error)))
      yield put({ type: SUBMIT_ASSET_ITEM_FAILURE, payload })
      break
    }
    default: {
      yield put(showErrorsAppCall(parseError(error)))
      yield put({ type: SUBMIT_ASSET_ITEM_FAILURE })
    }
  }
}

export function* fetchAssetList({ payload, pageNumber, pageSize }: FetchAssetListRequest) {
  try {
    const response: AxiosResponse = yield call(assetApi.search, payload, pageNumber, pageSize)
    const { item: highlightItem, type: highlightType } = yield select(selectAssetHighlightItem)
    const assetCollectionViewSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
      selectAssetsCollectionView
    )

    const isDetailMode = get(assetCollectionViewSelector.filters, 'include_history')
    const listResponse = get(response, 'data.data', null)
    const apiList = List(listResponse || [])
    const isUpdateMode = highlightType === 'update'
    let immutablelist
    let mergedList = []

    if (highlightItem) {
      const highlightItemResponse: AxiosResponse = yield call(
        assetApi.search,
        {
          filters: { biid: [highlightItem.biid], include_history: isDetailMode, ...payload.filters },
          sort: ['valid_from'],
        },
        1,
        1000
      )
      const detailHighlightItem = get(highlightItemResponse, 'data.data[0]')
      const recordIndex = apiList.findIndex((item: any) => item.biid === highlightItem.biid)
      const isRecordFoundInList = recordIndex !== -1
      if (apiList.toArray().length > 0 && isRecordFoundInList) {
        // Occurs when finish create/update asset item but "updated item" is found in API List
        // 1. When finish adding new asset item and "added item" is found in API List -> bring highlight item to the top of the list;
        // 2. When finish update existing asset item and "updated item" is found in API List -> highlight the record in its index;
        immutablelist = isUpdateMode
          ? List(apiList).setIn([recordIndex, 'isHighlight'], true)
          : List(apiList).remove(recordIndex).insert(0, detailHighlightItem).setIn([0, 'isHighlight'], true)
      } else {
        // Occurs when finish create/update asset item but added record is not found in API List
        // Bring highlight item to the top of the list
        immutablelist = List([highlightItem, ...apiList.toArray()]).setIn([0, 'isHighlight'], false)
      }
    } else {
      immutablelist = List(listResponse || [])
    }

    const list = immutablelist.toArray() || []
    if (list && list.length > 0) {
      const commentCountResponse: AxiosResponse = yield call(
        assetCommentApi.getCommentCountByFixedAssets,
        list.map((item: any) => item.biid)
      )
      const commentsCountByFixedAssets =
        commentCountResponse && commentCountResponse.data ? commentCountResponse.data.data : []

      mergedList = list.map((fixedAssetItem: any) => {
        const hasIncludeChangeSituations =
          fixedAssetItem.change_situations && fixedAssetItem.change_situations.length > 0
        const fixedAssetItemCommentInfo = find(commentsCountByFixedAssets, commentCountItem => {
          return commentCountItem.fixed_asset_biid === fixedAssetItem.biid
        })
        return {
          ...fixedAssetItem,
          depreciation_method_1: get(fixedAssetItem, 'fixed_asset_ledgers.[0].depreciation_method.name_jp', null),
          service_life_1: get(fixedAssetItem, 'fixed_asset_ledgers.[0].service_life', null),
          commentCount: fixedAssetItemCommentInfo ? fixedAssetItemCommentInfo.count : 0,
          change_situations: hasIncludeChangeSituations
            ? fixedAssetItem.change_situations.map((changeSituationItem: any) => {
                return {
                  ...changeSituationItem,
                  depreciation_method_1: get(
                    fixedAssetItem,
                    'fixed_asset_ledgers.[0].depreciation_method.name_jp',
                    null
                  ),
                  service_life_1: get(fixedAssetItem, 'fixed_asset_ledgers.[0].service_life', null),
                }
              })
            : null,
        }
      })
    }

    const totalRecords = Number(response.data.meta.total)
    const totalPages = calculatePaginationTotalPages(totalRecords, pageSize)
    yield put(handleFetchAssetListSuccess(mergedList, totalPages, totalRecords))
  } catch (error) {
    yield put(handleFetchAssetListFailure())
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchAssetByValidAt({
  biid,
  valid_at,
  updateValidAtValue,
  isGetDetailOneFixedAsset,
}: FetchAssetItemByValidAtRequest): SagaIterator {
  try {
    const [fixedAssetByValidAt, fixedAssetByLatest] = yield all([
      call(assetApi.get, biid, valid_at),
      call(assetApi.get, biid),
    ])
    yield put(
      handleFetchAssetItemByValidAtSuccess({
        fixedAssetByLatest: parseAssetData(get(fixedAssetByLatest, 'data.data')),
        fixedAssetByValidAt: parseAssetData(get(fixedAssetByValidAt, 'data.data')),
        filterDate: updateValidAtValue ? valid_at : '',
      })
    )

    yield put({
      type: FETCH_ASSET_EVENT_HISTORY_REQUEST,
      payload: {
        filters: {
          biid: [biid],
          ...(isGetDetailOneFixedAsset && { is_get_detail_one_fixed_asset: isGetDetailOneFixedAsset }),
        },
        sort: ['valid_from'],
      },
    })
  } catch (error) {
    yield put(handleFetchAssetItemByValidAtFailure())
    const errorCode = get(error, 'response.data.errors[0].status')
    if (Number(errorCode) === API_STATUS_CODE.NOT_FOUND) {
      const validAtMoment = moment(valid_at)
      const errorMessages = i18n.t('components.AssetDetail.asset_not_exist', {
        year: formatYear(validAtMoment),
        month: formatMonth(validAtMoment),
        day: formatDay(validAtMoment),
      })
      return yield put(setAppMessage({ type: 'failure', content: errorMessages }))
    }
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* fetchAsset({ biid, isGetDetailOneFixedAsset }: FetchAssetItemRequest) {
  try {
    const response: AxiosResponse = yield call(assetApi.get, biid)
    const assetDetail = response.data.data

    yield put({ type: FETCH_ASSET_ITEM_SUCCESS, payload: clearNullDataDeep(parseAssetData(assetDetail)) })
    yield put({
      type: FETCH_ASSET_EVENT_HISTORY_REQUEST,
      payload: {
        filters: {
          biid: [biid],
          ...(isGetDetailOneFixedAsset && { is_get_detail_one_fixed_asset: isGetDetailOneFixedAsset }),
        },
        sort: ['valid_from'],
      },
    })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createAsset({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.create, payload)
    const highlightItem = get(response, 'data.data', null)
    yield put<CreateAssetItemSuccess>({ type: CREATE_ASSET_ITEM_SUCCESS, highlightItem })
    yield put({ type: CHANGE_LOCATION, payload: '/assets' })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetItemFailure>({
      type: HANDLE_SUBMIT_ASSET_ITEM_FAILURE,
      error,
    })
  }
}

export function* fetchAssetEventHistory({ payload, isGetDetailOneFixedAsset }: FetchAssetEventHistoryRequest) {
  try {
    const pageNumber = 1
    const pageSize = 1000
    const response: AxiosResponse = yield call(assetApi.search, payload, pageNumber, pageSize, isGetDetailOneFixedAsset)
    const list = response.data.data
    const totalRecords = Number(response.data.meta.total)

    // TODO: remove below functions when BE have a new API to fetch all history (response will included area, department)
    const areaBiids: string[] = []
    const departmentBiids: string[] = []
    // Get area and department for each history
    for (const item of list) {
      if (item.change_situation.code === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT) {
        // Get area info
        item.area_biid && areaBiids.push(item.area_biid)
        item.department_biid && departmentBiids.push(item.department_biid)

        const response: AxiosResponse = yield call(assetApi.getPreviousOfId, item.biid, item.id)
        item.previous = response.data.data

        item.previous.area_biid && areaBiids.push(item.previous.area_biid)
        item.previous.department_biid && departmentBiids.push(item.previous.department_biid)
      } else if (item.change_situation.code === ASSET_CHANGE_SITUATION_TYPES.SWITCH) {
        const response: AxiosResponse = yield call(assetApi.getPreviousOfId, item.biid, item.id)
        item.previous = response.data.data
      }
    }

    const areaListResponse: AxiosResponse = yield call(areaApi.postSearch, { filters: { biid: areaBiids } })
    const areaList = areaListResponse.data.data
    const departmentListResponse: AxiosResponse = yield call(departmentApi.postSearch, {
      filters: { biid: departmentBiids },
    })
    const departmentList = departmentListResponse.data.data

    list.forEach((item: any) => {
      if (item.change_situation.code === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT) {
        item.area = findCorrectDataFromHistories(areaList, {
          biid: item.area_biid,
          valid_date: item.valid_from,
        })

        item.previous.area = findCorrectDataFromHistories(areaList, {
          biid: item.previous.area_biid,
          valid_date: item.previous.valid_from,
        })

        item.department = findCorrectDataFromHistories(departmentList, {
          biid: item.department_biid,
          valid_date: item.valid_from,
        })

        item.previous.department = findCorrectDataFromHistories(departmentList, {
          biid: item.previous.department_biid,
          valid_date: item.previous.valid_from,
        })
      }
    })

    yield put<FetchAssetEventHistorySuccess>({
      type: FETCH_ASSET_EVENT_HISTORY_SUCCESS,
      payload: { list, totalRecords },
    })
  } catch (error) {
    const payload = parseValidationError(get(error, 'response.data.errors', undefined))
    yield put({ type: FETCH_ASSET_EVENT_HISTORY_FAILURE, payload })
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* createAssetEventUsage({ biid, data, isGetDetailOneFixedAsset }: any) {
  try {
    yield call(assetApi.createUsage, biid, data)
    yield put({ type: CREATE_ASSET_EVENT_USAGE_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* createAssetEventRetirement({ biid, data, isGetDetailOneFixedAsset }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.createRetire, biid, data)
    const retiredAt = get(response, 'data.data.retired_at')
    if (retiredAt) {
      const assetCollectionViewSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
        selectAssetsCollectionView
      )
      const retiredAtDate = moment(retiredAt)
      const filterAt = assetCollectionViewSelector.filters?.valid_at
      const filterValidAt = moment(filterAt)
      const paramObj: AssetSingleViewQueryParams = {
        valid_at: retiredAtDate.isSameOrBefore(filterValidAt, 'date') ? retiredAt : filterAt,
        type: retiredAtDate.isSameOrBefore(filterValidAt, 'date')
          ? ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD
          : undefined,
      }
      const param = `?${objectToQueryParams(paramObj)}`
      history.replace(`/assets/${biid}${param}`)
      yield put(fetchAssetByValidAtAction(biid, retiredAt, false))
    }
    yield put({ type: CREATE_ASSET_EVENT_RETIREMENT_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* deleteAssetRequest({ payload }: DeleteAssetRequest) {
  const formValues: ReturnType<typeof selectAssetsCollectionFormValues> = yield select(selectAssetsCollectionFormValues)
  const assetCollectionSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
    selectAssetsCollectionView
  )

  try {
    yield call(assetApi.deleteByBiid, payload.item.biid)
    yield put({ type: DELETE_ASSET_SUCCESS })
    yield put<FetchAssetListRequest>({
      type: FETCH_ASSET_LIST_REQUEST,
      payload: {
        filters: assetCollectionSelector.filters,
        sort: assetCollectionSelector.sort,
        formValues,
      },
      pageNumber: assetCollectionSelector.page,
      pageSize: payload.pageSize,
    })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
  } catch (error) {
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.deleted_failure_without_name'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* deleteAssetItemRequest({ payload, isGetDetailOneFixedAsset }: DeleteAssetItemRequest) {
  const biid = get(payload, 'biid')
  const id = get(payload, 'id')
  const assetName = get(payload, 'name')
  const changeSituationCode = get(payload, 'change_situation.code')
  const isDividingEvent = changeSituationCode === ASSET_CHANGE_SITUATION_TYPES.DIVIDING
  const isAcquisitionEvent = changeSituationCode === ASSET_CHANGE_SITUATION_TYPES.ACQUISITION
  const isDestinationAsset = !!(get(payload, 'divided_at') && get(payload, 'dividing_source_fixed_asset'))
  const numberOfEvents = get(payload, 'number_of_events')
  const assetCollectionViewSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
    selectAssetsCollectionView
  )
  const formValues: ReturnType<typeof selectAssetsCollectionFormValues> = yield select(selectAssetsCollectionFormValues)

  try {
    if (isDividingEvent) {
      yield call(assetApi.deleteDividingEvent, biid, id)
    } else {
      yield call(assetApi.deleteById, biid, id)
    }

    if (payload?.retired_at || payload?.sold_at) {
      const paramObj: AssetSingleViewQueryParams = {
        valid_at: get(assetCollectionViewSelector, 'filters.valid_at') || moment().format(DATE_FORMAT_RFC),
      }
      const param = `?${objectToQueryParams(paramObj)}`
      history.replace(`/assets/${biid}${param}`)
    }
    yield put({ type: DELETE_ASSET_ITEM_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )

    if ((isDestinationAsset && numberOfEvents === 1) || isAcquisitionEvent) {
      history.push('/assets')
      if (isAcquisitionEvent) {
        yield put<FetchAssetListRequest>({
          type: FETCH_ASSET_LIST_REQUEST,
          payload: {
            filters: assetCollectionViewSelector.filters,
            sort: assetCollectionViewSelector.sort,
            formValues,
          },
          pageNumber: assetCollectionViewSelector.page,
          pageSize: payload.pageSize,
        })
      }
    } else {
      yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
    }
  } catch (error) {
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.deleted_failure', { name: assetName }),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
    yield put({
      type: DELETE_ASSET_ITEM_FAILURE,
      payload: {
        message: i18n.t('common.messages.deleted_failure', { name: assetName }),
      },
    })
  }
}

export function* updateCommentCount({ assetBiid }: any) {
  try {
    const commentCountResponse: AxiosResponse = yield call(assetCommentApi.getCommentCountByFixedAssets, [assetBiid])
    const commentsCountByFixedAssets = commentCountResponse.data.data || []
    yield put({
      type: UPDATE_COMMENT_COUNT_SUCCESS,
      payload: commentsCountByFixedAssets.length > 0 ? commentsCountByFixedAssets[0].count : 0,
      assetBiid,
    })
  } catch (error) {
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* deleteUnusedAsseRequest({ biid }: any) {
  try {
    yield call(assetApi.deleteByBiid, biid)
    yield put({ type: DELETE_UNUSED_ASSET_SUCCESS })
    yield put({ type: CHANGE_LOCATION, payload: '/assets' })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.deleted_success'),
      })
    )
  } catch (error) {
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.deleted_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export function* submitImpairment({
  payload: { assetBiid, impairmentPayload, isGetDetailOneFixedAsset },
  meta,
}: SubmitImpairmentEventRequest) {
  try {
    yield call(assetApi.submitImpairmentEvent, assetBiid, impairmentPayload)
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid: assetBiid, isGetDetailOneFixedAsset })
    yield put({ type: SUBMIT_IMPAIRMENT_SUCCESS, meta })
  } catch (error: any) {
    if (error?.response?.status !== API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      yield put(showErrorsAppCall(parseError(error)))
    }
    // yield put({ type: SUBMIT_IMPAIRMENT_FAILURE, payload: error, error: true, meta });
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* submitModifiedAssetType({
  payload: { assetBiid, modifiedPayload },
  meta,
}: SubmitModifiedAssetTypeEventRequest) {
  try {
    yield call(assetApi.submitModifiedAssetTypeEvent, assetBiid, modifiedPayload)
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('components.AssetTypeModifyForm.update_asset_type_success'),
      })
    )
    yield put({ type: SUBMIT_MODIFIED_ASSET_TYPE_SUCCESS, meta })
  } catch (error: any) {
    if (error?.response?.status !== API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      yield put(showErrorsAppCall(parseError(error)))
    }
    yield put({ type: SUBMIT_MODIFIED_ASSET_TYPE_FAILURE, payload: error, error: true, meta })
  }
}

export function* updateAssetEventUsage({ biid, id, data, isGetDetailOneFixedAsset }: any) {
  try {
    yield call(assetApi.updateUsage, biid, id, data)
    yield put({ type: UPDATE_ASSET_EVENT_USAGE_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* createAssetEventMovement({ biid, data, isGetDetailOneFixedAsset }: any) {
  try {
    yield call(assetApi.createMovement, biid, data)
    yield put({ type: CREATE_ASSET_EVENT_MOVEMENT_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* createAssetEventSwitchCategory({ biid, data, isGetDetailOneFixedAsset }: any) {
  try {
    yield call(assetApi.createSwitchCategory, biid, data)
    yield put({ type: CREATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* createAssetEventSell({ biid, data, isGetDetailOneFixedAsset }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.createSell, biid, data)
    const soldAt = get(response, 'data.data.sold_at')

    if (soldAt) {
      const assetCollectionViewSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
        selectAssetsCollectionView
      )
      const soldAtDate = moment(soldAt)
      const filterAt = assetCollectionViewSelector.filters?.valid_at
      const filterValidAt = moment(filterAt)
      const paramObj: AssetSingleViewQueryParams = {
        valid_at: soldAtDate.isSameOrBefore(filterValidAt, 'date') ? soldAt : filterAt,
        type: soldAtDate.isSameOrBefore(filterValidAt, 'date')
          ? ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD
          : undefined,
      }
      const param = `?${objectToQueryParams(paramObj)}`
      history.replace(`/assets/${biid}${param}`)
      yield put(fetchAssetByValidAtAction(biid, soldAt, false))
    }

    yield put({ type: CREATE_ASSET_EVENT_SELL_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* updateAssetEventRetirement({ biid, id, data, isGetDetailOneFixedAsset }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.updateRetirement, biid, id, data)
    const retiredAt = get(response, 'data.data.retired_at')
    if (retiredAt) {
      const assetCollectionViewSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
        selectAssetsCollectionView
      )
      const retiredAtDate = moment(retiredAt)
      const filterAt = assetCollectionViewSelector.filters?.valid_at
      const filterValidAt = moment(filterAt)
      const paramObj: AssetSingleViewQueryParams = {
        valid_at: retiredAtDate.isSameOrBefore(filterValidAt, 'date') ? retiredAt : filterAt,
        type: retiredAtDate.isSameOrBefore(filterValidAt, 'date')
          ? ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD
          : undefined,
      }
      const param = `?${objectToQueryParams(paramObj)}`
      history.replace(`/assets/${biid}${param}`)
      yield put(fetchAssetByValidAtAction(biid, retiredAt, false))
    }
    yield put({ type: UPDATE_ASSET_EVENT_RETIREMENT_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.created_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* updateAssetEventMovement({ biid, id, data, isGetDetailOneFixedAsset }: any) {
  try {
    yield call(assetApi.updateMovement, biid, id, data)
    yield put({ type: UPDATE_ASSET_EVENT_MOVEMENT_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* updateAssetEventSwitchCategory({ biid, id, data, isGetDetailOneFixedAsset }: any) {
  try {
    yield call(assetApi.updateSwitchCategory, biid, id, data)
    yield put({ type: UPDATE_ASSET_EVENT_SWITCH_CATEGORY_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* updateAssetEventSell({ biid, id, data, isGetDetailOneFixedAsset }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.updateSell, biid, id, data)
    const soldAt = get(response, 'data.data.sold_at')

    if (soldAt) {
      const assetCollectionViewSelector: ReturnType<typeof selectAssetsCollectionView> = yield select(
        selectAssetsCollectionView
      )
      const soldAtDate = moment(soldAt)
      const filterAt = assetCollectionViewSelector.filters?.valid_at
      const filterValidAt = moment(filterAt)
      const paramObj: AssetSingleViewQueryParams = {
        valid_at: soldAtDate.isSameOrBefore(filterValidAt, 'date') ? soldAt : filterAt,
        type: soldAtDate.isSameOrBefore(filterValidAt, 'date')
          ? ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD
          : undefined,
      }
      const param = `?${objectToQueryParams(paramObj)}`
      history.replace(`/assets/${biid}${param}`)
      yield put(fetchAssetByValidAtAction(biid, soldAt, false))
    }
    yield put({ type: UPDATE_ASSET_EVENT_SELL_SUCCESS })
    yield put({ type: FETCH_ASSET_ITEM_REQUEST, biid, isGetDetailOneFixedAsset })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetEventItemFailure>({ type: HANDLE_SUBMIT_ASSET_EVENT_FAILURE, error })
  }
}

export function* updateAsset({ biid, data }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.updateByBiid, biid, data)
    const highlightItem = get(response, 'data.data', null)
    yield put<UpdateAssetSuccess>({ type: UPDATE_ASSET_SUCCESS, highlightItem })
    yield put({ type: CHANGE_LOCATION, payload: '/assets' })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.updated_success'),
      })
    )
  } catch (error) {
    yield put<HandleSubmitAssetItemFailure>({
      type: HANDLE_SUBMIT_ASSET_ITEM_FAILURE,
      error,
    })
  }
}

export function* exportAsset({ payload, charset }: any) {
  try {
    const response: AxiosResponse = yield call(assetApi.exportFile, charset, payload.filters, payload.sort)
    yield downloadFileByResponse(response)
    yield put({ type: EXPORT_ASSET_SUCCESS })
    yield put(
      setAppMessage({
        type: 'success',
        content: i18n.t('common.messages.exported_success'),
      })
    )
  } catch (error) {
    yield put({ type: EXPORT_ASSET_FAILURE })
    yield put(
      setAppMessage({
        type: 'failure',
        content: i18n.t('common.messages.exported_failure'),
      })
    )
    yield put(showErrorsAppCall(parseError(error)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(FETCH_ASSET_LIST_REQUEST, fetchAssetList)
  yield takeLatest(FETCH_ASSET_ITEM_REQUEST, fetchAsset)
  yield takeLatest(FETCH_ASSET_ITEM_BY_VALID_AT_REQUEST, fetchAssetByValidAt)
  yield takeLatest(FETCH_ASSET_EVENT_HISTORY_REQUEST, fetchAssetEventHistory)
  yield takeLatest(CREATE_ASSET_ITEM_REQUEST, createAsset)
  yield takeLatest(CREATE_ASSET_EVENT_USAGE_REQUEST, createAssetEventUsage)
  yield takeLatest(CREATE_ASSET_EVENT_RETIREMENT_REQUEST, createAssetEventRetirement)
  yield takeLatest(CREATE_ASSET_EVENT_MOVEMENT_REQUEST, createAssetEventMovement)
  yield takeLatest(CREATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST, createAssetEventSwitchCategory)
  yield takeLatest(CREATE_ASSET_EVENT_SELL_REQUEST, createAssetEventSell)
  yield takeLatest(DELETE_ASSET_REQUEST, deleteAssetRequest)
  yield takeLatest(DELETE_ASSET_ITEM_REQUEST, deleteAssetItemRequest)
  yield takeLatest(DELETE_UNUSED_ASSET_REQUEST, deleteUnusedAsseRequest)
  yield takeLatest(UPDATE_ASSET_EVENT_USAGE_REQUEST, updateAssetEventUsage)
  yield takeLatest(UPDATE_ASSET_EVENT_RETIREMENT_REQUEST, updateAssetEventRetirement)
  yield takeLatest(UPDATE_ASSET_EVENT_MOVEMENT_REQUEST, updateAssetEventMovement)
  yield takeLatest(UPDATE_ASSET_EVENT_SWITCH_CATEGORY_REQUEST, updateAssetEventSwitchCategory)
  yield takeLatest(UPDATE_ASSET_EVENT_SELL_REQUEST, updateAssetEventSell)
  yield takeLatest(UPDATE_ASSET_REQUEST, updateAsset)
  yield takeLatest(UPDATE_COMMENT_COUNT_REQUEST, updateCommentCount)
  yield takeLatest(EXPORT_ASSET_REQUEST, exportAsset)
  yield takeLatest(HANDLE_SUBMIT_ASSET_EVENT_FAILURE, handleSubmitEventFailure)
  yield takeLatest(HANDLE_SUBMIT_ASSET_ITEM_FAILURE, handleSubmitAssetFailure)
  yield takeLatest(SUBMIT_IMPAIRMENT_REQUEST, submitImpairment)
  yield takeLatest(SUBMIT_MODIFIED_ASSET_TYPE_REQUEST, submitModifiedAssetType)
}
