/* eslint-disable react/no-danger */
import {
  ASSET_CHANGE_SITUATION_TYPES,
  Button,
  calculatePaginationTotalPages,
  Checkbox,
  ConfirmModal,
  DEFAULT_PAGE_SIZE,
  FeatureFlagNames,
  FEATURES_ID,
  FixedAssetsService,
  getErrorMsg,
  getNs,
  messageFn,
  messageSuccess,
  openFeatureSlice,
  Spin,
  Tooltip,
} from 'aa_common/front-end'
import { ErrorsList } from 'aa_common/front-end/components'
import { getAxiosSilent } from 'aa_common/front-end/open-api/helper'
import { Pagination } from 'components/molecules'
import { useGetSelectedEventOnPage } from 'components/organisms/accounting/AssetEventListReport/hooks'
import { useCheckEventAtClosingMonth } from 'components/organisms/accounting/AssetEventListReport/hooks/useCheckEventAtClosingMonth'
import { URL_REOPEN_MONTHLY_GUIDE } from 'constants/app'
import { useLongPolling } from 'hooks/useLongPolling'
import useUserPermission from 'lib/hooks/useUserPermission'
import { get, isEqual } from 'lodash'
import {
  assetEventListReportSlice,
  resetStore,
  setDetailMode,
  setError,
  setErrorIds,
  unSelectAllRowOnPage,
} from 'pages/accounting/AssetEventListReportPage/store'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssetEventsReport, fetchSummaryFixedAssetEvents } from 'store/accounting/assetEventsReport/actions'
import { selectAssetEventsReport } from 'store/accounting/assetEventsReport/selectors'
import { selectLoadingApp } from 'store/app/selectors'
import { selectTermData } from 'store/settings/accountingPeriod/selectors'
import { useShallow } from 'zustand/react/shallow'

import { ExtAxiosError } from '../../../../types/custom-types'
import { AcquisitionEventView } from './components/acquisition-event-view'
import { CollectiveCheckbox } from './components/collective-checkbox'
import { DividingEventView } from './components/dividing-event-view'
import { ImpairmentEventView } from './components/impairment-event-view'
import { MovementEventView } from './components/movement-event-view'
import { RetirementEventView } from './components/retirement-event-view'
import { SaleEventView } from './components/sale-event-view'
import { SwitchEventView } from './components/switch-event-view'
import { StyledErrorWrapper, StyledHeader, StyledTable, StyledTableHeader, StyledWrapper } from './styles'

const { t, useNsReady } = getNs([
  'common',
  'components/organisms/assets/asset-detail',
  'components/organisms/accounting/monthly-closing-asset-event-list',
])

const externalLinkIconSvg = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="up-right-from-square" class="svg-inline--fa fa-up-right-from-square " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="margin-left: 5px;"><path fill="currentColor" d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM488 0H352c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.88L370.8 96L169.4 297.4c-12.5 12.5-12.5 32.75 0 45.25C175.6 348.9 183.8 352 192 352s16.38-3.125 22.62-9.375L416 141.3l41.38 41.38c9.156 9.141 22.88 11.84 34.88 6.938C504.2 184.6 512 172.9 512 160V24C512 10.74 501.3 0 488 0z"></path></svg>`

export const AssetEventListReport = ({ eventType }: Props) => {
  useNsReady()
  const dispatch = useDispatch()

  const [pageNum, setPageNum] = useState(1)
  const [visible, setVisible] = useState(false)
  const [isCancelingProcessing, setCancelingProcessing] = useState<boolean>(false)
  const { data: assetEvents } = useSelector(selectAssetEventsReport, isEqual)
  const loadingApp = useSelector(selectLoadingApp, isEqual)
  const termData = useSelector(selectTermData, isEqual)?.data

  const assetEventListReportData = assetEventListReportSlice.useSlice(useShallow(state => state.data))
  const currentTermMonth = termData?.term_months?.find(
    (termMonth: any) => termMonth.id === assetEventListReportData?.termMonthId
  )

  const { selectedEvents, selectedEventIds } = useGetSelectedEventOnPage(eventType)
  const remoteFlags = openFeatureSlice.useSlice()

  const isRemoteFlagsAllowed =
    (remoteFlags[FeatureFlagNames.CollectionViewBulkChangeMovement] &&
      eventType === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT) ||
    (remoteFlags[FeatureFlagNames.CollectionViewBulkChangeRetirement] &&
      eventType === ASSET_CHANGE_SITUATION_TYPES.RETIREMENT) ||
    (remoteFlags[FeatureFlagNames.CollectionViewBulkChangeChangeSaleType] &&
      eventType === ASSET_CHANGE_SITUATION_TYPES.SELL) ||
    (remoteFlags[FeatureFlagNames.CollectionViewBulkChangeChangeImpairmentType] &&
      eventType === ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT)

  const handleChangePage = (value: number) => {
    setPageNum(value)
  }

  const { checkClosingMonthEvent } = useCheckEventAtClosingMonth({ records: selectedEvents, eventType })
  const featureId = useMemo(() => {
    switch (eventType) {
      // Only check Movement and Retire in this sprint(84)
      case ASSET_CHANGE_SITUATION_TYPES.RETIREMENT:
        return FEATURES_ID.RETIRE
      case ASSET_CHANGE_SITUATION_TYPES.MOVEMENT:
        return FEATURES_ID.MOVEMENT
      case ASSET_CHANGE_SITUATION_TYPES.SELL:
        return FEATURES_ID.SALE
      case ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT:
        return FEATURES_ID.IMPAIRMENT
      default:
        return FEATURES_ID.REPORT_ACQUISITION_TRANSFER
    }
  }, [eventType])

  const reFreshData = useCallback(() => {
    assetEventListReportData?.termMonthId &&
      dispatch(fetchSummaryFixedAssetEvents(assetEventListReportData?.termMonthId))
  }, [dispatch, assetEventListReportData?.termMonthId])

  const permissions = useUserPermission(featureId)

  const assetsData = useMemo(() => {
    return assetEvents?.data?.map((item: any) =>
      assetEventListReportData?.errorIds?.includes(item?.id?.toString()) ? { ...item, highlight: true } : item
    )
  }, [assetEvents?.data, assetEventListReportData?.errorIds, assetEventListReportData])
  const renderedEventList = useCallback(() => {
    const eventViewsMap = new Map([
      [
        ASSET_CHANGE_SITUATION_TYPES.ACQUISITION,
        <AcquisitionEventView key="acquisition-event-view" assets={assetsData} />,
      ],
      [
        ASSET_CHANGE_SITUATION_TYPES.RETIREMENT,
        <RetirementEventView key="retirement-event-view" assets={assetsData} />,
      ],
      [ASSET_CHANGE_SITUATION_TYPES.MOVEMENT, <MovementEventView key="movement-event-view" assets={assetsData} />],
      [ASSET_CHANGE_SITUATION_TYPES.SELL, <SaleEventView key="sale-event-view" assets={assetsData} />],
      [ASSET_CHANGE_SITUATION_TYPES.SWITCH, <SwitchEventView key="switch-event-view" assets={assetsData} />],
      [
        ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT,
        <ImpairmentEventView key="impairment-event-view" assets={assetsData} />,
      ],
      [ASSET_CHANGE_SITUATION_TYPES.DIVIDING, <DividingEventView key="dividing-event-view" assets={assetsData} />],
    ])
    return eventType && eventViewsMap.get(eventType)
  }, [eventType, assetsData])

  const fetchAssetEvents = useCallback(() => {
    if (assetEventListReportData?.termMonthId && eventType) {
      dispatch(fetchAssetEventsReport(assetEventListReportData?.termMonthId, eventType, DEFAULT_PAGE_SIZE, pageNum))
    }
  }, [assetEventListReportData?.termMonthId, pageNum, dispatch, eventType])

  useEffect(() => {
    resetStore()
  }, [assetEventListReportData?.termMonthId])

  useEffect(() => {
    fetchAssetEvents()
  }, [fetchAssetEvents])

  const isHavePermission = React.useMemo(() => {
    return permissions && permissions.permissions.isCRUD
  }, [permissions])

  const onSuccess = () => {
    messageSuccess(t('messages.cancel_success'))
    reFreshData()
    setPageNum(1)
    fetchAssetEvents()
    unSelectAllRowOnPage(selectedEventIds)
  }
  const onError = (errorIds: string[], error: string) => {
    setError(error)
    setVisible(false)
    setErrorIds(errorIds)
  }

  const cancelEventPolling = useLongPolling('cancelEventPolling', {
    onError,
    onSuccess,
    isShowToastErrorMessage: false,
  })

  const submitDeleteEvent = async () => {
    setError(null)
    setCancelingProcessing(true)
    if (!checkClosingMonthEvent.checkEventInClosingMonth) {
      try {
        const bodyDelete = selectedEvents.map(function (item: any) {
          return { biid: item.biid, id: item.id }
        })
        const res = await FixedAssetsService.bulkChangeDeleteDelete({ data: bodyDelete }, getAxiosSilent())
        res?.data?.id && cancelEventPolling.setJobId(res.data.id)
      } catch (err) {
        const errorMessage = getErrorMsg(err as ExtAxiosError)
        messageFn().error(errorMessage)
      } finally {
        setVisible(false)
        setCancelingProcessing(false)
      }
    } else {
      const errorClosingMonth = `<div>
          ${t('msg_err_cancel_event_is_closed')}
          <a target="_blank" href="${URL_REOPEN_MONTHLY_GUIDE}">
            ${t('actions.click_here_for_details')}
            ${externalLinkIconSvg}
            </div>`

      setError(errorClosingMonth)
      setVisible(false)
      setErrorIds(checkClosingMonthEvent.ids)
    }
  }

  const cancelDelete = () => {
    setVisible(false)
  }

  const getCurrentMonth = () => {
    const { month } = currentTermMonth || {}
    const { year } = termData || {}
    return year && month && `${year}/${month.toString().padStart(2, '0')}`
  }

  const hasSelectedItem = selectedEvents?.length > 0
  return (
    <Spin loading={loadingApp || cancelEventPolling.loading || isCancelingProcessing}>
      <StyledWrapper>
        {/* Only add Cancel button in retirement and movement in this print(84) */}
        {isRemoteFlagsAllowed && (
          <>
            <div className="cancel">
              <Tooltip content={isHavePermission ? undefined : t('actions.no_permission')}>
                <Button
                  color="grey"
                  style={{ color: isHavePermission && hasSelectedItem ? '#EC4949' : '#919191' }}
                  disabled={!isHavePermission || !hasSelectedItem}
                  onClick={() => selectedEvents?.length > 0 && setVisible(true)}
                >
                  {t('actions.cancel_bulk_change_event')}
                </Button>
              </Tooltip>
            </div>
            {selectedEvents?.length > 0 && (
              <ConfirmModal
                className="delete-event-modal"
                message={t('msg_confirm_cancel_event', {
                  date: getCurrentMonth(),
                  eventType: get(selectedEvents[0], ['change_situation', 'name_jp']),
                  count: selectedEvents?.length,
                })}
                visible={visible}
                onOK={submitDeleteEvent}
                onCancel={cancelDelete}
                okText={t('actions.delete')}
                isLoading={cancelEventPolling.loading || isCancelingProcessing}
              />
            )}
            {assetEventListReportData?.error && (
              <StyledErrorWrapper>
                <ErrorsList
                  title={<div dangerouslySetInnerHTML={{ __html: assetEventListReportData?.error }} />}
                  style={{ color: '#303030' }}
                />
              </StyledErrorWrapper>
            )}
          </>
        )}
        {assetEvents && (
          <>
            <StyledHeader>
              <>
                <div className="left-side">{isRemoteFlagsAllowed && <CollectiveCheckbox eventType={eventType} />}</div>
                <div className="right-side">
                  {isRemoteFlagsAllowed && (
                    <Checkbox
                      name="detail-display"
                      checked={assetEventListReportData?.isDetailMode}
                      onChange={({ checked }) => setDetailMode(checked)}
                    >
                      {t('detail_display')}
                    </Checkbox>
                  )}
                  <Pagination
                    name="fixed-asset"
                    value={pageNum}
                    totalPages={calculatePaginationTotalPages(assetEvents.total, DEFAULT_PAGE_SIZE) || 0}
                    totalRecords={assetEvents.total || 0}
                    onPageChanged={handleChangePage}
                  />
                </div>
              </>
            </StyledHeader>
            <StyledTable>
              <StyledTableHeader>
                {isRemoteFlagsAllowed && <div className="checkbox-column" />}
                <div className="status">{t('journal_linkage')}</div>
                <div className="photo">{t('photo')}</div>
                <div className="asset-code">{t('asset_code')}</div>
                <div className="asset-name">{t('asset_name')}</div>
              </StyledTableHeader>
              {renderedEventList()}
            </StyledTable>
          </>
        )}
      </StyledWrapper>
    </Spin>
  )
}

type Props = {
  eventType?: ASSET_CHANGE_SITUATION_TYPES
}
