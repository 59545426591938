import { UnderlineInput } from 'aa_common/front-end/antd'
import { UnborderCollapse } from 'aa_common/front-end/components'
import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import parser from 'html-react-parser'
import { useMemo } from 'react'

import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { useReferValue } from '../hooks/useReferValue'
import { useResetInput } from '../hooks/useResetInput'
import { shouldRender } from './BookValueAtChange'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type ServiceLifeAfterChangeProps = {
  fieldName: string
  hasTransition: boolean
  depreciationCode: DEPRECIATION_METHOD_CODE
  enabled?: boolean
  setFieldValue: (field: string, value: any) => void
  formValues: any
  initialValue?: number | null
}

export const ServiceLifeAfterChange = ({
  fieldName,
  hasTransition,
  depreciationCode,
  enabled,
  setFieldValue,
  formValues,
  initialValue,
}: React.PropsWithChildren<ServiceLifeAfterChangeProps>) => {
  const isShown = useMemo(() => shouldRender(hasTransition, depreciationCode), [hasTransition, depreciationCode])

  useReferValue(fieldName, formValues, setFieldValue, initialValue, isShown)
  useClearValueOnHide(fieldName, setFieldValue, isShown)
  const { hasUpdate, handleReset } = useResetInput(
    fieldName,
    formValues,
    setFieldValue,
    initialValue,
    isNumberHaveValue
  )

  if (!isShown) {
    return null
  }

  return (
    <Item
      label={
        <ItemLabelTooltip
          tooltipMaxWidth={560}
          label={parser(t('service_life_after_change'))}
          tooltip={parser(t('service_life_after_change_tooltip'))}
        />
      }
    >
      <UnderlineInput
        type="number"
        name={fieldName}
        editable={enabled}
        align="right"
        isResetManualValue={hasUpdate && enabled}
        onHandleClickOnReset={handleReset}
        min={2}
        defaultValue={initialValue || 2}
      />
    </Item>
  )
}
