import { RightArrowIcon } from 'aa_common/front-end/components'
import { EMPTY_SYMBOL } from 'aa_common/front-end/constants'
import i18n from 'i18n'
import React from 'react'

export const DetailField = ({
  titleKey,
  previousValue,
  currentValue,
}: {
  titleKey: string
  previousValue: string | number
  currentValue: string | number
}) => {
  return (
    <div className="detail-field-wrapper">
      <div className="title">{i18n.t(`components.AssetDetail.${titleKey}`)}</div>
      <div className="value" style={{ display: 'flex', alignItems: 'center' }}>
        <label>{previousValue || EMPTY_SYMBOL}</label>
        <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />
        <label>{currentValue || EMPTY_SYMBOL}</label>
      </div>
    </div>
  )
}
