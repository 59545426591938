import { CORPORATE_INCOME_TAX } from 'aa_common/front-end/constants'
import { getNs } from 'aa_common/front-end/i18n-config'

import { ReportForm as ReportForm1 } from '../tax-report-table-16/report-1'
import { ReportForm as ReportForm2 } from '../tax-report-table-16/report-2'
import { ReportForm as ReportForm4 } from '../tax-report-table-16/report-4'
import { ReportForm as ReportForm6 } from '../tax-report-table-16/report-6'
import { ReportForm as ReportForm7 } from '../tax-report-table-16/report-7'
import { ReportForm as ReportForm8 } from '../tax-report-table-16/report-8'

const { t, useNsReady } = getNs(['common', 'pages/accounting/corporate-income-tax-report'])

type ReportRefactorProps = {
  reportId: number
}

export const ReportRefactor = ({ reportId }: ReportRefactorProps) => {
  useNsReady()

  const ReportComponent =
    !!reportId &&
    {
      [CORPORATE_INCOME_TAX.TABLE_16_TYPE_1]: ReportForm1,
      [CORPORATE_INCOME_TAX.TABLE_16_TYPE_2]: ReportForm2,
      [CORPORATE_INCOME_TAX.TABLE_16_TYPE_4]: ReportForm4,
      [CORPORATE_INCOME_TAX.TABLE_16_TYPE_6]: ReportForm6,
      [CORPORATE_INCOME_TAX.TABLE_16_TYPE_7]: ReportForm7,
      [CORPORATE_INCOME_TAX.TABLE_16_TYPE_8]: ReportForm8,
    }[reportId]

  return (ReportComponent && <ReportComponent />) || <div className="not-found-report">{t('reportNotFound')}</div>
}
