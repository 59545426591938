import { formatNumber } from 'aa_common/front-end/helpers'
import { Checkbox } from 'antd'
import { FileLink, ReferenceLink, TagView } from 'components/atoms'
import { EMPTY_SYMBOL } from 'constants/app'
import i18n from 'i18n'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { TableWrapper } from '../styles'

type Props = {
  data: any
}

const Information: React.FC<Props> = ({ data }) => {
  const renderAttachments = (attachments: any[]) => {
    return attachments.map((item: any) => <FileLink file={item} key={uniqueId()} style={{ marginRight: 10 }} />)
  }

  const renderUrls = (urls: string[]) =>
    urls.map((url: string) => <ReferenceLink url={url} key={url} style={{ marginRight: 10 }} />)

  const renderTags = (tags: any[]) => {
    return tags.map((tag: any) => (
      <TagView key={uniqueId()} content={get(tag, 'name') || ''} style={{ marginRight: 5 }} />
    ))
  }

  const renderAssetTypeCorporateTax = (data: any) => {
    let result = ''

    if (get(data, ['asset_type_for_corporate_tax', 'name_jp']))
      result += `${get(data, ['asset_type_for_corporate_tax', 'name_jp'])}\n`

    if (get(data, 'asset_structure_for_corporate_tax')) result += `${data.asset_structure_for_corporate_tax}\n`

    if (get(data, 'asset_detail_for_corporate_tax')) result += data.asset_detail_for_corporate_tax

    return result.trim()
  }

  const getLabelByKey = (key: string) => {
    return i18n.t(`components.AssetDetail.${key}`)
  }

  const unitName = get(data, 'unit.name')
  return (
    <>
      <div style={{ display: 'flex' }}>
        <TableWrapper>
          <tbody>
            <tr>
              <th>{getLabelByKey('acquisition_category')}</th>
              <td>{get(data, ['acquire_category', 'name_jp'])}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('asset_category')}</th>
              <td>{get(data, ['asset_category', 'name'])}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('asset_type_for_depreciable_property_tax')}</th>
              <td>{get(data, ['asset_type_for_depreciable_property_tax', 'name_jp'])}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('asset_type_for_corporate_tax')}</th>
              <td style={{ whiteSpace: 'pre-line' }}>{renderAssetTypeCorporateTax(data)}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('area')}</th>
              <td>{get(data, ['area', 'name'])}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('submit_destination')}</th>
              <td>{get(data, ['area', 'submit_destination', 'name'])}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('department')}</th>
              <td>{get(data, ['department', 'name'])}</td>
            </tr>
          </tbody>
        </TableWrapper>
        <TableWrapper style={{ marginLeft: 10 }}>
          <tbody>
            <tr>
              <th>{getLabelByKey('quantity')}</th>
              <td>
                {formatNumber(get(data, 'quantity') || 0)} {unitName && `(${unitName})`}
              </td>
            </tr>
            <tr>
              <th>{getLabelByKey('acquire_destination')}</th>
              <td>{get(data, 'acquire_destination_name')}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('advance_application_number')}</th>
              <td>{get(data, 'pre_submit_number')}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('exemption_flag')}</th>
              <td>{data && <ExemptionFlag data={data} />}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('taxation_standard_special')}</th>
              <td>{data && <ExemptionValue data={data} />}</td>
            </tr>
            <tr>
              <th>{getLabelByKey('summary')}</th>
              <td>{get(data, 'summary_for_asset_depreciation_tax') || EMPTY_SYMBOL}</td>
            </tr>
          </tbody>
        </TableWrapper>
      </div>
      <TableWrapper style={{ marginTop: 10 }}>
        <tbody>
          <tr>
            <th>{getLabelByKey('attachment')}</th>
            <td>{renderAttachments(get(data, 'attachments') || [])}</td>
          </tr>
          <tr>
            <th>{getLabelByKey('reference')}</th>
            <td>{renderUrls(get(data, 'urls') || [])}</td>
          </tr>
        </tbody>
      </TableWrapper>
      <TableWrapper style={{ marginTop: 10 }}>
        <tbody>
          <tr>
            <th>{getLabelByKey('tag')}</th>
            <td>{renderTags(get(data, 'tags') || [])}</td>
          </tr>
          <tr>
            <th>{getLabelByKey('memo')}</th>
            <td>{get(data, 'memo')}</td>
          </tr>
        </tbody>
      </TableWrapper>
    </>
  )
}

const ExemptionFlag = ({ data }: any) => {
  let checked = false
  if (get(data, 'exemption_numerator') > 0 || get(data, 'exemption_denominator') > 0) {
    checked = true
  }

  return <Checkbox disabled checked={checked} />
}

const ExemptionValue = ({ data }: any) => {
  if (get(data, 'exemption_numerator') > 0 || get(data, 'exemption_denominator') > 0) {
    return (
      <>
        {get(data, 'exemption_numerator') || ''}/${get(data, 'exemption_denominator') || ''}
      </>
    )
  }
  return <>ー</>
}

export default Information
