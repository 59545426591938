import { loadNs } from 'aa_common/front-end/i18n-config'
import { AssetCodeSettingRule } from 'models/masterData'

const t = loadNs(['components/organisms/settings/asset-code-auto-generation-view'])

export const displayValueTextOfRule = (
  masterDataRules: AssetCodeSettingRule[],
  ruleCode: number,
  ruleValue: number
) => {
  let result = ''

  const selectedMasterDataRule = masterDataRules.find(masterDataRule => masterDataRule.code === ruleCode)

  if (selectedMasterDataRule) result = selectedMasterDataRule?.name_jp

  const selectedRuleValue = selectedMasterDataRule?.values.find(dataRule => dataRule.code === ruleValue)

  if (selectedRuleValue) result += `（${selectedRuleValue.name_jp}）`

  return result
}

export const parseLengthRuleValues = (lengthValues: any[]) => {
  return lengthValues.map((lengthValue: any) => {
    return {
      ...lengthValue,
      text: `${t('serial_number')}（${lengthValue.name_jp}）`,
    }
  })
}
