import { ifElse } from 'aa_common/front-end/helpers'
import { loadNs } from 'aa_common/front-end/i18n-config'
import { Input, NumberInput, RegexInput } from 'components/atoms'
import { AutoCompleteSelect, AutoCompleteText, BasicForm } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import { DEPRECIATION_METHOD_CODE } from 'constants/masterData'
import { getIn, useFormikContext } from 'formik'
import { FORM_MODES } from 'models/form'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchExciseList } from 'store/settings/excise/actions'

import { validateForm, ValueFields } from './helpers'
import { Error, InlineWrapper } from './styles'

const t = loadNs([
  'common',
  'components/organisms/settings/asset-category-list',
  'components/organisms/assets/asset-form',
  'components/organisms/settings/asset-category-form',
])

const { Group, Header, Item, Divider, SubmitButton } = BasicForm

type Props = {
  masterData: any
  type: FORM_MODES
}

const Form: React.FC<Props> = ({ masterData, type }) => {
  const dispatch = useDispatch()
  const { values, errors, setFieldValue } = useFormikContext<ValueFields>()
  const isValidFields = validateForm(values)
  const depreciationCode = getIn(values, 'depreciation_method')

  const renderServiceLife = () => {
    const isDisabledInput = depreciationCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
    const isSmallAmountDepreciableAsset = depreciationCode === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET

    if (
      [DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item label={t('service_life')}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name="service_life"
            min={ifElse(isSmallAmountDepreciableAsset, 0, 2)}
            max={100}
            defaultValue={ifElse(isSmallAmountDepreciableAsset, 0, 2)}
            maxLength={3}
            disabled={isDisabledInput || false}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
          />
          <label>{t('units.year')}</label>
        </div>
        <Error>
          <label>{getIn(errors, 'service_life')}</label>
        </Error>
      </Item>
    )
  }

  useEffect(() => {
    const filters = {
      is_active: true,
    }
    dispatch(fetchExciseList({ filters }))
  }, [dispatch])

  useEffect(() => {
    if (!depreciationCode) return

    if (type === FORM_MODES.NEW) {
      switch (parseInt(depreciationCode, 10)) {
        case DEPRECIATION_METHOD_CODE.NON_DEPRECIATION:
        case DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD:
        case DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE:
          setFieldValue('service_life', null)
          break
        case DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD:
          setFieldValue('service_life', 3)
          break
        case DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE:
        case DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE:
        case DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE:
        case DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250:
        case DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200:
          setFieldValue('service_life', 2)
          break
        default:
          break
      }
    }
  }, [depreciationCode, type, setFieldValue])

  return (
    <>
      <Group>
        <Header>{t('form_title')}</Header>
        <Item required label={t('code')}>
          <RegexInput name="code" placeholder={t('placeholder.under_10')} maxLength={10} />
        </Item>
        <Item required label={t('name')}>
          <Input name="name" placeholder={t('placeholder.under_50')} maxLength={50} />
        </Item>
        <Item required label={t('type')}>
          <AutoCompleteSelect
            name="asset_type"
            items={masterData && masterData.asset_type}
            valueField="code"
            textField="name_jp"
          />
        </Item>
        <Divider>{t('divider_content')}</Divider>
        <Item label={t('excise')}>
          <ExciseSelect style={{ width: 400 }} name="excise_id" placeholder={t('excise_placeholder')} />
        </Item>
        <Item label={t('depreciation_method')}>
          <AutoCompleteSelect
            name="depreciation_method"
            items={masterData && masterData.depreciation_method}
            valueField="code"
            textField="name_jp"
            placeholder={t('depreciation_method_placeholder')}
          />
        </Item>
        {depreciationCode !== DEPRECIATION_METHOD_CODE.NON_DEPRECIATION && <>{renderServiceLife()}</>}
        <Item label={t('asset_type_for_depreciable_property_tax')}>
          <AutoCompleteSelect
            name="asset_type_for_depreciable_property_tax"
            items={masterData && masterData.asset_type_for_depreciable_property_tax}
            valueField="code"
            textField="name_jp"
            placeholder={t('asset_type_for_depreciable_property_tax_placeholder')}
          />
        </Item>
        <Item label={t('asset_type_for_corporate_tax')}>
          <InlineWrapper>
            <AutoCompleteSelect
              name="asset_type_for_corporate_tax"
              items={masterData && masterData.asset_type_for_corporate_tax}
              valueField="code"
              textField="name_jp"
              placeholder={t('asset_type_for_corporate_tax_placeholder')}
              style={{ width: 144 }}
            />{' '}
            -
            <AutoCompleteText
              name="asset_structure_for_corporate_tax"
              placeholder={t('asset_structure_for_corporate_tax_placeholder')}
              style={{ width: 103 }}
              maxLength={50}
            />{' '}
            -
            <AutoCompleteText
              name="asset_detail_for_corporate_tax"
              placeholder={t('asset_detail_for_corporate_tax_placeholder')}
              style={{ width: 101 }}
              maxLength={50}
            />
          </InlineWrapper>
        </Item>
        <Item className="last" label={t('search_key')}>
          <Input name="search_key" placeholder={t('placeholder.under_20')} maxLength={20} />
        </Item>
      </Group>
      <SubmitButton isDisableBtn={!isValidFields} />
    </>
  )
}

export default Form
