import { UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { loadNs } from 'aa_common/front-end/i18n-config'
import * as exciseApi from 'api/app/excise'
import { MoneyInput } from 'components/molecules'
import { ExciseSelect } from 'components/organisms'
import { EXCISE_CAN_EDIT_TAX, EXCISE_TAX_INPUT_FORMAT, EXCISE_TAX_METHOD } from 'constants/masterData'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Group } from '../events/styles'
import { ValueFields } from './helper'

const t = loadNs('components/organisms/assets/asset-retirement-form')

const { Item } = UnborderCollapse
const contentItemWidth = 240
const itemWidth = '100%'

const DisposalCostWithExcise: React.FC<Props> = ({ isBulkChangesEventForRetirement }) => {
  const { values, setFieldValue } = useFormikContext<ValueFields>()
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [exciseSettingResponse, getExciseSettingByDate] = useResource(exciseApi.getExciseSettingsByDate)
  const [calculatedExciseValueResponse, getCalculatedExciseValue] = useResource(exciseApi.getExciseVatInfo)
  const retiredAt: string | null = get(values, 'retired_at')
  const isTaxMethodExcluded = get(exciseSettingResponse.data, 'tax_method') === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormatExternalTax =
    get(exciseSettingResponse.data, 'tax_input_format') === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX
  const isCanEditTax = get(exciseSettingResponse.data, 'can_edit_tax') === EXCISE_CAN_EDIT_TAX.YES
  const disposalExciseId: number | null = get(values, 'disposal_excise_id')
  const disposalCostInput: number = get(values, 'disposal_cost_input')
  const disposalCost: number = get(values, 'disposal_cost')
  const manualDisposalExciseValue: number | null = get(values, 'manual_disposal_excise_value')
  const isDisposalCostIsZero = disposalCostInput === 0
  const isHasManualDisposalExciseValue = !!manualDisposalExciseValue || manualDisposalExciseValue === 0

  const handleOnChangeManualDisposalExciseValue = (newValue: number) => {
    setFieldValue('manual_disposal_excise_value', newValue)
  }

  useEffect(() => {
    if (retiredAt && !isBulkChangesEventForRetirement) {
      getExciseSettingByDate(retiredAt)
    }
  }, [retiredAt, isBulkChangesEventForRetirement])

  useEffect(() => {
    if (isDataLoaded && retiredAt && disposalExciseId && disposalCostInput && manualDisposalExciseValue === null) {
      getCalculatedExciseValue(retiredAt, disposalExciseId, disposalCostInput)
    }
  }, [disposalExciseId, retiredAt, disposalCostInput, isDataLoaded])

  useEffect(() => {
    if (isDataLoaded && disposalCostInput === 0) {
      setFieldValue('disposal_cost', 0)
      setFieldValue('disposal_cost_excluded_excise', 0)
      setFieldValue('calculated_disposal_excise_value', 0)
      isCanEditTax && setFieldValue('manual_disposal_excise_value', null)
      setFieldValue('disposal_excise_id', get(exciseSettingResponse.data, 'default_excise_disposal_cost_id'))
    }
  }, [disposalCostInput, isDataLoaded, isCanEditTax])

  useEffect(() => {
    if (!isTaxMethodExcluded && disposalCost === 0) {
      setFieldValue('disposal_excise_id', get(exciseSettingResponse.data, 'default_excise_disposal_cost_id'))
    }
  }, [disposalCost]) // eslint-disable-line

  useEffect(() => {
    if (exciseSettingResponse.data && !disposalExciseId) {
      const defaultExciseDisposalCostId = get(exciseSettingResponse.data, 'default_excise_disposal_cost_id')
      setFieldValue('disposal_excise_id', defaultExciseDisposalCostId)
    }
  }, [exciseSettingResponse.data]) // eslint-disable-line

  useEffect(() => {
    if ((manualDisposalExciseValue || manualDisposalExciseValue === 0) && isTaxMethodExcluded) {
      if (isTaxInputFormatExternalTax) {
        const disposalCostExcludedExcise = get(values, 'disposal_cost_excluded_excise')
        setFieldValue('disposal_cost', disposalCostExcludedExcise + manualDisposalExciseValue)
      } else {
        const disposalCostIncludedExcise = get(values, 'disposal_cost')
        setFieldValue('disposal_cost_excluded_excise', disposalCostIncludedExcise - manualDisposalExciseValue)
      }
    }
  }, [manualDisposalExciseValue, isTaxMethodExcluded, isTaxInputFormatExternalTax]) // eslint-disable-line

  useEffect(() => {
    if (calculatedExciseValueResponse.data) {
      setFieldValue('disposal_cost', calculatedExciseValueResponse.data.included_excise_value)
      setFieldValue('disposal_cost_excluded_excise', calculatedExciseValueResponse.data.excluded_excise_value)
      setFieldValue('calculated_disposal_excise_value', calculatedExciseValueResponse.data.excise_value)
      isCanEditTax && setFieldValue('manual_disposal_excise_value', null)
    }
  }, [calculatedExciseValueResponse.data, isCanEditTax]) // eslint-disable-line

  useEffect(() => {
    if (manualDisposalExciseValue) {
      setFieldValue('calculated_disposal_excise_value', manualDisposalExciseValue)
    }

    setIsDataLoaded(true)
  }, []) // eslint-disable-line

  const handleResetManualExciseValue = () => {
    const calculatedExciseValueResponseValue = calculatedExciseValueResponse.data

    if (manualDisposalExciseValue !== null) {
      setFieldValue('manual_disposal_excise_value', null)
    }

    if (calculatedExciseValueResponseValue) {
      setFieldValue('calculated_disposal_excise_value', calculatedExciseValueResponseValue?.excise_value)
      setFieldValue('disposal_cost', calculatedExciseValueResponseValue?.included_excise_value)
      setFieldValue('disposal_cost_excluded_excise', calculatedExciseValueResponseValue?.excluded_excise_value)
    }
  }

  return (
    <>
      {isTaxMethodExcluded && isTaxInputFormatExternalTax && (
        <>
          <Item label={t('disposal_cost_excluded_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <MoneyInput name="disposal_cost_input" style={{ width: 240 }} />
            </Group>
          </Item>
          <Item label={t('excise')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <ExciseSelect name="disposal_excise_id" style={{ width: 240 }} isDisabled={isDisposalCostIsZero} />
            </Group>
          </Item>
          <Item label={t('consumption_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="calculated_disposal_excise_value"
                placeholder={t('disposal_cost_included_tax_placeholder')}
                style={{ width: 240 }}
                editable={!isDisposalCostIsZero && isCanEditTax}
                onChange={handleOnChangeManualDisposalExciseValue}
                isResetManualValue={!isDisposalCostIsZero && isCanEditTax && isHasManualDisposalExciseValue}
                onHandleClickOnReset={handleResetManualExciseValue}
              />
            </Group>
          </Item>
          <Item label={t('disposal_cost_included_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="disposal_cost"
                placeholder={t('disposal_cost_excluded_tax_placeholder')}
                editable={false}
                style={{ width: 240 }}
              />
            </Group>
          </Item>
        </>
      )}
      {isTaxMethodExcluded && !isTaxInputFormatExternalTax && (
        <>
          <Item label={t('disposal_cost_included_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <MoneyInput name="disposal_cost_input" style={{ width: 240 }} />
            </Group>
          </Item>
          <Item label={t('excise')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <ExciseSelect name="disposal_excise_id" style={{ width: 240 }} isDisabled={isDisposalCostIsZero} />
            </Group>
          </Item>
          <Item label={t('consumption_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="calculated_disposal_excise_value"
                maxValue={get(values, 'disposal_cost')}
                placeholder={t('disposal_cost_included_tax_placeholder')}
                style={{ width: 240 }}
                editable={!isDisposalCostIsZero && isCanEditTax}
                onChange={handleOnChangeManualDisposalExciseValue}
                isResetManualValue={!isDisposalCostIsZero && isCanEditTax && isHasManualDisposalExciseValue}
                onHandleClickOnReset={handleResetManualExciseValue}
              />
            </Group>
          </Item>
          <Item label={t('disposal_cost_excluded_tax')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <UnderlineMoneyInput
                name="disposal_cost_excluded_excise"
                placeholder={t('disposal_cost_included_tax_placeholder')}
                editable={false}
                style={{ width: 240 }}
              />
            </Group>
          </Item>
        </>
      )}
      {!isTaxMethodExcluded && (
        <>
          <Item
            label={`${t('disposal_cost_included_tax')}`}
            contentWidth={contentItemWidth}
            style={{ width: itemWidth }}
          >
            <Group>
              <MoneyInput name="disposal_cost" style={{ width: 240 }} />
            </Group>
          </Item>
          <Item label={t('excise')} contentWidth={contentItemWidth} style={{ width: itemWidth }}>
            <Group>
              <ExciseSelect
                name="disposal_excise_id"
                style={{ width: 240 }}
                isDisabled={!get(values, 'disposal_cost')}
              />
            </Group>
          </Item>
        </>
      )}
    </>
  )
}

type Props = {
  isBulkChangesEventForRetirement?: boolean
}

export default DisposalCostWithExcise
